import { PostsService } from "@Services/posts.service";
import type { Page } from "@Types/services/post";
import useBaseQuery from "./useBaseQuery";

type UsePageProps = {
  id: string;
  onSuccess: (data: Page) => void;
  onError: (error: any) => void;
};

const usePage = ({ id, onError, onSuccess }: UsePageProps) => {
  const service = PostsService.getInstance();

  return useBaseQuery<Page, "page">({
    service: async () => service.getPage(id),
    dataName: "page",
    queryKey: ["page", id],
    onError,
    onSuccess,
  });
};

export default usePage;
