const matomoOptions = {
  urlBase: "https://innn.matomo.cloud/",
  // siteId: 1,
  siteId: 3,
  configurations: {
    requireConsent: true,
    requireCookieConsent: true,
    setSecureCookie: true,
  },
};

export default matomoOptions;
