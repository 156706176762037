import { useState } from "react";

import { faEnvelopeDot } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import {
  descriptionContainer,
  formContainer,
  headingStyle,
  illustrationContainerStyle,
  rowStyle,
  sectionStyle,
} from "@Appearances/pages/styleHomeNewsletter";
import {
  BodyLg,
  Column,
  Container,
  Heading,
  Illustration,
  Row,
  UserForm,
  UserFormFields,
} from "@Components";
import { useTheme } from "@emotion/react";
import { marginBottomMdZero, marginBottomXsMd, paddingX2xl } from "@Styles";
import { ValidationMessage } from "@Stylize/helpers/validation";
import type { SharedHomeProps } from "@Types";
import { EmailService } from "@Services/email.service";

const HomeSignableNewsletter = (props: SharedHomeProps) => {
  const { tone } = useTheme();

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [user, setUser] = useState<UserFormFields>({
    firstName: "",
    lastName: "",
    email: "",
    country: "DE",
    postalCode: "",
    postalCodeDisplay: "",
    location: "",
    locationDisplay: "",
  });

  const emailService = EmailService.getInstance();

  const handleFormSubmit = (): Promise<ValidationMessage> => {
    setLoading(true);

    return emailService
      .subscribeNewsletter({ ...user, source: "homeBanner" })
      .then(() => {
        return { valid: true, message: "" };
      })
      .catch(() => {
        return {
          valid: false,
          message: t("components.userForm.validation.submit.error"),
        };
      })
      .finally(() => setLoading(false));
  };

  return (
    <section
      css={sectionStyle}
      {...props}
    >
      <Container size="lg">
        <Row css={rowStyle}>
          <Column span={{ default: 12, md: 5 }}>
            <div css={illustrationContainerStyle}>
              <Illustration
                name="newsletter"
                size="lg"
                css={[paddingX2xl]}
              />
            </div>

            <div css={descriptionContainer}>
              <Heading
                css={headingStyle}
                scale={1}
                variant="display"
                color="primary"
              >
                {t("pages.home.newsletter.title")}
              </Heading>

              <BodyLg css={[marginBottomXsMd, marginBottomMdZero]}>
                {t("pages.home.newsletter.text")}
              </BodyLg>
            </div>
          </Column>

          <Column
            span={{ default: 12, md: 6 }}
            offset={{ default: 0, md: 1, lg: 1 }}
          >
            <div css={formContainer(tone)}>
              <UserForm
                isLoading={loading}
                submitButtonIcon={faEnvelopeDot}
                submitButtonLabel={t("common.newsletter.subscribe") as string}
                user={user}
                setUser={setUser}
                subscribe={true}
                onSubmit={handleFormSubmit}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  );
};

export default HomeSignableNewsletter;
