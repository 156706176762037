// export default Loading;
import { BodyLg } from "@Components/typography";
import styled from "@emotion/styled";
import { displayFlex, marginZero } from "@Styles";
import { motion } from "framer-motion";
import { useEffect } from "react";

const LoadingDot = styled(motion.div)(({ color }) => ({
  width: "5px",
  height: "5px",
  borderRadius: "50%",
  background: color || "#000",
  marginLeft: "5px", // Space between dots
}));
const DotsContainer = styled.div({
  display: "flex",
  alignItems: "center",
  marginRight: "10px", // Space between dots and text
});

const dotVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: "easeInOut",
    },
  },
  exit: {
    opacity: 0,
    y: 10,
    transition: {
      duration: 0.4,
      ease: "easeInOut",
    },
  },
};

const LoadingText = () => {
  const animateDots = () => {
    const dots = Array.from(
      document.querySelectorAll<HTMLDivElement>(".loading-dot"),
    );
    let delay = 0;

    dots.forEach((dot) => {
      setTimeout(() => {
        dot.style.visibility = "visible";
        dot.classList.add("animate");
        setTimeout(() => {
          dot.style.visibility = "hidden";
        }, 800);
      }, delay);
      delay += 800; // Adjust the delay between each dot
    });

    setTimeout(animateDots, delay); // Repeat the animation
  };

  useEffect(() => {
    animateDots(); // Start animation on mount
  }, []);

  return (
    <div css={[displayFlex]}>
      <BodyLg css={[marginZero]}>Loading</BodyLg>
      <DotsContainer>
        <LoadingDot
          className="loading-dot"
          variants={dotVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        />

        <LoadingDot
          className="loading-dot"
          variants={dotVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        />

        <LoadingDot
          className="loading-dot"
          variants={dotVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        />
      </DotsContainer>
    </div>
  );
};

export default LoadingText;
