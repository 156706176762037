import { useContext, useEffect, useState } from "react";

import { CSSObject } from "@emotion/react";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";

import {
  Button,
  Column,
  Container,
  Heading,
  Placeholder,
  Row,
} from "@Components";
import { PageTitleContext } from "@Contexts/PageTitleContext";
import {
  colorChildren,
  colorPrimary,
  marginBottomXl,
  marginTopLg,
} from "@Styles";
import { Page } from "@Types/services/post";
import Error from "./Error";
import { PostsService } from "@Services/posts.service";
import { useCookieOptions } from "@Utils/useCookies";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { useTranslation } from "react-i18next";

const renderDataPrivacyPage = (
  content: string,
  handler: () => void,
  buttonLabel: string,
) => {
  const top = parse(content.substring(0, content.indexOf("Bewerbung")));
  const bottom = parse(content.substring(content.indexOf("Bewerbung")));

  const renderedContent = (
    <>
      {top}
      <Button
        fullWidth={{ default: true, md: false }}
        label={buttonLabel}
        variant="primary"
        size="md"
        onClick={handler}
        tone="initiative"
      />
      <br />
      {bottom}
    </>
  );

  return renderedContent;
};
const PagePage = () => {
  const postsService = PostsService.getInstance();
  const [page, setPage] = useState<Page>({ title: "", content: "" } as Page);
  const [hasError, setHasError] = useState<boolean>(false);
  const { setPageTitle } = useContext(PageTitleContext);
  const { resetCookies } = useCookieOptions();
  const { _id } = useParams();
  const { t } = useTranslation();

  const loadPage = async () => {
    if (!_id) {
      return setHasError(true);
    }

    try {
      const { data } = await postsService.getPage(_id);
      setPage(data);
    } catch {
      setHasError(true);
    }
  };

  useEffect(() => {
    loadPage();
  }, [_id]);

  useEffect(() => {
    setPageTitle(page.title);
    console.info(page.content.indexOf("Bewerbung"));
  }, [page, setPageTitle]);

  const containerCSS: CSSObject[] = [marginTopLg, marginBottomXl];

  const titleCSS: CSSObject[] = [colorPrimary("initiative")];

  const contentCSS: CSSObject[] = [colorChildren("initiative")];

  if (hasError) {
    return <Error />;
  }
  const handleCookieReset = () => {
    resetCookies();
  };

  let content = <Placeholder lines={3} />;
  if (page.content) {
    if (_id === "datenschutz") {
      content = renderDataPrivacyPage(
        page.content,
        handleCookieReset,
        t("components.cookieBanner.resetButtonLabel"),
      );
    } else {
      content = parse(page.content) as EmotionJSX.Element;
    }
  }

  return (
    <Container size="lg">
      <Row>
        <Column
          span={{ default: 12, md: 10, lg: 6 }}
          offset={{ md: 1, lg: 3 }}
        >
          <div css={containerCSS}>
            <div>
              <Heading
                scale={3}
                aria-level={1}
                css={titleCSS}
              >
                {page.title ? page.title : <Placeholder />}
              </Heading>
              <div css={contentCSS}>{content}</div>
            </div>
          </div>
        </Column>
      </Row>
    </Container>
  );
};

export default PagePage;
