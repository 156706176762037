import { DivisionComponentProps } from "@Types/components/pageProps";
import type { ReactNode } from "react";
import React from "react";

export interface IVisibleProps extends DivisionComponentProps {
  when?: any;
  children: ReactNode | ReactNode[] | JSX.Element | string;
}

const Visible = ({ when = false, children, ...props }: IVisibleProps) => (
  <React.Fragment {...props}>{when ? children : null}</React.Fragment>
);

export default Visible;
