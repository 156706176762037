import { Visible } from "@Atoms";
import { Loading } from "@Organisms";
import styled, { type CSSObject } from "@emotion/styled";
import { motion } from "framer-motion";
import React from "react";

interface BackdropProps {
  visible: boolean;
  onClose?: () => void;
  opacity?: number;
  color?: string;
  children?: React.ReactNode; // Backdrop content
  defaultContent?: React.ReactNode; // Default content when backdrop is not open
  width?: string;
  height?: string;
}
const BackdropOverlay = styled(motion.div)<{
  opacity: number;
  color: string;
  height?: string;
  width?: string;
  containerTop?: number;
  containerLeft?: number;
}>(({ theme, opacity, color, height, width }) => {
  const styles: CSSObject[] = [
    {
      position: "absolute",
      top: 0,
      left: 0,
      width,
      height,
      backgroundColor: color,
      opacity,
      zIndex: 1000,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
      borderRadius: theme.borderRadius.input,
    },
  ];
  return styles;
});

const BackdropContainer = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
});

const Backdrop: React.FC<BackdropProps> = ({
  visible,
  onClose,
  opacity = 0.7,
  color = "rgba(0, 0, 0, 0.5)",
  children,
  defaultContent = <Loading />,
  width = "100%",
  height = "100%",
}) => {
  return (
    <BackdropContainer>
      {/* Render default content when backdrop is not open */}
      {children}
      <Visible when={visible}>
        <BackdropOverlay
          color={color}
          width={width}
          height={height}
          opacity={opacity}
          initial={{ opacity: 1 }}
          animate={{ opacity }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          {defaultContent}
        </BackdropOverlay>
      </Visible>
      {/* Render the backdrop overlay when open */}
    </BackdropContainer>
  );
};

export default Backdrop;
