import { CSSObject } from "@emotion/react";
import { rgba } from "emotion-rgba";

const borderRadius = ".5rem";
const shadowBaseColor = "#828894";
const shadowColor1 = rgba(shadowBaseColor, 0.16);
const shadowColor2 = rgba(shadowBaseColor, 0.08);

export const elevation1: CSSObject = {
  borderRadius,
  boxShadow: `0px 0px 1px ${shadowColor1}, 0px 2px 4px ${shadowColor1}`,
};

export const elevation2: CSSObject = {
  borderRadius,
  boxShadow: `0px 0px 2px ${shadowColor1}, 0px 4px 6px ${shadowColor1}`,
};

export const elevation3: CSSObject = {
  borderRadius,
  boxShadow: `0px 0px 2px ${shadowColor2}, 0px 0px 4px ${shadowColor1}, 0px 8px 8px ${shadowColor1}`,
};

export const reversedElevation3: CSSObject = {
  borderRadius,
  boxShadow: `0px 0px 2px ${shadowColor2}, 0px 0px 4px ${shadowColor1}, 0px -8px 8px ${shadowColor1}`,
};

export const menuElevation: CSSObject = {
  borderRadius, // slightly rounder corners
  boxShadow: `
    0px 2px 4px ${shadowColor1}, /* light, subtle shadow */
    0px 8px 16px ${shadowColor1} /* deeper shadow for more depth */
  `,
};
