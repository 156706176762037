import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import { BannerText, Placeholder } from "@Components";
import { paddingLeftMdLg, paddingRightMdLg, paddingYLgXl } from "@Styles";
import type {
  PageFragmentProps,
  SectionPageProps,
} from "@Types/components/pageProps";
import type { Fragment } from "@Types/services/post";
import { CSSObject } from "@emotion/react";
import routes from "@Configs/routes";

const getFragments = ({ loaded, page }: PageFragmentProps) => {
  const fragments = (
    (loaded &&
      page?.fragments &&
      page?.fragments?.filter(
        (fragment: Fragment) => fragment?.section == "feature-fragment",
      )) ||
    []
  ).map((fragment: Fragment) => {
    return {
      buttonLink: routes.petition.start,
      attachment: fragment.featuredImage,
      ...fragment,
    };
  });

  return fragments;
};

type Layout = {
  imagePosition: "left" | "right";
  contentOffset: number;
  imageOffset: number;
  contentPadding: CSSObject;
  imagePadding: CSSObject;
};

const Features = ({ loaded, page, ...props }: SectionPageProps) => {
  const fragments = getFragments({ loaded, page });
  const { t } = useTranslation();
  const FEATURES_KEYS = [
    {
      title: "pages.aboutUs.features.fragments.fragment1.title",
      buttonLabel: null,
    },
    {
      title: "pages.aboutUs.features.fragments.fragment2.title",
      buttonLabel: t("pages.aboutUs.features.fragments.fragment2.buttonLabel"),
      buttonLink: "https://verein.innn.it/satzung/",
    },
    {
      title: "pages.aboutUs.features.fragments.fragment3.title",
      buttonLabel: t("pages.aboutUs.features.fragments.fragment3.buttonLabel"),
      buttonLink: "https://verein.innn.it/",
    },
    {
      title: "pages.aboutUs.features.fragments.fragment4.title",
      buttonLabel: null,
    },
  ];
  // Function to determine layout based on image position

  const determineLayout = (order: number): Layout => {
    const isLeftAligned = order % 2 === 0;

    return {
      imagePosition: isLeftAligned ? "left" : "right",
      contentOffset: isLeftAligned ? 1 : 0,
      imageOffset: isLeftAligned ? 0 : 1,
      contentPadding: isLeftAligned ? paddingRightMdLg : paddingLeftMdLg,
      imagePadding: isLeftAligned ? paddingLeftMdLg : paddingRightMdLg,
    };
  };

  // Function to get column definitions
  const getColumnDefinitions = (
    contentOffset: number,
    imageOffset: number,
    contentPadding: CSSObject,
    imagePadding: CSSObject,
  ) => ({
    content: {
      offset: {
        default: 0,
        md: contentOffset,
        lg: contentOffset,
      },
      css: [paddingYLgXl, contentPadding],
    },
    image: {
      span: { default: 12, md: 5, lg: 5 },
      offset: {
        default: 0,
        md: imageOffset,
        lg: imageOffset,
      },
      css: [imagePadding],
    },
  });

  return (
    <section>
      {loaded ? (
        fragments?.map((feature) => {
          // Get layout based on the feature order
          const {
            imagePosition,
            contentOffset,
            imageOffset,
            contentPadding,
            imagePadding,
          } = determineLayout(feature.order);

          const selectedFeature = FEATURES_KEYS.find(
            (key) => key.title === feature.title,
          );

          // Get column definitions
          const columns = getColumnDefinitions(
            contentOffset,
            imageOffset,
            contentPadding,
            imagePadding,
          );

          return (
            <BannerText
              key={feature._id}
              {...{
                ...feature,
                title: t(feature.title),
                content: parse(t(feature.content as string)),
                imagePosition,
                buttonLabel: selectedFeature?.buttonLabel,
                buttonLink: selectedFeature?.buttonLink || "/",
              }}
              columns={columns}
              {...props}
            />
          );
        })
      ) : (
        <Placeholder />
      )}
    </section>
  );
};
export default Features;
