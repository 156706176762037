import { CSSObject } from "@emotion/react";

import { Link as LinkComponent } from "./typography";
import { Attachment } from "../../types/services/post";
import { ImageSizeNames, imgWidths } from "../variables/imageSizes";
import { ConditionalWrapper } from "./ConditionalWrapper";
import Placeholder from "./Placeholder";

export interface AttachmentProps {
  /** Alternative text for the image. */
  alt?: string;
  /** Attachment object, based on the featured image object. Should come from the API. */
  attachment?: Attachment;
  /** URL */
  href?: string;
  /** Size that the image is going to be displayed. */
  size: ImageSizeNames;
  /** Display the image as a thumbnail. */
  thumbnail?: boolean;
  /** Any custom CSS to be applied to the image */
  imageCss?: CSSObject[];
}

const allowedMimeTypes = ["image/gif", "image/jpeg", "image/png"];

const containerStyle: CSSObject = {
  position: "relative",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "100%",
  overflow: "hidden",
};

const pictureStyle: CSSObject = {
  display: "flex",
  height: "100%",
  width: "100%",
};

const imgStyle: CSSObject = {
  display: "block",
  width: "100%",
  height: "auto",
  objectFit: "cover",
  borderRadius: 0,
};

const overlayStyle: CSSObject = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background:
    "linear-gradient(180deg, rgba(0, 0, 0, 0) 84.11%, rgba(0, 0, 0, 0.2) 100%)",
  pointerEvents: "none",
};

const placeholderContainerStyle: CSSObject = {
  height: "100%",
  width: "100%",
};

/** Component to display featured images from posts. */
const AttachmentImage = ({
  alt,
  attachment,
  href,
  size = "sm",
  thumbnail = false,
  imageCss = [],
}: AttachmentProps) => {
  const imageSource = attachment?.urls?.[size] || attachment?.urls?.original;

  let webpSource;
  if ("original" == size) {
    webpSource = attachment?.urls?.webp;
  } else {
    webpSource = attachment?.urls?.[`${size}Webp`] || attachment?.urls?.webp;
  }

  let aspectRatio: number | "auto" = 0;
  switch (size) {
    case "original":
      aspectRatio = "auto";
      break;
    case "thumb":
      aspectRatio = 1;
      break;
    default:
      aspectRatio = 3 / 2;
      break;
  }

  imgStyle.aspectRatio = aspectRatio;
  containerStyle.aspectRatio = aspectRatio;
  containerStyle.width = imgWidths[size];

  if (
    !attachment ||
    !allowedMimeTypes.includes(attachment.mimeType) ||
    (!imageSource && !webpSource)
  ) {
    attachment = undefined;
  }

  if (true === thumbnail) {
    containerStyle.borderRadius = "0.5rem 0.5rem 0 0";
  }

  return (
    <div css={containerStyle}>
      {attachment ? (
        <ConditionalWrapper
          condition={Boolean(href)}
          wrapper={(children: JSX.Element): JSX.Element => (
            <LinkComponent href={href || ""}>{children}</LinkComponent>
          )}
        >
          <picture css={pictureStyle}>
            {webpSource && (
              <source
                srcSet={webpSource}
                type="image/webp"
              />
            )}
            <source
              srcSet={imageSource}
              type={attachment.mimeType}
            />
            <img
              alt={alt}
              css={[imgStyle, ...imageCss]}
              src={imageSource}
              width={containerStyle.width}
            />
          </picture>
        </ConditionalWrapper>
      ) : (
        <div css={placeholderContainerStyle}>
          <Placeholder />
        </div>
      )}
      {thumbnail && <div css={overlayStyle} />}
    </div>
  );
};

export default AttachmentImage;
