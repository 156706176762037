import { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import isEmpty from "validator/lib/isEmpty";
import isPostalCode, { PostalCodeLocale } from "validator/lib/isPostalCode";

import { ValidationMessage, ValidationRules, validate } from "./validation";
import { Location, PostalCode } from "../../types/services/post";
import api from "../../utils/api";

type LocationProperties = {
  country: string;
  postalCode?: PostalCode;
  location?: Location;
};

type OriginalLocationProperties = {
  country?: string;
  postalCode?: string;
  location?: string;
};
type MappedLocations = { label?: string; value?: string }[];

export const useLocation = ({
  country = "DE",
  location = "",
  postalCode = "",
}: OriginalLocationProperties) => {
  const { t } = useTranslation();
  const [postalCodeInput, setPostalCode] = useState("");

  const defaultLocationError = useMemo(
    () => ({
      valid: true,
      message: "",
    }),
    [],
  );

  const [postalCodeError, setPostalCodeError] =
    useState<ValidationMessage>(defaultLocationError);
  const [locationProperties, setLocationProperties] =
    useState<LocationProperties>({ country });
  const [locations, setLocations] = useState<MappedLocations>([]);

  const getLocations = useCallback(
    async (postalCodeInput: string) => {
      const country = locationProperties.country;

      setLocations([]);
      setLocationProperties((prev) => ({
        ...prev,
        postalCode: undefined,
        location: undefined,
      }));

      if (postalCodeInput.length < 5) {
        return handleLocationProperties({ country });
      }

      if (!isPostalCode(postalCodeInput, country as PostalCodeLocale)) {
        setPostalCodeError({
          valid: false,
          message: t("components.userForm.validation.postalCode.notValid"),
        });
        return handleLocationProperties({ country });
      }

      const params = {
        country,
        postalCode: postalCodeInput,
        withPostalCodes: true,
      };

      api
        .getLocations(params)
        .then((response) => {
          if (!response.data || response.data.length == 0) {
            throw new Error();
          }
          setPostalCodeError(defaultLocationError);

          const mappedLocations: MappedLocations = response.data.map(
            (location: Location) => ({
              label: location.name,
              value: location._id,
            }),
          );
          setLocations(mappedLocations);

          const postalCode: PostalCode | undefined =
            response.data[0].postalCodes?.find(
              (postalCode) => postalCode.postalCode === postalCodeInput,
            );
          if (response.data.length === 1) {
            handleLocationProperties({
              country,
              postalCode,
              location: response.data[0],
            });
          } else {
            handleLocationProperties({
              country,
              postalCode,
            });
          }
        })
        .catch(() => {
          setPostalCodeError({
            valid: false,
            message: t(
              "components.userForm.validation.postalCode.noLocalities",
            ),
          });
        });
    },
    [defaultLocationError, locationProperties.country, t],
  );

  const getLocationProperties = useCallback(
    ({ country = "DE", postalCode, location }: OriginalLocationProperties) => {
      const properties: LocationProperties = {
        country,
        location: undefined,
        postalCode: undefined,
      };

      if (!postalCode) {
        return handleLocationProperties({ country });
      }

      api
        .getPostalCode(postalCode)
        .then((response) => {
          properties.postalCode = response.data;
          properties.country = response.data.country;

          if (
            !location ||
            !response.data?.localities ||
            !response.data?.localities.includes(location)
          ) {
            throw new Error();
          }

          api
            .getLocation(location)
            .then((response) => {
              properties.location = response.data;
              return handleLocationProperties(properties);
            })
            .catch(null);
        })
        .catch(null);
    },
    [],
  );

  const handleLocationProperties = ({
    country = "DE",
    postalCode,
    location,
  }: LocationProperties) => {
    const properties: LocationProperties = {
      country,
      location: undefined,
      postalCode: undefined,
    };

    // Locations outside Germany are not processed
    if ("DE" !== country) {
      return setLocationProperties(properties);
    }

    // If only country is set, more information is to come
    if (country && !postalCode && !location) {
      return setLocationProperties(properties);
    }

    // If there's postal code, set it for the moment
    if (postalCode?._id) {
      properties.postalCode = postalCode;
    }

    if (
      location?._id &&
      postalCode?._id &&
      postalCode?.postalCode &&
      postalCode?.localities &&
      postalCode?.localities.includes(location._id)
    ) {
      properties.location = location;
    }
    return setLocationProperties(properties);
  };

  const validateLocation = (locationProperties: LocationProperties) => {
    const validationRules: ValidationRules = {
      postalCode: [
        {
          message: t("components.userForm.validation.postalCode.notEmpty"),
          valid:
            "DE" != locationProperties.country || !isEmpty(postalCodeInput),
        },
        {
          message: t("components.userForm.validation.postalCode.notValid"),
          valid:
            "DE" != locationProperties.country ||
            isPostalCode(postalCodeInput, locationProperties.country),
        },
      ],
      location: [
        {
          message: t("components.userForm.validation.location.notEmpty"),
          valid:
            "DE" != locationProperties.country ||
            !!locationProperties.location?._id,
        },
      ],
    };

    return validate(validationRules);
  };

  useEffect(() => {
    if (postalCodeInput) {
      getLocations(postalCodeInput);
    }
  }, [postalCodeInput, getLocations]);

  useEffect(() => {
    getLocationProperties({ country, postalCode, location });
  }, [country, postalCode, location, getLocationProperties]);

  return {
    locationProperties,
    locations,
    postalCodeError,
    setPostalCode,
    validateLocation,
  };
};
