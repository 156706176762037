import { Signable } from "../types/services/post";

export const getSignableCountdown = (
  signable: Signable,
): number | undefined => {
  try {
    const endDate =
      signable.type === "initiative"
        ? signable?.currentStage?.endDate
        : signable?.endDate;

    if (!endDate) {
      return undefined;
    }

    const dayInMilliseconds = 24 * 60 * 60 * 1000;
    const now = new Date();
    const endDateDate = new Date(endDate);
    const countdown = Math.ceil(
      (endDateDate.getTime() - now.getTime()) / dayInMilliseconds,
    );

    return countdown;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
