import { CSSObject } from "@emotion/react";
import { marginLeftXl, marginLeftZero } from "@Styles";
import { breakpointDown } from "@Variables/breakpoints";

const headerStyle: CSSObject = {
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const signableTagsStyle: CSSObject = {
  width: "12rem",
  marginBottom: "-1rem",
  ...marginLeftXl,
  [breakpointDown["md"]]: {
    width: "100%",
    ...marginLeftZero,
  },
};

export { headerStyle, signableTagsStyle };
