import { useTheme } from "@emotion/react";
import { marginBottomSm, marginBottomZero } from "@Styles";
import type { TextInputProps } from "@Types";
import { FC } from "react";
import { Visible } from "../atoms";
import { inputCSS, inputErrorStyle, inputStyle } from "./Input.styles";

const TextInput: FC<TextInputProps> = ({
  type = "text",
  name,
  value,
  label,
  onChange,
  onBlur,
  placeholder,
  error,
  ...props
}) => {
  const { colors, tone } = useTheme();

  return (
    <div>
      <label css={marginBottomZero}>
        <Visible when={!!label}>
          <div css={marginBottomSm}>{label}</div>
        </Visible>

        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={false}
          placeholder={placeholder}
          css={[
            inputCSS(colors),
            inputStyle,
            inputErrorStyle(tone, !!error?.message),
            marginBottomZero,
          ]}
          {...props}
        />
      </label>
    </div>
  );
};

export default TextInput;
