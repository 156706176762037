import { BannerIllustration } from "@Components";
import routes from "@Configs/routes";
import { useTranslation } from "react-i18next";

const InitiativeBannerIllustration = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "common.banners.initiativeContact",
  });

  return (
    <BannerIllustration
      buttonLabel={t("buttonLabel")}
      buttonLink={routes.initiative.start}
      content={t("content")}
      illustration="start"
      title={t("title")}
    />
  );
};

export default InitiativeBannerIllustration;
