import { CSSObject } from "@emotion/react";

import {
  bodySmStyle,
  colorBlack,
  colorPrimary,
  displayFlex,
  fontWeightRegular,
  gapLg,
  gapMd,
  gapSm,
  gapXl,
  marginBottomLg,
  marginBottomMd,
  marginBottomMdZero,
  marginBottomXsXl,
  marginBottomZero,
  marginTopLg,
  marginTopXl,
  paddingYLg,
  plainListStyle,
} from "@Styles";
import { breakpointDown, breakpointUp } from "@Variables/breakpoints";
import colors from "@Variables/colors";

const borderTopStyle: CSSObject = {
  borderTop: `1px solid ${colors.gray3}`,
};

const footerCSS: CSSObject[] = [borderTopStyle, marginBottomLg];
const footerLowerCSS: CSSObject[] = [paddingYLg, borderTopStyle];
const sharedColumnCSS: CSSObject[] = [
  marginTopXl,
  {
    [breakpointDown["md"]]: {
      ...marginTopLg,
      marginBottom: "0",
    },
  },
];

//#region General Styles
const columnCSS: CSSObject[] = [
  marginBottomXsXl,
  marginBottomMdZero,
  {
    [breakpointDown["md"]]: {
      // ...marginTopLg,
      marginBottom: "0",
    },
  },
];
//#endregion General Styles

const logoContainerCSS: CSSObject[] = [
  marginBottomMd,
  {
    display: "block",
    height: "3rem",
    width: "6.234rem",
  },
];

//#region AboutUs - Link List(first column)

const linkCSS: CSSObject[] = [
  colorPrimary("initiative"),
  fontWeightRegular,
  {
    textDecoration: "none",
  },
];

const linkListCSS: CSSObject[] = [
  gapSm,
  plainListStyle,
  displayFlex,
  {
    flexDirection: "column",
  },
];

//#region AboutUs(Second Column)
const linkListItemLinkCSS: CSSObject[] = [...linkCSS, colorBlack];

const socialMediaListCSS: CSSObject[] = [
  gapMd,
  plainListStyle,
  {
    display: "flex",
    justifyContent: "space-around",
    [breakpointUp["md"]]: {
      justifyContent: "end",
    },
    [breakpointDown["md"]]: {
      ...gapLg,
    },
  },
];

const infoCSS: CSSObject[] = [
  bodySmStyle,
  gapXl,
  plainListStyle,
  displayFlex,

  {
    flexDirection: "column",
    textAlign: "center",
    [breakpointUp["md"]]: {
      flexDirection: "row",
    },
    li: {
      listStyle: "none",
      margin: 0,
    },
  },
];

const colophonCSS: CSSObject[] = [
  marginBottomZero,
  {
    textAlign: "center",

    [breakpointUp["md"]]: {
      textAlign: "start",
    },
  },
];

export {
  colophonCSS,
  columnCSS,
  footerCSS,
  footerLowerCSS,
  infoCSS,
  linkCSS,
  linkListCSS,
  linkListItemLinkCSS,
  logoContainerCSS,
  sharedColumnCSS,
  socialMediaListCSS,
};
