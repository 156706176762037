import type { BorderRadius, StyleVariants } from "@Types/configs";
import linearGradient from "@Utils/colorGradient";
import {
  generatePaletteColor,
  initiativeOpacityVariants,
  petitionsOpacityVariants,
} from "./helpers";

const borderRadius: BorderRadius = {
  card: "2rem",
  image: "0.5rem",
  input: "0.375rem",
};

const styles: StyleVariants = {
  initiative: {
    tone: "initiative",
    colors: {
      accent: generatePaletteColor(initiativeOpacityVariants.accent),
      label: generatePaletteColor(initiativeOpacityVariants.label),
      primary: generatePaletteColor(initiativeOpacityVariants.primary),
      secondary: generatePaletteColor(initiativeOpacityVariants.secondary),
      tertiary: generatePaletteColor(initiativeOpacityVariants.tertiary),
      white: generatePaletteColor(initiativeOpacityVariants.white),
      danger: generatePaletteColor(initiativeOpacityVariants.danger),
      success: generatePaletteColor(initiativeOpacityVariants.success),
      gradient: linearGradient(
        initiativeOpacityVariants.primary[100],
        initiativeOpacityVariants.secondary[100],
      ),
    },
    borderRadius,
  },

  petition: {
    tone: "petition",
    colors: {
      accent: generatePaletteColor(petitionsOpacityVariants.accent),
      label: generatePaletteColor(petitionsOpacityVariants.label),
      primary: generatePaletteColor(petitionsOpacityVariants.primary),
      secondary: generatePaletteColor(petitionsOpacityVariants.secondary),
      tertiary: generatePaletteColor(petitionsOpacityVariants.tertiary),
      white: generatePaletteColor(petitionsOpacityVariants.white),
      danger: generatePaletteColor(petitionsOpacityVariants.danger),
      success: generatePaletteColor(petitionsOpacityVariants.success),
      gradient: linearGradient(
        petitionsOpacityVariants.primary[100],
        petitionsOpacityVariants.secondary[100],
      ),
    },
    borderRadius,
  },
};

export default styles;
