import type { AxiosResponse } from "axios";
import BaseService from "./base.service";
import { UserFormFields } from "@Components";
import apiRoutes from "./serviceRoutes";

export class EmailService extends BaseService {
  private static instance: EmailService;
  private routes = apiRoutes.email;

  // Singleton instance getter
  public static getInstance(): EmailService {
    if (!EmailService.instance) {
      EmailService.instance = new EmailService();
    }

    return EmailService.instance;
  }

  public subscribeNewsletter(
    payload: UserFormFields & { source: string },
  ): Promise<AxiosResponse<any, any>> {
    return this.post(this.routes.subscribeNewsletter, payload);
  }
}
