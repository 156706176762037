import api from "@Utils/api";

export const fetchPetitions = async (offset: number, limit = 6) => {
  return api
    .getSignables({
      type: "petition",
      initiator: "me",
      status: ["draft", "publish", "trash"],
      offset,
      limit,
      sort: "createdAt",
    })
    .then((response) => response.data);
};
