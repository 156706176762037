import { Loading } from "@Components";
import routes from "@Configs/routes";
import { ThemeProvider as EmotionThemeProvider, Theme } from "@emotion/react";
import type { Tone } from "@Variables";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styles from "./StylesContext/styles";

export type SetTone = React.Dispatch<React.SetStateAction<Tone>>;

// Define whitelist as a constant set outside of the component
const whitelist = new Set([
  routes.petition.root,
  routes.root,
  routes.postRedirection,
]);

const initiativeThemeRoutes = new Set([
  routes.initiative.faq,
  routes.initiative.search,
  routes.initiative.start,
]);

export type ThemeProviderProps = { children: React.ReactNode };
const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [tone, setTone] = useState<Tone>("petition");
  const [isLoading, setIsLoading] = useState(true); // Set initial loading state to true
  const scrollPositionY = useRef(0);

  const location = useLocation();
  const params = useParams<{ _id: string }>();

  useEffect(() => {
    const currentPath = location.pathname;
    const isDynamicRouteMatch =
      params._id !== undefined && location.pathname === `/${params._id}`;

    const newTone = initiativeThemeRoutes.has(currentPath)
      ? "initiative"
      : "petition";

    if (newTone !== tone) {
      setIsLoading(true); // Set loading to true before changing the tone
      setTone(newTone);
    } else if (whitelist.has(currentPath) || isDynamicRouteMatch) {
      setTone("petition");
    }
  }, [location.pathname, params._id, setTone]);

  useEffect(() => {
    if (isLoading) {
      // Store the current scroll position
      scrollPositionY.current = window.scrollY;
      // Hide the scrollbar by setting overflow to hidden
      document.body.style.overflow = "hidden";
      // Simulate a delay to show the loading indicator
      const timer = setTimeout(() => {
        setIsLoading(false); // Set loading to false after the theme has been applied
        document.body.style.overflow = ""; // Reset overflow
        // Restore the scroll position
        // window.scrollTo(0, scrollPositionY.current);
      }, 300); // Adjust the delay as needed

      return () => {
        clearTimeout(timer);
        document.body.style.overflow = ""; // Reset overflow
        // Restore the scroll position
        // window.scrollTo(0, scrollPositionY.current);
      };
    }
  }, [isLoading]);

  const currentTheme = useMemo((): Theme => {
    return {
      ...styles[tone],
      setTone,
    };
  }, [tone]);

  return (
    <EmotionThemeProvider theme={currentTheme}>
      <AnimatePresence>
        {isLoading ? (
          <motion.div
            key="loading"
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <Loading fixed />
          </motion.div>
        ) : (
          <motion.div
            key="content"
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </EmotionThemeProvider>
  );
};

export default ThemeProvider;
