import type { AdjustedSignableType } from "@Types/pages/typeUserProfile";
import type { Signable } from "@Types/services/post";
import api from "@Utils/api";

export const fetchSignatures = async (
  offset: number,
  limit = 6,
): Promise<AdjustedSignableType[]> => {
  const signatures = (
    await api.getSignatures({
      offset,
      limit,
      signer: "me",
    })
  )?.data;

  const ids = signatures?.map((signature) => signature?.signableId);

  if (ids.length === 0) {
    return [];
  }

  const signables = (
    await api.getSignables({
      type: "petition",
      ids,
    })
  ).data;

  const filteredResult = signatures.map((signature) => {
    const signable: Signable = signables.find((signable) => {
      return signable?._id === signature?.signableId;
    }) as unknown as Signable;
    return {
      ...signable,
      signatureId: signature?._id,
      createdAt: signature.verifiedAt as string,
    };
  });

  return filteredResult;
};
