import { Navigate, createBrowserRouter } from "react-router-dom";

import { routes } from "@Configs";
import App from "./App";
import {
  AboutPage,
  AuthenticationPage,
  ErrorPage,
  FaqPage,
  HomeInitiativePage,
  HomePage,
  PSFPage,
  PostRedirectionPage,
  SignablePage,
  StartPetitionPage,
  UserProfilePage,
  VerificationPage,
} from "./pages";

const userRoutes = [
  routes.petition.user.account,
  routes.petition.user.petitions,
  routes.petition.user.signatures,
].map((path: string) => ({
  path,
  element: <UserProfilePage />,
}));

const initiativeRoutes = [routes.initiative.root, routes.initiative.search].map(
  (path: string) => ({
    path,
    element: <HomeInitiativePage />,
  }),
);

const signableRoutes = [routes.updates, routes.updatesId, routes.share].map(
  (path: string) => ({
    path,
    element: <SignablePage />,
  }),
);

const router = createBrowserRouter([
  {
    path: routes.root,
    element: <App />,
    children: [
      { path: routes.root, element: <HomePage /> },

      { path: routes.about, element: <AboutPage /> },
      { path: routes.authenticate, element: <AuthenticationPage /> },
      { path: routes.error, element: <ErrorPage /> },
      { path: routes.faq, element: <FaqPage /> },
      {
        path: routes.petition.root,
        element: (
          <Navigate
            to={routes.root}
            replace
          />
        ),
      },
      { path: routes.petition.start, element: <StartPetitionPage /> },
      { path: routes.petition.startById, element: <StartPetitionPage /> },
      { path: routes.verify, element: <VerificationPage /> },
      { path: routes.postRedirection, element: <PostRedirectionPage /> },

      { path: routes.psfStep, element: <PSFPage /> },
      { path: routes.notFound, element: <ErrorPage /> },

      // Grouped petition user routes
      ...userRoutes,

      ...initiativeRoutes,

      ...signableRoutes,
    ],
  },
]);

export default router;
