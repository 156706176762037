import { CSSObject, useTheme } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  bgGradient,
  displayFlex,
  marginBottomLg,
  marginBottomMd,
  marginY2xl,
} from "@Styles";
import Button from "./Button";
import Column, { ColumnProps } from "./Column";
import { Container, ContainerProps } from "./Container";
import { Heading } from "./Heading";
import Illustration, { IllustrationNames } from "./Illustration";
import Row from "./Row";
import { BodyLg } from "./typography";

export interface BannerIllustrationProps extends ContainerProps {
  /** Title of the banner */
  title: string;
  /** Content of the banner. Accepts JSX */
  content: string;
  /** Label of the call-to-action*/
  buttonLabel: string;
  /** URL for the call-to-action */
  buttonLink: string;
  //  Illustration name
  illustration: IllustrationNames;
  /** FontAwesome icon to be displayed in the button. */
  icon?: IconProp;

  columns?: {
    image?: Omit<ColumnProps, "children">;
    content?: Omit<ColumnProps, "children">;
  };
}

const containerCSS: CSSObject[] = [
  {
    width: "100%",
  },
];

const contentStyle: CSSObject = marginBottomLg;
const illustrationContainerStyle: CSSObject[] = [
  {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
];

const rowCSS: CSSObject[] = [];

/**
 * Displays a banner with an illustration.
 */
const DonorBannerIllustration = ({
  title,
  content,
  buttonLabel,
  buttonLink,
  illustration,
  icon,
  columns,
  ...props
}: BannerIllustrationProps) => {
  const { tone } = useTheme();

  const buttonProps = { icon };

  containerCSS.push(bgGradient(tone, "light"));
  const titleCSS: CSSObject[] = [marginBottomMd, { alignSelf: "stretch" }];

  return (
    <Container
      size="fw"
      {...props}
      css={[containerCSS]}
    >
      <Container size="lg">
        <Row css={rowCSS}>
          <Column
            span={{ default: 12, md: 5 }}
            css={[marginY2xl]}
            {...columns?.content}
          >
            <Heading
              scale={2}
              variant="display"
              color="primary"
              css={titleCSS}
            >
              {title}
            </Heading>

            <BodyLg css={[contentStyle]}>{content}</BodyLg>

            <Button
              as="a"
              fullWidth={{ default: true, md: false }}
              href={buttonLink}
              label={buttonLabel}
              variant="primary"
              {...buttonProps}
            />
          </Column>

          <Column
            offset={{ default: 0, md: 2 }}
            span={{ default: 12, md: 5 }}
            css={[displayFlex, { justifyContent: "center" }]}
            {...columns?.image}
          >
            <Illustration
              name={illustration}
              size="md"
              css={illustrationContainerStyle}
            />
          </Column>
        </Row>
      </Container>
    </Container>
  );
};

export default DonorBannerIllustration;
