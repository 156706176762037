import { CSSObject } from "@emotion/react";

import { getRfsValue } from "../helpers/rfs";
import toCamelCase from "../helpers/toCamelCase";
import { breakpointUp, breakpoints } from "../variables/breakpoints";
import { spacers } from "../variables/scales";

interface sidePropertiesType {
  [key: string]: Array<string>;
}

export type propertyNameType =
  | "gap"
  | "margin"
  | "marginNeg"
  | "padding"
  | "rowGap";

const sides: sidePropertiesType = {
  all: [""],
  top: ["top"],
  right: ["right"],
  bottom: ["bottom"],
  left: ["left"],
  x: ["left", "right"],
  y: ["top", "bottom"],
};

const createSpacers = (property: propertyNameType) => {
  const spacerStyles: { [key: string]: CSSObject } = {};

  for (const side of Object.keys(sides)) {
    if ("gap" == property && "all" != side) continue;

    const propertyName: string = "marginNeg" == property ? "margin" : property;
    const sideName = "all" == side ? "" : toCamelCase(side);
    const zeroProperties: CSSObject = {};

    for (const sideProperty of sides[side]) {
      zeroProperties[propertyName + toCamelCase(sideProperty)] = "0";
    }
    spacerStyles[property + sideName + "Zero"] = zeroProperties;

    for (const size of Object.keys(spacers)) {
      const properties: CSSObject = {};
      const sizeName: string = toCamelCase(size);
      const value = getRfsValue(
        "marginNeg" == property ? -spacers[size] : spacers[size],
      );

      for (const sideProperty of sides[side]) {
        properties[propertyName + toCamelCase(sideProperty)] = value;
      }
      spacerStyles[property + sideName + sizeName] = properties;

      for (const breakpoint of breakpoints) {
        spacerStyles[property + sideName + toCamelCase(breakpoint) + "Zero"] = {
          [breakpointUp[breakpoint]]: zeroProperties,
        };
        spacerStyles[property + sideName + toCamelCase(breakpoint) + sizeName] =
          {
            [breakpointUp[breakpoint]]: properties,
          };
      }
    }
  }

  return spacerStyles;
};

const gapStyles = createSpacers("gap");
const rowGapStyles = createSpacers("rowGap");
const marginStyles = createSpacers("margin");
const marginNegStyles = createSpacers("marginNeg");
const paddingStyles = createSpacers("padding");

export const gapZero = gapStyles["gapZero"];
export const gap2xs = gapStyles["gap2xs"];
export const gapXs = gapStyles["gapXs"];
export const gapSm = gapStyles["gapSm"];
export const gapMd = gapStyles["gapMd"];
export const gapLg = gapStyles["gapLg"];
export const gapXl = gapStyles["gapXl"];
export const gap2xl = gapStyles["gap2xl"];
export const gap3xl = gapStyles["gap3xl"];
export const gap4xl = gapStyles["gap4xl"];
export const gap5xl = gapStyles["gap5xl"];

export const gapXsZero = gapStyles["gapXsZero"];
export const gapXs2xs = gapStyles["gapXs2xs"];
export const gapXsXs = gapStyles["gapXsXs"];
export const gapXsSm = gapStyles["gapXsSm"];
export const gapXsMd = gapStyles["gapXsMd"];
export const gapXsLg = gapStyles["gapXsLg"];
export const gapXsXl = gapStyles["gapXsXl"];
export const gapXs2xl = gapStyles["gapXs2xl"];
export const gapXs3xl = gapStyles["gapXs3xl"];
export const gapXs4xl = gapStyles["gapXs4xl"];
export const gapXs5xl = gapStyles["gapXs5xl"];

export const gapSmZero = gapStyles["gapSmZero"];
export const gapSm2xs = gapStyles["gapSm2xs"];
export const gapSmXs = gapStyles["gapSmXs"];
export const gapSmSm = gapStyles["gapSmSm"];
export const gapSmMd = gapStyles["gapSmMd"];
export const gapSmLg = gapStyles["gapSmLg"];
export const gapSmXl = gapStyles["gapSmXl"];
export const gapSm2xl = gapStyles["gapSm2xl"];
export const gapSm3xl = gapStyles["gapSm3xl"];
export const gapSm4xl = gapStyles["gapSm4xl"];
export const gapSm5xl = gapStyles["gapSm5xl"];

export const gapMdZero = gapStyles["gapMdZero"];
export const gapMd2xs = gapStyles["gapMd2xs"];
export const gapMdXs = gapStyles["gapMdXs"];
export const gapMdSm = gapStyles["gapMdSm"];
export const gapMdMd = gapStyles["gapMdMd"];
export const gapMdLg = gapStyles["gapMdLg"];
export const gapMdXl = gapStyles["gapMdXl"];
export const gapMd2xl = gapStyles["gapMd2xl"];
export const gapMd3xl = gapStyles["gapMd3xl"];
export const gapMd4xl = gapStyles["gapMd4xl"];
export const gapMd5xl = gapStyles["gapMd5xl"];

export const gapLgZero = gapStyles["gapLgZero"];
export const gapLg2xs = gapStyles["gapLg2xs"];
export const gapLgXs = gapStyles["gapLgXs"];
export const gapLgSm = gapStyles["gapLgSm"];
export const gapLgMd = gapStyles["gapLgMd"];
export const gapLgLg = gapStyles["gapLgLg"];
export const gapLgXl = gapStyles["gapLgXl"];
export const gapLg2xl = gapStyles["gapLg2xl"];
export const gapLg3xl = gapStyles["gapLg3xl"];
export const gapLg4xl = gapStyles["gapLg4xl"];
export const gapLg5xl = gapStyles["gapLg5xl"];

export const rowGapZero = rowGapStyles["rowGapZero"];
export const rowGap2xs = rowGapStyles["rowGap2xs"];
export const rowGapXs = rowGapStyles["rowGapXs"];
export const rowGapSm = rowGapStyles["rowGapSm"];
export const rowGapMd = rowGapStyles["rowGapMd"];
export const rowGapLg = rowGapStyles["rowGapLg"];
export const rowGapXl = rowGapStyles["rowGapXl"];
export const rowGap2xl = rowGapStyles["rowGap2xl"];
export const rowGap3xl = rowGapStyles["rowGap3xl"];
export const rowGap4xl = rowGapStyles["rowGap4xl"];
export const rowGap5xl = rowGapStyles["rowGap5xl"];

export const rowGapXsZero = rowGapStyles["rowGapXsZero"];
export const rowGapXs2xs = rowGapStyles["rowGapXs2xs"];
export const rowGapXsXs = rowGapStyles["rowGapXsXs"];
export const rowGapXsSm = rowGapStyles["rowGapXsSm"];
export const rowGapXsMd = rowGapStyles["rowGapXsMd"];
export const rowGapXsLg = rowGapStyles["rowGapXsLg"];
export const rowGapXsXl = rowGapStyles["rowGapXsXl"];
export const rowGapXs2xl = rowGapStyles["rowGapXs2xl"];
export const rowGapXs3xl = rowGapStyles["rowGapXs3xl"];
export const rowGapXs4xl = rowGapStyles["rowGapXs4xl"];
export const rowGapXs5xl = rowGapStyles["rowGapXs5xl"];

export const rowGapSmZero = rowGapStyles["rowGapSmZero"];
export const rowGapSm2xs = rowGapStyles["rowGapSm2xs"];
export const rowGapSmXs = rowGapStyles["rowGapSmXs"];
export const rowGapSmSm = rowGapStyles["rowGapSmSm"];
export const rowGapSmMd = rowGapStyles["rowGapSmMd"];
export const rowGapSmLg = rowGapStyles["rowGapSmLg"];
export const rowGapSmXl = rowGapStyles["rowGapSmXl"];
export const rowGapSm2xl = rowGapStyles["rowGapSm2xl"];
export const rowGapSm3xl = rowGapStyles["rowGapSm3xl"];
export const rowGapSm4xl = rowGapStyles["rowGapSm4xl"];
export const rowGapSm5xl = rowGapStyles["rowGapSm5xl"];

export const rowGapMdZero = rowGapStyles["rowGapMdZero"];
export const rowGapMd2xs = rowGapStyles["rowGapMd2xs"];
export const rowGapMdXs = rowGapStyles["rowGapMdXs"];
export const rowGapMdSm = rowGapStyles["rowGapMdSm"];
export const rowGapMdMd = rowGapStyles["rowGapMdMd"];
export const rowGapMdLg = rowGapStyles["rowGapMdLg"];
export const rowGapMdXl = rowGapStyles["rowGapMdXl"];
export const rowGapMd2xl = rowGapStyles["rowGapMd2xl"];
export const rowGapMd3xl = rowGapStyles["rowGapMd3xl"];
export const rowGapMd4xl = rowGapStyles["rowGapMd4xl"];
export const rowGapMd5xl = rowGapStyles["rowGapMd5xl"];

export const rowGapLgZero = rowGapStyles["rowGapLgZero"];
export const rowGapLg2xs = rowGapStyles["rowGapLg2xs"];
export const rowGapLgXs = rowGapStyles["rowGapLgXs"];
export const rowGapLgSm = rowGapStyles["rowGapLgSm"];
export const rowGapLgMd = rowGapStyles["rowGapLgMd"];
export const rowGapLgLg = rowGapStyles["rowGapLgLg"];
export const rowGapLgXl = rowGapStyles["rowGapLgXl"];
export const rowGapLg2xl = rowGapStyles["rowGapLg2xl"];
export const rowGapLg3xl = rowGapStyles["rowGapLg3xl"];
export const rowGapLg4xl = rowGapStyles["rowGapLg4xl"];
export const rowGapLg5xl = rowGapStyles["rowGapLg5xl"];

export const marginZero = marginStyles["marginZero"];
export const margin2xs = marginStyles["margin2xs"];
export const marginXs = marginStyles["marginXs"];
export const marginSm = marginStyles["marginSm"];
export const marginMd = marginStyles["marginMd"];
export const marginLg = marginStyles["marginLg"];
export const marginXl = marginStyles["marginXl"];
export const margin2xl = marginStyles["margin2xl"];
export const margin3xl = marginStyles["margin3xl"];
export const margin4xl = marginStyles["margin4xl"];
export const margin5xl = marginStyles["margin5xl"];

export const marginXsZero = marginStyles["marginXsZero"];
export const marginXs2xs = marginStyles["marginXs2xs"];
export const marginXsXs = marginStyles["marginXsXs"];
export const marginXsSm = marginStyles["marginXsSm"];
export const marginXsMd = marginStyles["marginXsMd"];
export const marginXsLg = marginStyles["marginXsLg"];
export const marginXsXl = marginStyles["marginXsXl"];
export const marginXs2xl = marginStyles["marginXs2xl"];
export const marginXs3xl = marginStyles["marginXs3xl"];
export const marginXs4xl = marginStyles["marginXs4xl"];
export const marginXs5xl = marginStyles["marginSm5xl"];

export const marginSmZero = marginStyles["marginSmZero"];
export const marginSm2xs = marginStyles["marginSm2xs"];
export const marginSmXs = marginStyles["marginSmXs"];
export const marginSmSm = marginStyles["marginSmSm"];
export const marginSmMd = marginStyles["marginSmMd"];
export const marginSmLg = marginStyles["marginSmLg"];
export const marginSmXl = marginStyles["marginSmXl"];
export const marginSm2xl = marginStyles["marginSm2xl"];
export const marginSm3xl = marginStyles["marginSm3xl"];
export const marginSm4xl = marginStyles["marginSm4xl"];
export const marginSm5xl = marginStyles["marginSm5xl"];

export const marginMdZero = marginStyles["marginMdZero"];
export const marginMd2xs = marginStyles["marginMd2xs"];
export const marginMdXs = marginStyles["marginMdXs"];
export const marginMdSm = marginStyles["marginMdSm"];
export const marginMdMd = marginStyles["marginMdMd"];
export const marginMdLg = marginStyles["marginMdLg"];
export const marginMdXl = marginStyles["marginMdXl"];
export const marginMd2xl = marginStyles["marginMd2xl"];
export const marginMd3xl = marginStyles["marginMd3xl"];
export const marginMd4xl = marginStyles["marginMd4xl"];
export const marginMd5xl = marginStyles["marginMd5xl"];

export const marginLgZero = marginStyles["marginLgZero"];
export const marginLg2xs = marginStyles["marginLg2xs"];
export const marginLgXs = marginStyles["marginLgXs"];
export const marginLgSm = marginStyles["marginLgSm"];
export const marginLgMd = marginStyles["marginLgMd"];
export const marginLgLg = marginStyles["marginLgLg"];
export const marginLgXl = marginStyles["marginLgXl"];
export const marginLg2xl = marginStyles["marginLg2xl"];
export const marginLg3xl = marginStyles["marginLg3xl"];
export const marginLg4xl = marginStyles["marginLg4xl"];
export const marginLg5xl = marginStyles["marginLg5xl"];

export const marginTopZero = marginStyles["marginTopZero"];
export const marginTop3xs = marginStyles["marginTop3xs"];
export const marginTop2xs = marginStyles["marginTop2xs"];
export const marginTopXs = marginStyles["marginTopXs"];
export const marginTopSm = marginStyles["marginTopSm"];
export const marginTopMd = marginStyles["marginTopMd"];
export const marginTopLg = marginStyles["marginTopLg"];
export const marginTopXl = marginStyles["marginTopXl"];
export const marginTop2xl = marginStyles["marginTop2xl"];
export const marginTop3xl = marginStyles["marginTop3xl"];
export const marginTop4xl = marginStyles["marginTop4xl"];
export const marginTop5xl = marginStyles["marginTop5xl"];

export const marginTopXsZero = marginStyles["marginTopXsZero"];
export const marginTopXs2xs = marginStyles["marginTopXs2xs"];
export const marginTopXsXs = marginStyles["marginTopXsXs"];
export const marginTopXsSm = marginStyles["marginTopXsSm"];
export const marginTopXsMd = marginStyles["marginTopXsMd"];
export const marginTopXsLg = marginStyles["marginTopXsLg"];
export const marginTopXsXl = marginStyles["marginTopXsXl"];
export const marginTopXs2xl = marginStyles["marginTopXs2xl"];
export const marginTopXs3xl = marginStyles["marginTopXs3xl"];
export const marginTopXs4xl = marginStyles["marginTopXs4xl"];
export const marginTopXs5xl = marginStyles["marginTopXs5xl"];

export const marginTopSmZero = marginStyles["marginTopSmZero"];
export const marginTopSm2xs = marginStyles["marginTopSm2xs"];
export const marginTopSmXs = marginStyles["marginTopSmXs"];
export const marginTopSmSm = marginStyles["marginTopSmSm"];
export const marginTopSmMd = marginStyles["marginTopSmMd"];
export const marginTopSmLg = marginStyles["marginTopSmLg"];
export const marginTopSmXl = marginStyles["marginTopSmXl"];
export const marginTopSm2xl = marginStyles["marginTopSm2xl"];
export const marginTopSm3xl = marginStyles["marginTopSm3xl"];
export const marginTopSm4xl = marginStyles["marginTopSm4xl"];
export const marginTopSm5xl = marginStyles["marginTopSm5xl"];

export const marginTopMdZero = marginStyles["marginTopMdZero"];
export const marginTopMd2xs = marginStyles["marginTopMd2xs"];
export const marginTopMdXs = marginStyles["marginTopMdXs"];
export const marginTopMdSm = marginStyles["marginTopMdSm"];
export const marginTopMdMd = marginStyles["marginTopMdMd"];
export const marginTopMdLg = marginStyles["marginTopMdLg"];
export const marginTopMdXl = marginStyles["marginTopMdXl"];
export const marginTopMd2xl = marginStyles["marginTopMd2xl"];
export const marginTopMd3xl = marginStyles["marginTopMd3xl"];
export const marginTopMd4xl = marginStyles["marginTopMd4xl"];
export const marginTopMd5xl = marginStyles["marginTopMd5xl"];

export const marginTopLgZero = marginStyles["marginTopLgZero"];
export const marginTopLg2xs = marginStyles["marginTopLg2xs"];
export const marginTopLgXs = marginStyles["marginTopLgXs"];
export const marginTopLgSm = marginStyles["marginTopLgSm"];
export const marginTopLgMd = marginStyles["marginTopLgMd"];
export const marginTopLgLg = marginStyles["marginTopLgLg"];
export const marginTopLgXl = marginStyles["marginTopLgXl"];
export const marginTopLg2xl = marginStyles["marginTopLg2xl"];
export const marginTopLg3xl = marginStyles["marginTopLg3xl"];
export const marginTopLg4xl = marginStyles["marginTopLg4xl"];
export const marginTopLg5xl = marginStyles["marginTopLg5xl"];

export const marginRightZero = marginStyles["marginRightZero"];
export const marginRight2xs = marginStyles["marginRight2xs"];
export const marginRightXs = marginStyles["marginRightXs"];
export const marginRightSm = marginStyles["marginRightSm"];
export const marginRightMd = marginStyles["marginRightMd"];
export const marginRightLg = marginStyles["marginRightLg"];
export const marginRightXl = marginStyles["marginRightXl"];
export const marginRight2xl = marginStyles["marginRight2xl"];
export const marginRight3xl = marginStyles["marginRight3xl"];
export const marginRight4xl = marginStyles["marginRight4xl"];
export const marginRight5xl = marginStyles["marginRight5xl"];

export const marginRightXsZero = marginStyles["marginRightXsZero"];
export const marginRightXs2xs = marginStyles["marginRightXs2xs"];
export const marginRightXsXs = marginStyles["marginRightXsXs"];
export const marginRightXsSm = marginStyles["marginRightXsSm"];
export const marginRightXsMd = marginStyles["marginRightXsMd"];
export const marginRightXsLg = marginStyles["marginRightXsLg"];
export const marginRightXsXl = marginStyles["marginRightXsXl"];
export const marginRightXs2xl = marginStyles["marginRightXs2xl"];
export const marginRightXs3xl = marginStyles["marginRightXs3xl"];
export const marginRightXs4xl = marginStyles["marginRightXs4xl"];
export const marginRightXs5xl = marginStyles["marginRightXs5xl"];

export const marginRightSmZero = marginStyles["marginRightSmZero"];
export const marginRightSm2xs = marginStyles["marginRightSm2xs"];
export const marginRightSmXs = marginStyles["marginRightSmXs"];
export const marginRightSmSm = marginStyles["marginRightSmSm"];
export const marginRightSmMd = marginStyles["marginRightSmMd"];
export const marginRightSmLg = marginStyles["marginRightSmLg"];
export const marginRightSmXl = marginStyles["marginRightSmXl"];
export const marginRightSm2xl = marginStyles["marginRightSm2xl"];
export const marginRightSm3xl = marginStyles["marginRightSm3xl"];
export const marginRightSm4xl = marginStyles["marginRightSm4xl"];
export const marginRightSm5xl = marginStyles["marginRightSm5xl"];

export const marginRightMdZero = marginStyles["marginRightMdZero"];
export const marginRightMd2xs = marginStyles["marginRightMd2xs"];
export const marginRightMdXs = marginStyles["marginRightMdXs"];
export const marginRightMdSm = marginStyles["marginRightMdSm"];
export const marginRightMdMd = marginStyles["marginRightMdMd"];
export const marginRightMdLg = marginStyles["marginRightMdLg"];
export const marginRightMdXl = marginStyles["marginRightMdXl"];
export const marginRightMd2xl = marginStyles["marginRightMd2xl"];
export const marginRightMd3xl = marginStyles["marginRightMd3xl"];
export const marginRightMd4xl = marginStyles["marginRightMd4xl"];
export const marginRightMd5xl = marginStyles["marginRightMd5xl"];

export const marginRightLgZero = marginStyles["marginRightLgZero"];
export const marginRightLg2xs = marginStyles["marginRightLg2xs"];
export const marginRightLgXs = marginStyles["marginRightLgXs"];
export const marginRightLgSm = marginStyles["marginRightLgSm"];
export const marginRightLgMd = marginStyles["marginRightLgMd"];
export const marginRightLgLg = marginStyles["marginRightLgLg"];
export const marginRightLgXl = marginStyles["marginRightLgXl"];
export const marginRightLg2xl = marginStyles["marginRightLg2xl"];
export const marginRightLg3xl = marginStyles["marginRightLg3xl"];
export const marginRightLg4xl = marginStyles["marginRightLg4xl"];
export const marginRightLg5xl = marginStyles["marginRightLg5xl"];

export const marginBottomZero = marginStyles["marginBottomZero"];
export const marginBottom2xs = marginStyles["marginBottom2xs"];
export const marginBottomXs = marginStyles["marginBottomXs"];
export const marginBottomSm = marginStyles["marginBottomSm"];
export const marginBottomMd = marginStyles["marginBottomMd"];
export const marginBottomLg = marginStyles["marginBottomLg"];
export const marginBottomXl = marginStyles["marginBottomXl"];
export const marginBottom2xl = marginStyles["marginBottom2xl"];
export const marginBottom3xl = marginStyles["marginBottom3xl"];
export const marginBottom4xl = marginStyles["marginBottom4xl"];
export const marginBottom5xl = marginStyles["marginBottom5xl"];

export const marginBottomXsZero = marginStyles["marginBottomXsZero"];
export const marginBottomXs2xs = marginStyles["marginBottomXs2xs"];
export const marginBottomXsXs = marginStyles["marginBottomXsXs"];
export const marginBottomXsSm = marginStyles["marginBottomXsSm"];
export const marginBottomXsMd = marginStyles["marginBottomXsMd"];
export const marginBottomXsLg = marginStyles["marginBottomXsLg"];
export const marginBottomXsXl = marginStyles["marginBottomXsXl"];
export const marginBottomXs2xl = marginStyles["marginBottomXs2xl"];
export const marginBottomXs3xl = marginStyles["marginBottomXs3xl"];
export const marginBottomXs4xl = marginStyles["marginBottomXs4xl"];
export const marginBottomXs5xl = marginStyles["marginBottomXs5xl"];

export const marginBottomSmZero = marginStyles["marginBottomSmZero"];
export const marginBottomSm2xs = marginStyles["marginBottomSm2xs"];
export const marginBottomSmXs = marginStyles["marginBottomSmXs"];
export const marginBottomSmSm = marginStyles["marginBottomSmSm"];
export const marginBottomSmMd = marginStyles["marginBottomSmMd"];
export const marginBottomSmLg = marginStyles["marginBottomSmLg"];
export const marginBottomSmXl = marginStyles["marginBottomSmXl"];
export const marginBottomSm2xl = marginStyles["marginBottomSm2xl"];
export const marginBottomSm3xl = marginStyles["marginBottomSm3xl"];
export const marginBottomSm4xl = marginStyles["marginBottomSm4xl"];
export const marginBottomSm5xl = marginStyles["marginBottomSm5xl"];

export const marginBottomMdZero = marginStyles["marginBottomMdZero"];
export const marginBottomMd2xs = marginStyles["marginBottomMd2xs"];
export const marginBottomMdXs = marginStyles["marginBottomMdXs"];
export const marginBottomMdSm = marginStyles["marginBottomMdSm"];
export const marginBottomMdMd = marginStyles["marginBottomMdMd"];
export const marginBottomMdLg = marginStyles["marginBottomMdLg"];
export const marginBottomMdXl = marginStyles["marginBottomMdXl"];
export const marginBottomMd2xl = marginStyles["marginBottomMd2xl"];
export const marginBottomMd3xl = marginStyles["marginBottomMd3xl"];
export const marginBottomMd4xl = marginStyles["marginBottomMd4xl"];
export const marginBottomMd5xl = marginStyles["marginBottomMd5xl"];

export const marginBottomLgZero = marginStyles["marginBottomLgZero"];
export const marginBottomLg2xs = marginStyles["marginBottomLg2xs"];
export const marginBottomLgXs = marginStyles["marginBottomLgXs"];
export const marginBottomLgSm = marginStyles["marginBottomLgSm"];
export const marginBottomLgMd = marginStyles["marginBottomLgMd"];
export const marginBottomLgLg = marginStyles["marginBottomLgLg"];
export const marginBottomLgXl = marginStyles["marginBottomLgXl"];
export const marginBottomLg2xl = marginStyles["marginBottomLg2xl"];
export const marginBottomLg3xl = marginStyles["marginBottomLg3xl"];
export const marginBottomLg4xl = marginStyles["marginBottomLg4xl"];
export const marginBottomLg5xl = marginStyles["marginBottomLg5xl"];

export const marginLeftZero = marginStyles["marginLeftZero"];
export const marginLeft2xs = marginStyles["marginLeft2xs"];
export const marginLeftXs = marginStyles["marginLeftXs"];
export const marginLeftSm = marginStyles["marginLeftSm"];
export const marginLeftMd = marginStyles["marginLeftMd"];
export const marginLeftLg = marginStyles["marginLeftLg"];
export const marginLeftXl = marginStyles["marginLeftXl"];
export const marginLeft2xl = marginStyles["marginLeft2xl"];
export const marginLeft3xl = marginStyles["marginLeft3xl"];
export const marginLeft4xl = marginStyles["marginLeft4xl"];
export const marginLeft5xl = marginStyles["marginLeft5xl"];

export const marginLeftXsZero = marginStyles["marginLeftXsZero"];
export const marginLeftXs2xs = marginStyles["marginLeftXs2xs"];
export const marginLeftXsXs = marginStyles["marginLeftXsXs"];
export const marginLeftXsSm = marginStyles["marginLeftXsSm"];
export const marginLeftXsMd = marginStyles["marginLeftXsMd"];
export const marginLeftXsLg = marginStyles["marginLeftXsLg"];
export const marginLeftXsXl = marginStyles["marginLeftXsXl"];
export const marginLeftXs2xl = marginStyles["marginLeftXs2xl"];
export const marginLeftXs3xl = marginStyles["marginLeftXs3xl"];
export const marginLeftXs4xl = marginStyles["marginLeftXs4xl"];
export const marginLeftXs5xl = marginStyles["marginLeftXs5xl"];

export const marginLeftSmZero = marginStyles["marginLeftSmZero"];
export const marginLeftSm2xs = marginStyles["marginLeftSm2xs"];
export const marginLeftSmXs = marginStyles["marginLeftSmXs"];
export const marginLeftSmSm = marginStyles["marginLeftSmSm"];
export const marginLeftSmMd = marginStyles["marginLeftSmMd"];
export const marginLeftSmLg = marginStyles["marginLeftSmLg"];
export const marginLeftSmXl = marginStyles["marginLeftSmXl"];
export const marginLeftSm2xl = marginStyles["marginLeftSm2xl"];
export const marginLeftSm3xl = marginStyles["marginLeftSm3xl"];
export const marginLeftSm4xl = marginStyles["marginLeftSm4xl"];
export const marginLeftSm5xl = marginStyles["marginLeftSm5xl"];

export const marginLeftMdZero = marginStyles["marginLeftMdZero"];
export const marginLeftMd2xs = marginStyles["marginLeftMd2xs"];
export const marginLeftMdXs = marginStyles["marginLeftMdXs"];
export const marginLeftMdSm = marginStyles["marginLeftMdSm"];
export const marginLeftMdMd = marginStyles["marginLeftMdMd"];
export const marginLeftMdLg = marginStyles["marginLeftMdLg"];
export const marginLeftMdXl = marginStyles["marginLeftMdXl"];
export const marginLeftMd2xl = marginStyles["marginLeftMd2xl"];
export const marginLeftMd3xl = marginStyles["marginLeftMd3xl"];
export const marginLeftMd4xl = marginStyles["marginLeftMd4xl"];
export const marginLeftMd5xl = marginStyles["marginLeftMd5xl"];

export const marginLeftLgZero = marginStyles["marginLeftLgZero"];
export const marginLeftLg2xs = marginStyles["marginLeftLg2xs"];
export const marginLeftLgXs = marginStyles["marginLeftLgXs"];
export const marginLeftLgSm = marginStyles["marginLeftLgSm"];
export const marginLeftLgMd = marginStyles["marginLeftLgMd"];
export const marginLeftLgLg = marginStyles["marginLeftLgLg"];
export const marginLeftLgXl = marginStyles["marginLeftLgXl"];
export const marginLeftLg2xl = marginStyles["marginLeftLg2xl"];
export const marginLeftLg3xl = marginStyles["marginLeftLg3xl"];
export const marginLeftLg4xl = marginStyles["marginLeftLg4xl"];
export const marginLeftLg5xl = marginStyles["marginLeftLg5xl"];

export const marginXZero = marginStyles["marginXZero"];
export const marginX2xs = marginStyles["marginX2xs"];
export const marginXXs = marginStyles["marginXXs"];
export const marginXSm = marginStyles["marginXSm"];
export const marginXMd = marginStyles["marginXMd"];
export const marginXLg = marginStyles["marginXLg"];
export const marginXXl = marginStyles["marginXXl"];
export const marginX2xl = marginStyles["marginX2xl"];
export const marginX3xl = marginStyles["marginX3xl"];
export const marginX4xl = marginStyles["marginX4xl"];
export const marginX5xl = marginStyles["marginX5xl"];

export const marginXXsZero = marginStyles["marginXXsZero"];
export const marginXXs2xs = marginStyles["marginXXs2xs"];
export const marginXXsXs = marginStyles["marginXXsXs"];
export const marginXXsSm = marginStyles["marginXXsSm"];
export const marginXXsMd = marginStyles["marginXXsMd"];
export const marginXXsLg = marginStyles["marginXXsLg"];
export const marginXXsXl = marginStyles["marginXXsXl"];
export const marginXXs2xl = marginStyles["marginXXs2xl"];
export const marginXXs3xl = marginStyles["marginXXs3xl"];
export const marginXXs4xl = marginStyles["marginXXs4xl"];
export const marginXXs5xl = marginStyles["marginXXs5xl"];

export const marginXSmZero = marginStyles["marginXSmZero"];
export const marginXSm2xs = marginStyles["marginXSm2xs"];
export const marginXSmXs = marginStyles["marginXSmXs"];
export const marginXSmSm = marginStyles["marginXSmSm"];
export const marginXSmMd = marginStyles["marginXSmMd"];
export const marginXSmLg = marginStyles["marginXSmLg"];
export const marginXSmXl = marginStyles["marginXSmXl"];
export const marginXSm2xl = marginStyles["marginXSm2xl"];
export const marginXSm3xl = marginStyles["marginXSm3xl"];
export const marginXSm4xl = marginStyles["marginXSm4xl"];
export const marginXSm5xl = marginStyles["marginXSm5xl"];

export const marginXMdZero = marginStyles["marginXMdZero"];
export const marginXMd2xs = marginStyles["marginXMd2xs"];
export const marginXMdXs = marginStyles["marginXMdXs"];
export const marginXMdSm = marginStyles["marginXMdSm"];
export const marginXMdMd = marginStyles["marginXMdMd"];
export const marginXMdLg = marginStyles["marginXMdLg"];
export const marginXMdXl = marginStyles["marginXMdXl"];
export const marginXMd2xl = marginStyles["marginXMd2xl"];
export const marginXMd3xl = marginStyles["marginXMd3xl"];
export const marginXMd4xl = marginStyles["marginXMd4xl"];
export const marginXMd5xl = marginStyles["marginXMd5xl"];

export const marginXLgZero = marginStyles["marginXLgZero"];
export const marginXLg2xs = marginStyles["marginXLg2xs"];
export const marginXLgXs = marginStyles["marginXLgXs"];
export const marginXLgSm = marginStyles["marginXLgSm"];
export const marginXLgMd = marginStyles["marginXLgMd"];
export const marginXLgLg = marginStyles["marginXLgLg"];
export const marginXLgXl = marginStyles["marginXLgXl"];
export const marginXLg2xl = marginStyles["marginXLg2xl"];
export const marginXLg3xl = marginStyles["marginXLg3xl"];
export const marginXLg4xl = marginStyles["marginXLg4xl"];
export const marginXLg5xl = marginStyles["marginXLg5xl"];

export const marginYZero = marginStyles["marginYZero"];
export const marginY2xs = marginStyles["marginY2xs"];
export const marginYXs = marginStyles["marginYXs"];
export const marginYSm = marginStyles["marginYSm"];
export const marginYMd = marginStyles["marginYMd"];
export const marginYLg = marginStyles["marginYLg"];
export const marginYXl = marginStyles["marginYXl"];
export const marginY2xl = marginStyles["marginY2xl"];
export const marginY3xl = marginStyles["marginY3xl"];
export const marginY4xl = marginStyles["marginY4xl"];
export const marginY5xl = marginStyles["marginY5xl"];

export const marginYXsZero = marginStyles["marginYXsZero"];
export const marginYXs2xs = marginStyles["marginYXs2xs"];
export const marginYXsXs = marginStyles["marginYXsXs"];
export const marginYXsSm = marginStyles["marginYXsSm"];
export const marginYXsMd = marginStyles["marginYXsMd"];
export const marginYXsLg = marginStyles["marginYXsLg"];
export const marginYXsXl = marginStyles["marginYXsXl"];
export const marginYXs2xl = marginStyles["marginYXs2xl"];
export const marginYXs3xl = marginStyles["marginYXs3xl"];
export const marginYXs4xl = marginStyles["marginYXs4xl"];
export const marginYXs5xl = marginStyles["marginYXs5xl"];

export const marginYSmZero = marginStyles["marginYSmZero"];
export const marginYSm2xs = marginStyles["marginYSm2xs"];
export const marginYSmXs = marginStyles["marginYSmXs"];
export const marginYSmSm = marginStyles["marginYSmSm"];
export const marginYSmMd = marginStyles["marginYSmMd"];
export const marginYSmLg = marginStyles["marginYSmLg"];
export const marginYSmXl = marginStyles["marginYSmXl"];
export const marginYSm2xl = marginStyles["marginYSm2xl"];
export const marginYSm3xl = marginStyles["marginYSm3xl"];
export const marginYSm4xl = marginStyles["marginYSm4xl"];
export const marginYSm5xl = marginStyles["marginYSm5xl"];

export const marginYMdZero = marginStyles["marginYMdZero"];
export const marginYMd2xs = marginStyles["marginYMd2xs"];
export const marginYMdXs = marginStyles["marginYMdXs"];
export const marginYMdSm = marginStyles["marginYMdSm"];
export const marginYMdMd = marginStyles["marginYMdMd"];
export const marginYMdLg = marginStyles["marginYMdLg"];
export const marginYMdXl = marginStyles["marginYMdXl"];
export const marginYMd2xl = marginStyles["marginYMd2xl"];
export const marginYMd3xl = marginStyles["marginYMd3xl"];
export const marginYMd4xl = marginStyles["marginYMd4xl"];
export const marginYMd5xl = marginStyles["marginYMd5xl"];

export const marginYLgZero = marginStyles["marginYLgZero"];
export const marginYLg2xs = marginStyles["marginYLg2xs"];
export const marginYLgXs = marginStyles["marginYLgXs"];
export const marginYLgSm = marginStyles["marginYLgSm"];
export const marginYLgMd = marginStyles["marginYLgMd"];
export const marginYLgLg = marginStyles["marginYLgLg"];
export const marginYLgXl = marginStyles["marginYLgXl"];
export const marginYLg2xl = marginStyles["marginYLg2xl"];
export const marginYLg3xl = marginStyles["marginYLg3xl"];
export const marginYLg4xl = marginStyles["marginYLg4xl"];
export const marginYLg5xl = marginStyles["marginYLg5xl"];

export const marginNegZero = marginNegStyles["marginNegZero"];
export const marginNeg2xs = marginNegStyles["marginNeg2xs"];
export const marginNegXs = marginNegStyles["marginNegXs"];
export const marginNegSm = marginNegStyles["marginNegSm"];
export const marginNegMd = marginNegStyles["marginNegMd"];
export const marginNegLg = marginNegStyles["marginNegLg"];
export const marginNegXl = marginNegStyles["marginNegXl"];
export const marginNeg2xl = marginNegStyles["marginNeg2xl"];
export const marginNeg3xl = marginNegStyles["marginNeg3xl"];
export const marginNeg4xl = marginNegStyles["marginNeg4xl"];
export const marginNeg5xl = marginNegStyles["marginNeg5xl"];

export const marginNegXsZero = marginNegStyles["marginNegXsZero"];
export const marginNegXs2xs = marginNegStyles["marginNegXs2xs"];
export const marginNegXsXs = marginNegStyles["marginNegXsXs"];
export const marginNegXsSm = marginNegStyles["marginNegXsSm"];
export const marginNegXsMd = marginNegStyles["marginNegXsMd"];
export const marginNegXsLg = marginNegStyles["marginNegXsLg"];
export const marginNegXsXl = marginNegStyles["marginNegXsXl"];
export const marginNegXs2xl = marginNegStyles["marginNegXs2xl"];
export const marginNegXs3xl = marginNegStyles["marginNegXs3xl"];
export const marginNegXs4xl = marginNegStyles["marginNegXs4xl"];
export const marginNegXs5xl = marginNegStyles["marginNegXs5xl"];

export const marginNegSmZero = marginNegStyles["marginNegSmZero"];
export const marginNegSm2xs = marginNegStyles["marginNegSm2xs"];
export const marginNegSmXs = marginNegStyles["marginNegSmXs"];
export const marginNegSmSm = marginNegStyles["marginNegSmSm"];
export const marginNegSmMd = marginNegStyles["marginNegSmMd"];
export const marginNegSmLg = marginNegStyles["marginNegSmLg"];
export const marginNegSmXl = marginNegStyles["marginNegSmXl"];
export const marginNegSm2xl = marginNegStyles["marginNegSm2xl"];
export const marginNegSm3xl = marginNegStyles["marginNegSm3xl"];
export const marginNegSm4xl = marginNegStyles["marginNegSm4xl"];
export const marginNegSm5xl = marginNegStyles["marginNegSm5xl"];

export const marginNegMdZero = marginNegStyles["marginNegMdZero"];
export const marginNegMd2xs = marginNegStyles["marginNegMd2xs"];
export const marginNegMdXs = marginNegStyles["marginNegMdXs"];
export const marginNegMdSm = marginNegStyles["marginNegMdSm"];
export const marginNegMdMd = marginNegStyles["marginNegMdMd"];
export const marginNegMdLg = marginNegStyles["marginNegMdLg"];
export const marginNegMdXl = marginNegStyles["marginNegMdXl"];
export const marginNegMd2xl = marginNegStyles["marginNegMd2xl"];
export const marginNegMd3xl = marginNegStyles["marginNegMd3xl"];
export const marginNegMd4xl = marginNegStyles["marginNegMd4xl"];
export const marginNegMd5xl = marginNegStyles["marginNegMd5xl"];

export const marginNegLgZero = marginNegStyles["marginNegLgZero"];
export const marginNegLg2xs = marginNegStyles["marginNegLg2xs"];
export const marginNegLgXs = marginNegStyles["marginNegLgXs"];
export const marginNegLgSm = marginNegStyles["marginNegLgSm"];
export const marginNegLgMd = marginNegStyles["marginNegLgMd"];
export const marginNegLgLg = marginNegStyles["marginNegLgLg"];
export const marginNegLgXl = marginNegStyles["marginNegLgXl"];
export const marginNegLg2xl = marginNegStyles["marginNegLg2xl"];
export const marginNegLg3xl = marginNegStyles["marginNegLg3xl"];
export const marginNegLg4xl = marginNegStyles["marginNegLg4xl"];
export const marginNegLg5xl = marginNegStyles["marginNegLg5xl"];

export const marginNegTopZero = marginNegStyles["marginNegTopZero"];
export const marginNegTop2xs = marginNegStyles["marginNegTop2xs"];
export const marginNegTopXs = marginNegStyles["marginNegTopXs"];
export const marginNegTopSm = marginNegStyles["marginNegTopSm"];
export const marginNegTopMd = marginNegStyles["marginNegTopMd"];
export const marginNegTopLg = marginNegStyles["marginNegTopLg"];
export const marginNegTopXl = marginNegStyles["marginNegTopXl"];
export const marginNegTop2xl = marginNegStyles["marginNegTop2xl"];
export const marginNegTop3xl = marginNegStyles["marginNegTop3xl"];
export const marginNegTop4xl = marginNegStyles["marginNegTop4xl"];
export const marginNegTop5xl = marginNegStyles["marginNegTop5xl"];

export const marginNegTopSmZero = marginNegStyles["marginNegTopSmZero"];
export const marginNegTopSm2xs = marginNegStyles["marginNegTopSm2xs"];
export const marginNegTopSmXs = marginNegStyles["marginNegTopSmXs"];
export const marginNegTopSmSm = marginNegStyles["marginNegTopSmSm"];
export const marginNegTopSmMd = marginNegStyles["marginNegTopSmMd"];
export const marginNegTopSmLg = marginNegStyles["marginNegTopSmLg"];
export const marginNegTopSmXl = marginNegStyles["marginNegTopSmXl"];
export const marginNegTopSm2xl = marginNegStyles["marginNegTopSm2xl"];
export const marginNegTopSm3xl = marginNegStyles["marginNegTopSm3xl"];
export const marginNegTopSm4xl = marginNegStyles["marginNegTopSm4xl"];
export const marginNegTopSm5xl = marginNegStyles["marginNegTopSm5xl"];

export const marginNegTopXsZero = marginNegStyles["marginNegTopXsZero"];
export const marginNegTopXs2xs = marginNegStyles["marginNegTopXs2xs"];
export const marginNegTopXsXs = marginNegStyles["marginNegTopXsXs"];
export const marginNegTopXsSm = marginNegStyles["marginNegTopXsSm"];
export const marginNegTopXsMd = marginNegStyles["marginNegTopXsMd"];
export const marginNegTopXsLg = marginNegStyles["marginNegTopXsLg"];
export const marginNegTopXsXl = marginNegStyles["marginNegTopXsXl"];
export const marginNegTopXs2xl = marginNegStyles["marginNegTopXs2xl"];
export const marginNegTopXs3xl = marginNegStyles["marginNegTopXs3xl"];
export const marginNegTopXs4xl = marginNegStyles["marginNegTopXs4xl"];
export const marginNegTopXs5xl = marginNegStyles["marginNegTopXs5xl"];

export const marginNegTopMdZero = marginNegStyles["marginNegTopMdZero"];
export const marginNegTopMd2xs = marginNegStyles["marginNegTopMd2xs"];
export const marginNegTopMdXs = marginNegStyles["marginNegTopMdXs"];
export const marginNegTopMdSm = marginNegStyles["marginNegTopMdSm"];
export const marginNegTopMdMd = marginNegStyles["marginNegTopMdMd"];
export const marginNegTopMdLg = marginNegStyles["marginNegTopMdLg"];
export const marginNegTopMdXl = marginNegStyles["marginNegTopMdXl"];
export const marginNegTopMd2xl = marginNegStyles["marginNegTopMd2xl"];
export const marginNegTopMd3xl = marginNegStyles["marginNegTopMd3xl"];
export const marginNegTopMd4xl = marginNegStyles["marginNegTopMd4xl"];
export const marginNegTopMd5xl = marginNegStyles["marginNegTopMd5xl"];

export const marginNegTopLgZero = marginNegStyles["marginNegTopLgZero"];
export const marginNegTopLg2xs = marginNegStyles["marginNegTopLg2xs"];
export const marginNegTopLgXs = marginNegStyles["marginNegTopLgXs"];
export const marginNegTopLgSm = marginNegStyles["marginNegTopLgSm"];
export const marginNegTopLgMd = marginNegStyles["marginNegTopLgMd"];
export const marginNegTopLgLg = marginNegStyles["marginNegTopLgLg"];
export const marginNegTopLgXl = marginNegStyles["marginNegTopLgXl"];
export const marginNegTopLg2xl = marginNegStyles["marginNegTopLg2xl"];
export const marginNegTopLg3xl = marginNegStyles["marginNegTopLg3xl"];
export const marginNegTopLg4xl = marginNegStyles["marginNegTopLg4xl"];
export const marginNegTopLg5xl = marginNegStyles["marginNegTopLg5xl"];

export const marginNegRightZero = marginNegStyles["marginNegRightZero"];
export const marginNegRight2xs = marginNegStyles["marginNegRight2xs"];
export const marginNegRightXs = marginNegStyles["marginNegRightXs"];
export const marginNegRightSm = marginNegStyles["marginNegRightSm"];
export const marginNegRightMd = marginNegStyles["marginNegRightMd"];
export const marginNegRightLg = marginNegStyles["marginNegRightLg"];
export const marginNegRightXl = marginNegStyles["marginNegRightXl"];
export const marginNegRight2xl = marginNegStyles["marginNegRight2xl"];
export const marginNegRight3xl = marginNegStyles["marginNegRight3xl"];
export const marginNegRight4xl = marginNegStyles["marginNegRight4xl"];
export const marginNegRight5xl = marginNegStyles["marginNegRight5xl"];

export const marginNegRightXsZero = marginNegStyles["marginNegRightXsZero"];
export const marginNegRightXs2xs = marginNegStyles["marginNegRightXs2xs"];
export const marginNegRightXsXs = marginNegStyles["marginNegRightXsXs"];
export const marginNegRightXsSm = marginNegStyles["marginNegRightXsSm"];
export const marginNegRightXsMd = marginNegStyles["marginNegRightXsMd"];
export const marginNegRightXsLg = marginNegStyles["marginNegRightXsLg"];
export const marginNegRightXsXl = marginNegStyles["marginNegRightXsXl"];
export const marginNegRightXs2xl = marginNegStyles["marginNegRightXs2xl"];
export const marginNegRightXs3xl = marginNegStyles["marginNegRightXs3xl"];
export const marginNegRightXs4xl = marginNegStyles["marginNegRightXs4xl"];
export const marginNegRightXs5xl = marginNegStyles["marginNegRightXs5xl"];

export const marginNegRightSmZero = marginNegStyles["marginNegRightSmZero"];
export const marginNegRightSm2xs = marginNegStyles["marginNegRightSm2xs"];
export const marginNegRightSmXs = marginNegStyles["marginNegRightSmXs"];
export const marginNegRightSmSm = marginNegStyles["marginNegRightSmSm"];
export const marginNegRightSmMd = marginNegStyles["marginNegRightSmMd"];
export const marginNegRightSmLg = marginNegStyles["marginNegRightSmLg"];
export const marginNegRightSmXl = marginNegStyles["marginNegRightSmXl"];
export const marginNegRightSm2xl = marginNegStyles["marginNegRightSm2xl"];
export const marginNegRightSm3xl = marginNegStyles["marginNegRightSm3xl"];
export const marginNegRightSm4xl = marginNegStyles["marginNegRightSm4xl"];
export const marginNegRightSm5xl = marginNegStyles["marginNegRightSm5xl"];

export const marginNegRightMdZero = marginNegStyles["marginNegRightMdZero"];
export const marginNegRightMd2xs = marginNegStyles["marginNegRightMd2xs"];
export const marginNegRightMdXs = marginNegStyles["marginNegRightMdXs"];
export const marginNegRightMdSm = marginNegStyles["marginNegRightMdSm"];
export const marginNegRightMdMd = marginNegStyles["marginNegRightMdMd"];
export const marginNegRightMdLg = marginNegStyles["marginNegRightMdLg"];
export const marginNegRightMdXl = marginNegStyles["marginNegRightMdXl"];
export const marginNegRightMd2xl = marginNegStyles["marginNegRightMd2xl"];
export const marginNegRightMd3xl = marginNegStyles["marginNegRightMd3xl"];
export const marginNegRightMd4xl = marginNegStyles["marginNegRightMd4xl"];
export const marginNegRightMd5xl = marginNegStyles["marginNegRightMd5xl"];

export const marginNegRightLgZero = marginNegStyles["marginNegRightLgZero"];
export const marginNegRightLg2xs = marginNegStyles["marginNegRightLg2xs"];
export const marginNegRightLgXs = marginNegStyles["marginNegRightLgXs"];
export const marginNegRightLgSm = marginNegStyles["marginNegRightLgSm"];
export const marginNegRightLgMd = marginNegStyles["marginNegRightLgMd"];
export const marginNegRightLgLg = marginNegStyles["marginNegRightLgLg"];
export const marginNegRightLgXl = marginNegStyles["marginNegRightLgXl"];
export const marginNegRightLg2xl = marginNegStyles["marginNegRightLg2xl"];
export const marginNegRightLg3xl = marginNegStyles["marginNegRightLg3xl"];
export const marginNegRightLg4xl = marginNegStyles["marginNegRightLg4xl"];
export const marginNegRightLg5xl = marginNegStyles["marginNegRightLg5xl"];

export const marginNegBottomZero = marginNegStyles["marginNegBottomZero"];
export const marginNegBottom2xs = marginNegStyles["marginNegBottom2xs"];
export const marginNegBottomXs = marginNegStyles["marginNegBottomXs"];
export const marginNegBottomSm = marginNegStyles["marginNegBottomSm"];
export const marginNegBottomMd = marginNegStyles["marginNegBottomMd"];
export const marginNegBottomLg = marginNegStyles["marginNegBottomLg"];
export const marginNegBottomXl = marginNegStyles["marginNegBottomXl"];
export const marginNegBottom2xl = marginNegStyles["marginNegBottom2xl"];
export const marginNegBottom3xl = marginNegStyles["marginNegBottom3xl"];
export const marginNegBottom4xl = marginNegStyles["marginNegBottom4xl"];
export const marginNegBottom5xl = marginNegStyles["marginNegBottom5xl"];

export const marginNegBottomSmZero = marginNegStyles["marginNegBottomSmZero"];
export const marginNegBottomSm2xs = marginNegStyles["marginNegBottomSm2xs"];
export const marginNegBottomSmXs = marginNegStyles["marginNegBottomSmXs"];
export const marginNegBottomSmSm = marginNegStyles["marginNegBottomSmSm"];
export const marginNegBottomSmMd = marginNegStyles["marginNegBottomSmMd"];
export const marginNegBottomSmLg = marginNegStyles["marginNegBottomSmLg"];
export const marginNegBottomSmXl = marginNegStyles["marginNegBottomSmXl"];
export const marginNegBottomSm2xl = marginNegStyles["marginNegBottomSm2xl"];
export const marginNegBottomSm3xl = marginNegStyles["marginNegBottomSm3xl"];
export const marginNegBottomSm4xl = marginNegStyles["marginNegBottomSm4xl"];
export const marginNegBottomSm5xl = marginNegStyles["marginNegBottomSm5xl"];

export const marginNegBottomXsZero = marginNegStyles["marginNegBottomXsZero"];
export const marginNegBottomXs2xs = marginNegStyles["marginNegBottomXs2xs"];
export const marginNegBottomXsXs = marginNegStyles["marginNegBottomXsXs"];
export const marginNegBottomXsSm = marginNegStyles["marginNegBottomXsSm"];
export const marginNegBottomXsMd = marginNegStyles["marginNegBottomXsMd"];
export const marginNegBottomXsLg = marginNegStyles["marginNegBottomXsLg"];
export const marginNegBottomXsXl = marginNegStyles["marginNegBottomXsXl"];
export const marginNegBottomXs2xl = marginNegStyles["marginNegBottomXs2xl"];
export const marginNegBottomXs3xl = marginNegStyles["marginNegBottomXs3xl"];
export const marginNegBottomXs4xl = marginNegStyles["marginNegBottomXs4xl"];
export const marginNegBottomXs5xl = marginNegStyles["marginNegBottomXs5xl"];

export const marginNegBottomMdZero = marginNegStyles["marginNegBottomMdZero"];
export const marginNegBottomMd2xs = marginNegStyles["marginNegBottomMd2xs"];
export const marginNegBottomMdXs = marginNegStyles["marginNegBottomMdXs"];
export const marginNegBottomMdSm = marginNegStyles["marginNegBottomMdSm"];
export const marginNegBottomMdMd = marginNegStyles["marginNegBottomMdMd"];
export const marginNegBottomMdLg = marginNegStyles["marginNegBottomMdLg"];
export const marginNegBottomMdXl = marginNegStyles["marginNegBottomMdXl"];
export const marginNegBottomMd2xl = marginNegStyles["marginNegBottomMd2xl"];
export const marginNegBottomMd3xl = marginNegStyles["marginNegBottomMd3xl"];
export const marginNegBottomMd4xl = marginNegStyles["marginNegBottomMd4xl"];
export const marginNegBottomMd5xl = marginNegStyles["marginNegBottomMd5xl"];

export const marginNegBottomLgZero = marginNegStyles["marginNegBottomLgZero"];
export const marginNegBottomLg2xs = marginNegStyles["marginNegBottomLg2xs"];
export const marginNegBottomLgXs = marginNegStyles["marginNegBottomLgXs"];
export const marginNegBottomLgSm = marginNegStyles["marginNegBottomLgSm"];
export const marginNegBottomLgMd = marginNegStyles["marginNegBottomLgMd"];
export const marginNegBottomLgLg = marginNegStyles["marginNegBottomLgLg"];
export const marginNegBottomLgXl = marginNegStyles["marginNegBottomLgXl"];
export const marginNegBottomLg2xl = marginNegStyles["marginNegBottomLg2xl"];
export const marginNegBottomLg3xl = marginNegStyles["marginNegBottomLg3xl"];
export const marginNegBottomLg4xl = marginNegStyles["marginNegBottomLg4xl"];
export const marginNegBottomLg5xl = marginNegStyles["marginNegBottomLg5xl"];

export const marginNegLeftZero = marginNegStyles["marginNegLeftZero"];
export const marginNegLeft2xs = marginNegStyles["marginNegLeft2xs"];
export const marginNegLeftXs = marginNegStyles["marginNegLeftXs"];
export const marginNegLeftSm = marginNegStyles["marginNegLeftSm"];
export const marginNegLeftMd = marginNegStyles["marginNegLeftMd"];
export const marginNegLeftLg = marginNegStyles["marginNegLeftLg"];
export const marginNegLeftXl = marginNegStyles["marginNegLeftXl"];
export const marginNegLeft2xl = marginNegStyles["marginNegLeft2xl"];
export const marginNegLeft3xl = marginNegStyles["marginNegLeft3xl"];
export const marginNegLeft4xl = marginNegStyles["marginNegLeft4xl"];
export const marginNegLeft5xl = marginNegStyles["marginNegLeft5xl"];

export const marginNegLeftSmZero = marginNegStyles["marginNegLeftSmZero"];
export const marginNegLeftSm2xs = marginNegStyles["marginNegLeftSm2xs"];
export const marginNegLeftSmXs = marginNegStyles["marginNegLeftSmXs"];
export const marginNegLeftSmSm = marginNegStyles["marginNegLeftSmSm"];
export const marginNegLeftSmMd = marginNegStyles["marginNegLeftSmMd"];
export const marginNegLeftSmLg = marginNegStyles["marginNegLeftSmLg"];
export const marginNegLeftSmXl = marginNegStyles["marginNegLeftSmXl"];
export const marginNegLeftSm2xl = marginNegStyles["marginNegLeftSm2xl"];
export const marginNegLeftSm3xl = marginNegStyles["marginNegLeftSm3xl"];
export const marginNegLeftSm4xl = marginNegStyles["marginNegLeftSm4xl"];
export const marginNegLeftSm5xl = marginNegStyles["marginNegLeftSm5xl"];

export const marginNegLeftXsZero = marginNegStyles["marginNegLeftXsZero"];
export const marginNegLeftXs2xs = marginNegStyles["marginNegLeftXs2xs"];
export const marginNegLeftXsXs = marginNegStyles["marginNegLeftXsXs"];
export const marginNegLeftXsSm = marginNegStyles["marginNegLeftXsSm"];
export const marginNegLeftXsMd = marginNegStyles["marginNegLeftXsMd"];
export const marginNegLeftXsLg = marginNegStyles["marginNegLeftXsLg"];
export const marginNegLeftXsXl = marginNegStyles["marginNegLeftXsXl"];
export const marginNegLeftXs2xl = marginNegStyles["marginNegLeftXs2xl"];
export const marginNegLeftXs3xl = marginNegStyles["marginNegLeftXs3xl"];
export const marginNegLeftXs4xl = marginNegStyles["marginNegLeftXs4xl"];
export const marginNegLeftXs5xl = marginNegStyles["marginNegLeftXs5xl"];

export const marginNegLeftMdZero = marginNegStyles["marginNegLeftMdZero"];
export const marginNegLeftMd2xs = marginNegStyles["marginNegLeftMd2xs"];
export const marginNegLeftMdXs = marginNegStyles["marginNegLeftMdXs"];
export const marginNegLeftMdSm = marginNegStyles["marginNegLeftMdSm"];
export const marginNegLeftMdMd = marginNegStyles["marginNegLeftMdMd"];
export const marginNegLeftMdLg = marginNegStyles["marginNegLeftMdLg"];
export const marginNegLeftMdXl = marginNegStyles["marginNegLeftMdXl"];
export const marginNegLeftMd2xl = marginNegStyles["marginNegLeftMd2xl"];
export const marginNegLeftMd3xl = marginNegStyles["marginNegLeftMd3xl"];
export const marginNegLeftMd4xl = marginNegStyles["marginNegLeftMd4xl"];
export const marginNegLeftMd5xl = marginNegStyles["marginNegLeftMd5xl"];

export const marginNegLeftLgZero = marginNegStyles["marginNegLeftLgZero"];
export const marginNegLeftLg2xs = marginNegStyles["marginNegLeftLg2xs"];
export const marginNegLeftLgXs = marginNegStyles["marginNegLeftLgXs"];
export const marginNegLeftLgSm = marginNegStyles["marginNegLeftLgSm"];
export const marginNegLeftLgMd = marginNegStyles["marginNegLeftLgMd"];
export const marginNegLeftLgLg = marginNegStyles["marginNegLeftLgLg"];
export const marginNegLeftLgXl = marginNegStyles["marginNegLeftLgXl"];
export const marginNegLeftLg2xl = marginNegStyles["marginNegLeftLg2xl"];
export const marginNegLeftLg3xl = marginNegStyles["marginNegLeftLg3xl"];
export const marginNegLeftLg4xl = marginNegStyles["marginNegLeftLg4xl"];
export const marginNegLeftLg5xl = marginNegStyles["marginNegLeftLg5xl"];

export const marginNegXZero = marginNegStyles["marginNegXZero"];
export const marginNegX2xs = marginNegStyles["marginNegX2xs"];
export const marginNegXXs = marginNegStyles["marginNegXXs"];
export const marginNegXSm = marginNegStyles["marginNegXSm"];
export const marginNegXMd = marginNegStyles["marginNegXMd"];
export const marginNegXLg = marginNegStyles["marginNegXLg"];
export const marginNegXXl = marginNegStyles["marginNegXXl"];
export const marginNegX2xl = marginNegStyles["marginNegX2xl"];
export const marginNegX3xl = marginNegStyles["marginNegX3xl"];
export const marginNegX4xl = marginNegStyles["marginNegX4xl"];
export const marginNegX5xl = marginNegStyles["marginNegX5xl"];

export const marginNegXXsZero = marginNegStyles["marginNegXXsZero"];
export const marginNegXXs2xs = marginNegStyles["marginNegXXs2xs"];
export const marginNegXXsXs = marginNegStyles["marginNegXXsXs"];
export const marginNegXXsSm = marginNegStyles["marginNegXXsSm"];
export const marginNegXXsMd = marginNegStyles["marginNegXXsMd"];
export const marginNegXXsLg = marginNegStyles["marginNegXXsLg"];
export const marginNegXXsXl = marginNegStyles["marginNegXXsXl"];
export const marginNegXXs2xl = marginNegStyles["marginNegXXs2xl"];
export const marginNegXXs3xl = marginNegStyles["marginNegXXs3xl"];
export const marginNegXXs4xl = marginNegStyles["marginNegXXs4xl"];
export const marginNegXXs5xl = marginNegStyles["marginNegXXs5xl"];

export const marginNegXSmZero = marginNegStyles["marginNegXSmZero"];
export const marginNegXSm2xs = marginNegStyles["marginNegXSm2xs"];
export const marginNegXSmXs = marginNegStyles["marginNegXSmXs"];
export const marginNegXSmSm = marginNegStyles["marginNegXSmSm"];
export const marginNegXSmMd = marginNegStyles["marginNegXSmMd"];
export const marginNegXSmLg = marginNegStyles["marginNegXSmLg"];
export const marginNegXSmXl = marginNegStyles["marginNegXSmXl"];
export const marginNegXSm2xl = marginNegStyles["marginNegXSm2xl"];
export const marginNegXSm3xl = marginNegStyles["marginNegXSm3xl"];
export const marginNegXSm4xl = marginNegStyles["marginNegXSm4xl"];
export const marginNegXSm5xl = marginNegStyles["marginNegXSm5xl"];

export const marginNegXMdZero = marginNegStyles["marginNegXMdZero"];
export const marginNegXMd2xs = marginNegStyles["marginNegXMd2xs"];
export const marginNegXMdXs = marginNegStyles["marginNegXMdXs"];
export const marginNegXMdSm = marginNegStyles["marginNegXMdSm"];
export const marginNegXMdMd = marginNegStyles["marginNegXMdMd"];
export const marginNegXMdLg = marginNegStyles["marginNegXMdLg"];
export const marginNegXMdXl = marginNegStyles["marginNegXMdXl"];
export const marginNegXMd2xl = marginNegStyles["marginNegXMd2xl"];
export const marginNegXMd3xl = marginNegStyles["marginNegXMd3xl"];
export const marginNegXMd4xl = marginNegStyles["marginNegXMd4xl"];
export const marginNegXMd5xl = marginNegStyles["marginNegXMd5xl"];

export const marginNegXLgZero = marginNegStyles["marginNegXLgZero"];
export const marginNegXLg2xs = marginNegStyles["marginNegXLg2xs"];
export const marginNegXLgXs = marginNegStyles["marginNegXLgXs"];
export const marginNegXLgSm = marginNegStyles["marginNegXLgSm"];
export const marginNegXLgMd = marginNegStyles["marginNegXLgMd"];
export const marginNegXLgLg = marginNegStyles["marginNegXLgLg"];
export const marginNegXLgXl = marginNegStyles["marginNegXLgXl"];
export const marginNegXLg2xl = marginNegStyles["marginNegXLg2xl"];
export const marginNegXLg3xl = marginNegStyles["marginNegXLg3xl"];
export const marginNegXLg4xl = marginNegStyles["marginNegXLg4xl"];
export const marginNegXLg5xl = marginNegStyles["marginNegXLg5xl"];

export const marginNegYZero = marginNegStyles["marginNegYZero"];
export const marginNegY2xs = marginNegStyles["marginNegY2xs"];
export const marginNegYXs = marginNegStyles["marginNegYXs"];
export const marginNegYSm = marginNegStyles["marginNegYSm"];
export const marginNegYMd = marginNegStyles["marginNegYMd"];
export const marginNegYLg = marginNegStyles["marginNegYLg"];
export const marginNegYXl = marginNegStyles["marginNegYXl"];
export const marginNegY2xl = marginNegStyles["marginNegY2xl"];
export const marginNegY3xl = marginNegStyles["marginNegY3xl"];
export const marginNegY4xl = marginNegStyles["marginNegY4xl"];
export const marginNegY5xl = marginNegStyles["marginNegY5xl"];

export const marginNegYXsZero = marginNegStyles["marginNegYXsZero"];
export const marginNegYXs2xs = marginNegStyles["marginNegYXs2xs"];
export const marginNegYXsXs = marginNegStyles["marginNegYXsXs"];
export const marginNegYXsSm = marginNegStyles["marginNegYXsSm"];
export const marginNegYXsMd = marginNegStyles["marginNegYXsMd"];
export const marginNegYXsLg = marginNegStyles["marginNegYXsLg"];
export const marginNegYXsXl = marginNegStyles["marginNegYXsXl"];
export const marginNegYXs2xl = marginNegStyles["marginNegYXs2xl"];
export const marginNegYXs3xl = marginNegStyles["marginNegYXs3xl"];
export const marginNegYXs4xl = marginNegStyles["marginNegYXs4xl"];
export const marginNegYXs5xl = marginNegStyles["marginNegYXs5xl"];

export const marginNegYSmZero = marginNegStyles["marginNegYSmZero"];
export const marginNegYSm2xs = marginNegStyles["marginNegYSm2xs"];
export const marginNegYSmXs = marginNegStyles["marginNegYSmXs"];
export const marginNegYSmSm = marginNegStyles["marginNegYSmSm"];
export const marginNegYSmMd = marginNegStyles["marginNegYSmMd"];
export const marginNegYSmLg = marginNegStyles["marginNegYSmLg"];
export const marginNegYSmXl = marginNegStyles["marginNegYSmXl"];
export const marginNegYSm2xl = marginNegStyles["marginNegYSm2xl"];
export const marginNegYSm3xl = marginNegStyles["marginNegYSm3xl"];
export const marginNegYSm4xl = marginNegStyles["marginNegYSm4xl"];
export const marginNegYSm5xl = marginNegStyles["marginNegYSm5xl"];

export const marginNegYMdZero = marginNegStyles["marginNegYMdZero"];
export const marginNegYMd2xs = marginNegStyles["marginNegYMd2xs"];
export const marginNegYMdXs = marginNegStyles["marginNegYMdXs"];
export const marginNegYMdSm = marginNegStyles["marginNegYMdSm"];
export const marginNegYMdMd = marginNegStyles["marginNegYMdMd"];
export const marginNegYMdLg = marginNegStyles["marginNegYMdLg"];
export const marginNegYMdXl = marginNegStyles["marginNegYMdXl"];
export const marginNegYMd2xl = marginNegStyles["marginNegYMd2xl"];
export const marginNegYMd3xl = marginNegStyles["marginNegYMd3xl"];
export const marginNegYMd4xl = marginNegStyles["marginNegYMd4xl"];
export const marginNegYMd5xl = marginNegStyles["marginNegYMd5xl"];

export const marginNegYLgZero = marginNegStyles["marginNegYLgZero"];
export const marginNegYLg2xs = marginNegStyles["marginNegYLg2xs"];
export const marginNegYLgXs = marginNegStyles["marginNegYLgXs"];
export const marginNegYLgSm = marginNegStyles["marginNegYLgSm"];
export const marginNegYLgMd = marginNegStyles["marginNegYLgMd"];
export const marginNegYLgLg = marginNegStyles["marginNegYLgLg"];
export const marginNegYLgXl = marginNegStyles["marginNegYLgXl"];
export const marginNegYLg2xl = marginNegStyles["marginNegYLg2xl"];
export const marginNegYLg3xl = marginNegStyles["marginNegYLg3xl"];
export const marginNegYLg4xl = marginNegStyles["marginNegYLg4xl"];
export const marginNegYLg5xl = marginNegStyles["marginNegYLg5xl"];

export const paddingZero = paddingStyles["paddingZero"];
export const padding2xs = paddingStyles["padding2xs"];
export const paddingXs = paddingStyles["paddingXs"];
export const paddingSm = paddingStyles["paddingSm"];
export const paddingMd = paddingStyles["paddingMd"];
export const paddingLg = paddingStyles["paddingLg"];
export const paddingXl = paddingStyles["paddingXl"];
export const padding2xl = paddingStyles["padding2xl"];
export const padding3xl = paddingStyles["padding3xl"];
export const padding4xl = paddingStyles["padding4xl"];
export const padding5xl = paddingStyles["padding5xl"];

export const paddingXsZero = paddingStyles["paddingXsZero"];
export const paddingXs2xs = paddingStyles["paddingXs2xs"];
export const paddingXsXs = paddingStyles["paddingXsXs"];
export const paddingXsSm = paddingStyles["paddingXsSm"];
export const paddingXsMd = paddingStyles["paddingXsMd"];
export const paddingXsLg = paddingStyles["paddingXsLg"];
export const paddingXsXl = paddingStyles["paddingXsXl"];
export const paddingXs2xl = paddingStyles["paddingXs2xl"];
export const paddingXs3xl = paddingStyles["paddingXs3xl"];
export const paddingXs4xl = paddingStyles["paddingXs4xl"];
export const paddingXs5xl = paddingStyles["paddingXs5xl"];

export const paddingSmZero = paddingStyles["paddingSmZero"];
export const paddingSm2xs = paddingStyles["paddingSm2xs"];
export const paddingSmXs = paddingStyles["paddingSmXs"];
export const paddingSmSm = paddingStyles["paddingSmSm"];
export const paddingSmMd = paddingStyles["paddingSmMd"];
export const paddingSmLg = paddingStyles["paddingSmLg"];
export const paddingSmXl = paddingStyles["paddingSmXl"];
export const paddingSm2xl = paddingStyles["paddingSm2xl"];
export const paddingSm3xl = paddingStyles["paddingSm3xl"];
export const paddingSm4xl = paddingStyles["paddingSm4xl"];
export const paddingSm5xl = paddingStyles["paddingSm5xl"];

export const paddingMdZero = paddingStyles["paddingMdZero"];
export const paddingMd2xs = paddingStyles["paddingMd2xs"];
export const paddingMdXs = paddingStyles["paddingMdXs"];
export const paddingMdSm = paddingStyles["paddingMdSm"];
export const paddingMdMd = paddingStyles["paddingMdMd"];
export const paddingMdLg = paddingStyles["paddingMdLg"];
export const paddingMdXl = paddingStyles["paddingMdXl"];
export const paddingMd2xl = paddingStyles["paddingMd2xl"];
export const paddingMd3xl = paddingStyles["paddingMd3xl"];
export const paddingMd4xl = paddingStyles["paddingMd4xl"];
export const paddingMd5xl = paddingStyles["paddingMd5xl"];

export const paddingLgZero = paddingStyles["paddingLgZero"];
export const paddingLg2xs = paddingStyles["paddingLg2xs"];
export const paddingLgXs = paddingStyles["paddingLgXs"];
export const paddingLgSm = paddingStyles["paddingLgSm"];
export const paddingLgMd = paddingStyles["paddingLgMd"];
export const paddingLgLg = paddingStyles["paddingLgLg"];
export const paddingLgXl = paddingStyles["paddingLgXl"];
export const paddingLg2xl = paddingStyles["paddingLg2xl"];
export const paddingLg3xl = paddingStyles["paddingLg3xl"];
export const paddingLg4xl = paddingStyles["paddingLg4xl"];
export const paddingLg5xl = paddingStyles["paddingLg5xl"];

export const paddingTopZero = paddingStyles["paddingTopZero"];
export const paddingTop3xs = paddingStyles["paddingTop3xs"];
export const paddingTop2xs = paddingStyles["paddingTop2xs"];
export const paddingTopXs = paddingStyles["paddingTopXs"];
export const paddingTopSm = paddingStyles["paddingTopSm"];
export const paddingTopMd = paddingStyles["paddingTopMd"];
export const paddingTopLg = paddingStyles["paddingTopLg"];
export const paddingTopXl = paddingStyles["paddingTopXl"];
export const paddingTop2xl = paddingStyles["paddingTop2xl"];
export const paddingTop3xl = paddingStyles["paddingTop3xl"];
export const paddingTop4xl = paddingStyles["paddingTop4xl"];
export const paddingTop5xl = paddingStyles["paddingTop5xl"];

export const paddingTopXsZero = paddingStyles["paddingTopXsZero"];
export const paddingTopXs2xs = paddingStyles["paddingTopXs2xs"];
export const paddingTopXsXs = paddingStyles["paddingTopXsXs"];
export const paddingTopXsSm = paddingStyles["paddingTopXsSm"];
export const paddingTopXsMd = paddingStyles["paddingTopXsMd"];
export const paddingTopXsLg = paddingStyles["paddingTopXsLg"];
export const paddingTopXsXl = paddingStyles["paddingTopXsXl"];
export const paddingTopXs2xl = paddingStyles["paddingTopXs2xl"];
export const paddingTopXs3xl = paddingStyles["paddingTopXs3xl"];
export const paddingTopXs4xl = paddingStyles["paddingTopXs4xl"];
export const paddingTopXs5xl = paddingStyles["paddingTopXs5xl"];

export const paddingTopSmZero = paddingStyles["paddingTopSmZero"];
export const paddingTopSm2xs = paddingStyles["paddingTopSm2xs"];
export const paddingTopSmXs = paddingStyles["paddingTopSmXs"];
export const paddingTopSmSm = paddingStyles["paddingTopSmSm"];
export const paddingTopSmMd = paddingStyles["paddingTopSmMd"];
export const paddingTopSmLg = paddingStyles["paddingTopSmLg"];
export const paddingTopSmXl = paddingStyles["paddingTopSmXl"];
export const paddingTopSm2xl = paddingStyles["paddingTopSm2xl"];
export const paddingTopSm3xl = paddingStyles["paddingTopSm3xl"];
export const paddingTopSm4xl = paddingStyles["paddingTopSm4xl"];
export const paddingTopSm5xl = paddingStyles["paddingTopSm5xl"];

export const paddingTopMdZero = paddingStyles["paddingTopMdZero"];
export const paddingTopMd2xs = paddingStyles["paddingTopMd2xs"];
export const paddingTopMdXs = paddingStyles["paddingTopMdXs"];
export const paddingTopMdSm = paddingStyles["paddingTopMdSm"];
export const paddingTopMdMd = paddingStyles["paddingTopMdMd"];
export const paddingTopMdLg = paddingStyles["paddingTopMdLg"];
export const paddingTopMdXl = paddingStyles["paddingTopMdXl"];
export const paddingTopMd2xl = paddingStyles["paddingTopMd2xl"];
export const paddingTopMd3xl = paddingStyles["paddingTopMd3xl"];
export const paddingTopMd4xl = paddingStyles["paddingTopMd4xl"];
export const paddingTopMd5xl = paddingStyles["paddingTopMd5xl"];

export const paddingTopLgZero = paddingStyles["paddingTopLgZero"];
export const paddingTopLg2xs = paddingStyles["paddingTopLg2xs"];
export const paddingTopLgXs = paddingStyles["paddingTopLgXs"];
export const paddingTopLgSm = paddingStyles["paddingTopLgSm"];
export const paddingTopLgMd = paddingStyles["paddingTopLgMd"];
export const paddingTopLgLg = paddingStyles["paddingTopLgLg"];
export const paddingTopLgXl = paddingStyles["paddingTopLgXl"];
export const paddingTopLg2xl = paddingStyles["paddingTopLg2xl"];
export const paddingTopLg3xl = paddingStyles["paddingTopLg3xl"];
export const paddingTopLg4xl = paddingStyles["paddingTopLg4xl"];
export const paddingTopLg5xl = paddingStyles["paddingTopLg5xl"];

export const paddingTopXlZero = paddingStyles["paddingTopXlZero"];
export const paddingTopXl2xs = paddingStyles["paddingTopXl2xs"];
export const paddingTopXlXs = paddingStyles["paddingTopXlXs"];
export const paddingTopXlSm = paddingStyles["paddingTopXlSm"];
export const paddingTopXlMd = paddingStyles["paddingTopXlMd"];
export const paddingTopXlLg = paddingStyles["paddingTopXlLg"];
export const paddingTopXlXl = paddingStyles["paddingTopXlXl"];
export const paddingTopXl2xl = paddingStyles["paddingTopXl2xl"];
export const paddingTopXl3xl = paddingStyles["paddingTopXl3xl"];
export const paddingTopXl4xl = paddingStyles["paddingTopXl4xl"];
export const paddingTopXl5xl = paddingStyles["paddingTopXl5xl"];

export const paddingRightZero = paddingStyles["paddingRightZero"];
export const paddingRight2xs = paddingStyles["paddingRight2xs"];
export const paddingRightXs = paddingStyles["paddingRightXs"];
export const paddingRightSm = paddingStyles["paddingRightSm"];
export const paddingRightMd = paddingStyles["paddingRightMd"];
export const paddingRightLg = paddingStyles["paddingRightLg"];
export const paddingRightXl = paddingStyles["paddingRightXl"];
export const paddingRight2xl = paddingStyles["paddingRight2xl"];
export const paddingRight3xl = paddingStyles["paddingRight3xl"];
export const paddingRight4xl = paddingStyles["paddingRight4xl"];
export const paddingRight5xl = paddingStyles["paddingRight5xl"];

export const paddingRightXsZero = paddingStyles["paddingRightXsZero"];
export const paddingRightXs2xs = paddingStyles["paddingRightXs2xs"];
export const paddingRightXsXs = paddingStyles["paddingRightXsXs"];
export const paddingRightXsSm = paddingStyles["paddingRightXsSm"];
export const paddingRightXsMd = paddingStyles["paddingRightXsMd"];
export const paddingRightXsLg = paddingStyles["paddingRightXsLg"];
export const paddingRightXsXl = paddingStyles["paddingRightXsXl"];
export const paddingRightXs2xl = paddingStyles["paddingRightXs2xl"];
export const paddingRightXs3xl = paddingStyles["paddingRightXs3xl"];
export const paddingRightXs4xl = paddingStyles["paddingRightXs4xl"];
export const paddingRightXs5xl = paddingStyles["paddingRightXs5xl"];

export const paddingRightSmZero = paddingStyles["paddingRightSmZero"];
export const paddingRightSm2xs = paddingStyles["paddingRightSm2xs"];
export const paddingRightSmXs = paddingStyles["paddingRightSmXs"];
export const paddingRightSmSm = paddingStyles["paddingRightSmSm"];
export const paddingRightSmMd = paddingStyles["paddingRightSmMd"];
export const paddingRightSmLg = paddingStyles["paddingRightSmLg"];
export const paddingRightSmXl = paddingStyles["paddingRightSmXl"];
export const paddingRightSm2xl = paddingStyles["paddingRightSm2xl"];
export const paddingRightSm3xl = paddingStyles["paddingRightSm3xl"];
export const paddingRightSm4xl = paddingStyles["paddingRightSm4xl"];
export const paddingRightSm5xl = paddingStyles["paddingRightSm5xl"];

export const paddingRightMdZero = paddingStyles["paddingRightMdZero"];
export const paddingRightMd2xs = paddingStyles["paddingRightMd2xs"];
export const paddingRightMdXs = paddingStyles["paddingRightMdXs"];
export const paddingRightMdSm = paddingStyles["paddingRightMdSm"];
export const paddingRightMdMd = paddingStyles["paddingRightMdMd"];
export const paddingRightMdLg = paddingStyles["paddingRightMdLg"];
export const paddingRightMdXl = paddingStyles["paddingRightMdXl"];
export const paddingRightMd2xl = paddingStyles["paddingRightMd2xl"];
export const paddingRightMd3xl = paddingStyles["paddingRightMd3xl"];
export const paddingRightMd4xl = paddingStyles["paddingRightMd4xl"];
export const paddingRightMd5xl = paddingStyles["paddingRightMd5xl"];

export const paddingRightLgZero = paddingStyles["paddingRightLgZero"];
export const paddingRightLg2xs = paddingStyles["paddingRightLg2xs"];
export const paddingRightLgXs = paddingStyles["paddingRightLgXs"];
export const paddingRightLgSm = paddingStyles["paddingRightLgSm"];
export const paddingRightLgMd = paddingStyles["paddingRightLgMd"];
export const paddingRightLgLg = paddingStyles["paddingRightLgLg"];
export const paddingRightLgXl = paddingStyles["paddingRightLgXl"];
export const paddingRightLg2xl = paddingStyles["paddingRightLg2xl"];
export const paddingRightLg3xl = paddingStyles["paddingRightLg3xl"];
export const paddingRightLg4xl = paddingStyles["paddingRightLg4xl"];
export const paddingRightLg5xl = paddingStyles["paddingRightLg5xl"];

export const paddingRightXlZero = paddingStyles["paddingRightXlZero"];
export const paddingRightXl2xs = paddingStyles["paddingRightXl2xs"];
export const paddingRightXlXs = paddingStyles["paddingRightXlXs"];
export const paddingRightXlSm = paddingStyles["paddingRightXlSm"];
export const paddingRightXlMd = paddingStyles["paddingRightXlMd"];
export const paddingRightXlLg = paddingStyles["paddingRightXlLg"];
export const paddingRightXlXl = paddingStyles["paddingRightXlXl"];
export const paddingRightXl2xl = paddingStyles["paddingRightXl2xl"];
export const paddingRightXl3xl = paddingStyles["paddingRightXl3xl"];
export const paddingRightXl4xl = paddingStyles["paddingRightXl4xl"];
export const paddingRightXl5xl = paddingStyles["paddingRightXl5xl"];

export const paddingBottomZero = paddingStyles["paddingBottomZero"];
export const paddingBottom2xs = paddingStyles["paddingBottom2xs"];
export const paddingBottomXs = paddingStyles["paddingBottomXs"];
export const paddingBottomSm = paddingStyles["paddingBottomSm"];
export const paddingBottomMd = paddingStyles["paddingBottomMd"];
export const paddingBottomLg = paddingStyles["paddingBottomLg"];
export const paddingBottomXl = paddingStyles["paddingBottomXl"];
export const paddingBottom2xl = paddingStyles["paddingBottom2xl"];
export const paddingBottom3xl = paddingStyles["paddingBottom3xl"];
export const paddingBottom4xl = paddingStyles["paddingBottom4xl"];
export const paddingBottom5xl = paddingStyles["paddingBottom5xl"];

export const paddingBottomXsZero = paddingStyles["paddingBottomXsZero"];
export const paddingBottomXs2xs = paddingStyles["paddingBottomXs2xs"];
export const paddingBottomXsXs = paddingStyles["paddingBottomXsXs"];
export const paddingBottomXsSm = paddingStyles["paddingBottomXsSm"];
export const paddingBottomXsMd = paddingStyles["paddingBottomXsMd"];
export const paddingBottomXsLg = paddingStyles["paddingBottomXsLg"];
export const paddingBottomXsXl = paddingStyles["paddingBottomXsXl"];
export const paddingBottomXs2xl = paddingStyles["paddingBottomXs2xl"];
export const paddingBottomXs3xl = paddingStyles["paddingBottomXs3xl"];
export const paddingBottomXs4xl = paddingStyles["paddingBottomXs4xl"];
export const paddingBottomXs5xl = paddingStyles["paddingBottomXs5xl"];

export const paddingBottomSmZero = paddingStyles["paddingBottomSmZero"];
export const paddingBottomSm2xs = paddingStyles["paddingBottomSm2xs"];
export const paddingBottomSmXs = paddingStyles["paddingBottomSmXs"];
export const paddingBottomSmSm = paddingStyles["paddingBottomSmSm"];
export const paddingBottomSmMd = paddingStyles["paddingBottomSmMd"];
export const paddingBottomSmLg = paddingStyles["paddingBottomSmLg"];
export const paddingBottomSmXl = paddingStyles["paddingBottomSmXl"];
export const paddingBottomSm2xl = paddingStyles["paddingBottomSm2xl"];
export const paddingBottomSm3xl = paddingStyles["paddingBottomSm3xl"];
export const paddingBottomSm4xl = paddingStyles["paddingBottomSm4xl"];
export const paddingBottomSm5xl = paddingStyles["paddingBottomSm5xl"];

export const paddingBottomMdZero = paddingStyles["paddingBottomMdZero"];
export const paddingBottomMd2xs = paddingStyles["paddingBottomMd2xs"];
export const paddingBottomMdXs = paddingStyles["paddingBottomMdXs"];
export const paddingBottomMdSm = paddingStyles["paddingBottomMdSm"];
export const paddingBottomMdMd = paddingStyles["paddingBottomMdMd"];
export const paddingBottomMdLg = paddingStyles["paddingBottomMdLg"];
export const paddingBottomMdXl = paddingStyles["paddingBottomMdXl"];
export const paddingBottomMd2xl = paddingStyles["paddingBottomMd2xl"];
export const paddingBottomMd3xl = paddingStyles["paddingBottomMd3xl"];
export const paddingBottomMd4xl = paddingStyles["paddingBottomMd4xl"];
export const paddingBottomMd5xl = paddingStyles["paddingBottomMd5xl"];

export const paddingBottomLgZero = paddingStyles["paddingBottomLgZero"];
export const paddingBottomLg2xs = paddingStyles["paddingBottomLg2xs"];
export const paddingBottomLgXs = paddingStyles["paddingBottomLgXs"];
export const paddingBottomLgSm = paddingStyles["paddingBottomLgSm"];
export const paddingBottomLgMd = paddingStyles["paddingBottomLgMd"];
export const paddingBottomLgLg = paddingStyles["paddingBottomLgLg"];
export const paddingBottomLgXl = paddingStyles["paddingBottomLgXl"];
export const paddingBottomLg2xl = paddingStyles["paddingBottomLg2xl"];
export const paddingBottomLg3xl = paddingStyles["paddingBottomLg3xl"];
export const paddingBottomLg4xl = paddingStyles["paddingBottomLg4xl"];
export const paddingBottomLg5xl = paddingStyles["paddingBottomLg5xl"];

export const paddingBottomXlZero = paddingStyles["paddingBottomXlZero"];
export const paddingBottomXl2xs = paddingStyles["paddingBottomXl2xs"];
export const paddingBottomXlXs = paddingStyles["paddingBottomXlXs"];
export const paddingBottomXlSm = paddingStyles["paddingBottomXlSm"];
export const paddingBottomXlMd = paddingStyles["paddingBottomXlMd"];
export const paddingBottomXlLg = paddingStyles["paddingBottomXlLg"];
export const paddingBottomXlXl = paddingStyles["paddingBottomXlXl"];
export const paddingBottomXl2xl = paddingStyles["paddingBottomXl2xl"];
export const paddingBottomXl3xl = paddingStyles["paddingBottomXl3xl"];
export const paddingBottomXl4xl = paddingStyles["paddingBottomXl4xl"];
export const paddingBottomXl5xl = paddingStyles["paddingBottomXl5xl"];

export const paddingLeftZero = paddingStyles["paddingLeftZero"];
export const paddingLeft2xs = paddingStyles["paddingLeft2xs"];
export const paddingLeftXs = paddingStyles["paddingLeftXs"];
export const paddingLeftSm = paddingStyles["paddingLeftSm"];
export const paddingLeftMd = paddingStyles["paddingLeftMd"];
export const paddingLeftLg = paddingStyles["paddingLeftLg"];
export const paddingLeftXl = paddingStyles["paddingLeftXl"];
export const paddingLeft2xl = paddingStyles["paddingLeft2xl"];
export const paddingLeft3xl = paddingStyles["paddingLeft3xl"];
export const paddingLeft4xl = paddingStyles["paddingLeft4xl"];
export const paddingLeft5xl = paddingStyles["paddingLeft5xl"];

export const paddingLeftXsZero = paddingStyles["paddingLeftXsZero"];
export const paddingLeftXs2xs = paddingStyles["paddingLeftXs2xs"];
export const paddingLeftXsXs = paddingStyles["paddingLeftXsXs"];
export const paddingLeftXsSm = paddingStyles["paddingLeftXsSm"];
export const paddingLeftXsMd = paddingStyles["paddingLeftXsMd"];
export const paddingLeftXsLg = paddingStyles["paddingLeftXsLg"];
export const paddingLeftXsXl = paddingStyles["paddingLeftXsXl"];
export const paddingLeftXs2xl = paddingStyles["paddingLeftXs2xl"];
export const paddingLeftXs3xl = paddingStyles["paddingLeftXs3xl"];
export const paddingLeftXs4xl = paddingStyles["paddingLeftXs4xl"];
export const paddingLeftXs5xl = paddingStyles["paddingLeftXs5xl"];

export const paddingLeftSmZero = paddingStyles["paddingLeftSmZero"];
export const paddingLeftSm2xs = paddingStyles["paddingLeftSm2xs"];
export const paddingLeftSmXs = paddingStyles["paddingLeftSmXs"];
export const paddingLeftSmSm = paddingStyles["paddingLeftSmSm"];
export const paddingLeftSmMd = paddingStyles["paddingLeftSmMd"];
export const paddingLeftSmLg = paddingStyles["paddingLeftSmLg"];
export const paddingLeftSmXl = paddingStyles["paddingLeftSmXl"];
export const paddingLeftSm2xl = paddingStyles["paddingLeftSm2xl"];
export const paddingLeftSm3xl = paddingStyles["paddingLeftSm3xl"];
export const paddingLeftSm4xl = paddingStyles["paddingLeftSm4xl"];
export const paddingLeftSm5xl = paddingStyles["paddingLeftSm5xl"];

export const paddingLeftMdZero = paddingStyles["paddingLeftMdZero"];
export const paddingLeftMd2xs = paddingStyles["paddingLeftMd2xs"];
export const paddingLeftMdXs = paddingStyles["paddingLeftMdXs"];
export const paddingLeftMdSm = paddingStyles["paddingLeftMdSm"];
export const paddingLeftMdMd = paddingStyles["paddingLeftMdMd"];
export const paddingLeftMdLg = paddingStyles["paddingLeftMdLg"];
export const paddingLeftMdXl = paddingStyles["paddingLeftMdXl"];
export const paddingLeftMd2xl = paddingStyles["paddingLeftMd2xl"];
export const paddingLeftMd3xl = paddingStyles["paddingLeftMd3xl"];
export const paddingLeftMd4xl = paddingStyles["paddingLeftMd4xl"];
export const paddingLeftMd5xl = paddingStyles["paddingLeftMd5xl"];

export const paddingLeftLgZero = paddingStyles["paddingLeftLgZero"];
export const paddingLeftLg2xs = paddingStyles["paddingLeftLg2xs"];
export const paddingLeftLgXs = paddingStyles["paddingLeftLgXs"];
export const paddingLeftLgSm = paddingStyles["paddingLeftLgSm"];
export const paddingLeftLgMd = paddingStyles["paddingLeftLgMd"];
export const paddingLeftLgLg = paddingStyles["paddingLeftLgLg"];
export const paddingLeftLgXl = paddingStyles["paddingLeftLgXl"];
export const paddingLeftLg2xl = paddingStyles["paddingLeftLg2xl"];
export const paddingLeftLg3xl = paddingStyles["paddingLeftLg3xl"];
export const paddingLeftLg4xl = paddingStyles["paddingLeftLg4xl"];
export const paddingLeftLg5xl = paddingStyles["paddingLeftLg5xl"];

export const paddingLeftXlZero = paddingStyles["paddingLeftXlZero"];
export const paddingLeftXl2xs = paddingStyles["paddingLeftXl2xs"];
export const paddingLeftXlXs = paddingStyles["paddingLeftXlXs"];
export const paddingLeftXlSm = paddingStyles["paddingLeftXlSm"];
export const paddingLeftXlMd = paddingStyles["paddingLeftXlMd"];
export const paddingLeftXlLg = paddingStyles["paddingLeftXlLg"];
export const paddingLeftXlXl = paddingStyles["paddingLeftXlXl"];
export const paddingLeftXl2xl = paddingStyles["paddingLeftXl2xl"];
export const paddingLeftXl3xl = paddingStyles["paddingLeftXl3xl"];
export const paddingLeftXl4xl = paddingStyles["paddingLeftXl4xl"];
export const paddingLeftXl5xl = paddingStyles["paddingLeftXl5xl"];

export const paddingXZero = paddingStyles["paddingXZero"];
export const paddingX2xs = paddingStyles["paddingX2xs"];
export const paddingXXs = paddingStyles["paddingXXs"];
export const paddingXSm = paddingStyles["paddingXSm"];
export const paddingXMd = paddingStyles["paddingXMd"];
export const paddingXLg = paddingStyles["paddingXLg"];
export const paddingXXl = paddingStyles["paddingXXl"];
export const paddingX2xl = paddingStyles["paddingX2xl"];
export const paddingX3xl = paddingStyles["paddingX3xl"];
export const paddingX4xl = paddingStyles["paddingX4xl"];
export const paddingX5xl = paddingStyles["paddingX5xl"];

export const paddingXXsZero = paddingStyles["paddingXXsZero"];
export const paddingXXs2xs = paddingStyles["paddingXXs2xs"];
export const paddingXXsXs = paddingStyles["paddingXXsXs"];
export const paddingXXsSm = paddingStyles["paddingXXsSm"];
export const paddingXXsMd = paddingStyles["paddingXXsMd"];
export const paddingXXsLg = paddingStyles["paddingXXsLg"];
export const paddingXXsXl = paddingStyles["paddingXXsXl"];
export const paddingXXs2xl = paddingStyles["paddingXXs2xl"];
export const paddingXXs3xl = paddingStyles["paddingXXs3xl"];
export const paddingXXs4xl = paddingStyles["paddingXXs4xl"];
export const paddingXXs5xl = paddingStyles["paddingXXs5xl"];

export const paddingXSmZero = paddingStyles["paddingXSmZero"];
export const paddingXSm2xs = paddingStyles["paddingXSm2xs"];
export const paddingXSmXs = paddingStyles["paddingXSmXs"];
export const paddingXSmSm = paddingStyles["paddingXSmSm"];
export const paddingXSmMd = paddingStyles["paddingXSmMd"];
export const paddingXSmLg = paddingStyles["paddingXSmLg"];
export const paddingXSmXl = paddingStyles["paddingXSmXl"];
export const paddingXSm2xl = paddingStyles["paddingXSm2xl"];
export const paddingXSm3xl = paddingStyles["paddingXSm3xl"];
export const paddingXSm4xl = paddingStyles["paddingXSm4xl"];
export const paddingXSm5xl = paddingStyles["paddingXSm5xl"];

export const paddingXMdZero = paddingStyles["paddingXMdZero"];
export const paddingXMd2xs = paddingStyles["paddingXMd2xs"];
export const paddingXMdXs = paddingStyles["paddingXMdXs"];
export const paddingXMdSm = paddingStyles["paddingXMdSm"];
export const paddingXMdMd = paddingStyles["paddingXMdMd"];
export const paddingXMdLg = paddingStyles["paddingXMdLg"];
export const paddingXMdXl = paddingStyles["paddingXMdXl"];
export const paddingXMd2xl = paddingStyles["paddingXMd2xl"];
export const paddingXMd3xl = paddingStyles["paddingXMd3xl"];
export const paddingXMd4xl = paddingStyles["paddingXMd4xl"];
export const paddingXMd5xl = paddingStyles["paddingXMd5xl"];

export const paddingXLgZero = paddingStyles["paddingXLgZero"];
export const paddingXLg2xs = paddingStyles["paddingXLg2xs"];
export const paddingXLgXs = paddingStyles["paddingXLgXs"];
export const paddingXLgSm = paddingStyles["paddingXLgSm"];
export const paddingXLgMd = paddingStyles["paddingXLgMd"];
export const paddingXLgLg = paddingStyles["paddingXLgLg"];
export const paddingXLgXl = paddingStyles["paddingXLgXl"];
export const paddingXLg2xl = paddingStyles["paddingXLg2xl"];
export const paddingXLg3xl = paddingStyles["paddingXLg3xl"];
export const paddingXLg4xl = paddingStyles["paddingXLg4xl"];
export const paddingXLg5xl = paddingStyles["paddingXLg5xl"];

export const paddingXXlZero = paddingStyles["paddingXXlZero"];
export const paddingXXl2xs = paddingStyles["paddingXXl2xs"];
export const paddingXXlXs = paddingStyles["paddingXXlXs"];
export const paddingXXlSm = paddingStyles["paddingXXlSm"];
export const paddingXXlMd = paddingStyles["paddingXXlMd"];
export const paddingXXlLg = paddingStyles["paddingXXlLg"];
export const paddingXXlXl = paddingStyles["paddingXXlXl"];
export const paddingXXl2xl = paddingStyles["paddingXXl2xl"];
export const paddingXXl3xl = paddingStyles["paddingXXl3xl"];
export const paddingXXl4xl = paddingStyles["paddingXXl4xl"];
export const paddingXXl5xl = paddingStyles["paddingXXl5xl"];

export const paddingYZero = paddingStyles["paddingYZero"];
export const paddingY2xs = paddingStyles["paddingY2xs"];
export const paddingYXs = paddingStyles["paddingYXs"];
export const paddingYSm = paddingStyles["paddingYSm"];
export const paddingYMd = paddingStyles["paddingYMd"];
export const paddingYLg = paddingStyles["paddingYLg"];
export const paddingYXl = paddingStyles["paddingYXl"];
export const paddingY2xl = paddingStyles["paddingY2xl"];
export const paddingY3xl = paddingStyles["paddingY3xl"];
export const paddingY4xl = paddingStyles["paddingY4xl"];
export const paddingY5xl = paddingStyles["paddingY5xl"];

export const paddingYXsZero = paddingStyles["paddingYXsZero"];
export const paddingYXs2xs = paddingStyles["paddingYXs2xs"];
export const paddingYXsXs = paddingStyles["paddingYXsXs"];
export const paddingYXsSm = paddingStyles["paddingYXsSm"];
export const paddingYXsMd = paddingStyles["paddingYXsMd"];
export const paddingYXsLg = paddingStyles["paddingYXsLg"];
export const paddingYXsXl = paddingStyles["paddingYXsXl"];
export const paddingYXs2xl = paddingStyles["paddingYXs2xl"];
export const paddingYXs3xl = paddingStyles["paddingYXs3xl"];
export const paddingYXs4xl = paddingStyles["paddingYXs4xl"];
export const paddingYXs5xl = paddingStyles["paddingYXs5xl"];

export const paddingYSmZero = paddingStyles["paddingYSmZero"];
export const paddingYSm2xs = paddingStyles["paddingYSm2xs"];
export const paddingYSmXs = paddingStyles["paddingYSmXs"];
export const paddingYSmSm = paddingStyles["paddingYSmSm"];
export const paddingYSmMd = paddingStyles["paddingYSmMd"];
export const paddingYSmLg = paddingStyles["paddingYSmLg"];
export const paddingYSmXl = paddingStyles["paddingYSmXl"];
export const paddingYSm2xl = paddingStyles["paddingYSm2xl"];
export const paddingYSm3xl = paddingStyles["paddingYSm3xl"];
export const paddingYSm4xl = paddingStyles["paddingYSm4xl"];
export const paddingYSm5xl = paddingStyles["paddingYSm5xl"];

export const paddingYMdZero = paddingStyles["paddingYMdZero"];
export const paddingYMd2xs = paddingStyles["paddingYMd2xs"];
export const paddingYMdXs = paddingStyles["paddingYMdXs"];
export const paddingYMdSm = paddingStyles["paddingYMdSm"];
export const paddingYMdMd = paddingStyles["paddingYMdMd"];
export const paddingYMdLg = paddingStyles["paddingYMdLg"];
export const paddingYMdXl = paddingStyles["paddingYMdXl"];
export const paddingYMd2xl = paddingStyles["paddingYMd2xl"];
export const paddingYMd3xl = paddingStyles["paddingYMd3xl"];
export const paddingYMd4xl = paddingStyles["paddingYMd4xl"];
export const paddingYMd5xl = paddingStyles["paddingYMd5xl"];

export const paddingYLgZero = paddingStyles["paddingYLgZero"];
export const paddingYLg2xs = paddingStyles["paddingYLg2xs"];
export const paddingYLgXs = paddingStyles["paddingYLgXs"];
export const paddingYLgSm = paddingStyles["paddingYLgSm"];
export const paddingYLgMd = paddingStyles["paddingYLgMd"];
export const paddingYLgLg = paddingStyles["paddingYLgLg"];
export const paddingYLgXl = paddingStyles["paddingYLgXl"];
export const paddingYLg2xl = paddingStyles["paddingYLg2xl"];
export const paddingYLg3xl = paddingStyles["paddingYLg3xl"];
export const paddingYLg4xl = paddingStyles["paddingYLg4xl"];
export const paddingYLg5xl = paddingStyles["paddingYLg5xl"];

export const paddingYXlZero = paddingStyles["paddingYXlZero"];
export const paddingYXl2xs = paddingStyles["paddingYXl2xs"];
export const paddingYXlXs = paddingStyles["paddingYXlXs"];
export const paddingYXlSm = paddingStyles["paddingYXlSm"];
export const paddingYXlMd = paddingStyles["paddingYXlMd"];
export const paddingYXlLg = paddingStyles["paddingYXlLg"];
export const paddingYXlXl = paddingStyles["paddingYXlXl"];
export const paddingYXl2xl = paddingStyles["paddingYXl2xl"];
export const paddingYXl3xl = paddingStyles["paddingYXl3xl"];
export const paddingYXl4xl = paddingStyles["paddingYXl4xl"];
export const paddingYXl5xl = paddingStyles["paddingYXl5xl"];
