import { CSSObject } from "@emotion/react";
import styled from "@emotion/styled";
import {
  bgWhite,
  bodyLgStyle,
  colorOrange,
  colorPrimary,
  displayBlock,
  displayFlex,
  elevation3,
  fontWeightBold,
  marginBottomZero,
  marginNegTopMd,
  marginTopMd,
  marginXMd,
  marginXZero,
  marginYZero,
  marginZero,
  paddingLeftXl,
  paddingLg,
  paddingTopZero,
  paddingXLg,
  paddingYMd,
} from "@Styles";
import { rotateAnimation } from "@Stylize/animations/rotate";
import { ColorPalette } from "@Types/configs";
import { breakpointUp } from "@Variables/breakpoints";
import themes, { type Tone } from "@Variables/themes";
import { zIndexes } from "@Variables/zIndexes";
import { Link } from "react-router-dom";
//#region MenuItems Styles
const itemCSS: CSSObject[] = [
  marginZero,
  {
    listStyleType: "none",
    textDecoration: "none",
  },
];

const itemLinkCSS = ({
  tone,
  skipTone,
  colors,
}: {
  tone: Tone;
  skipTone?: boolean;
  colors: ColorPalette;
}): CSSObject[] => {
  return [
    colorPrimary(tone),
    marginZero,
    paddingXLg,
    paddingYMd,
    bodyLgStyle,
    displayFlex,

    {
      alignItems: "center",
      justifyContent: "space-between",
      margin: "auto",
      position: "relative",
      textDecoration: "none",
      color: skipTone ? colors.label.main : undefined,

      "&:after": [
        marginXMd,
        displayBlock,
        {
          borderBottom: `1px solid ${skipTone ? colors.label[50] : themes[tone].colors.secondary}`,
          bottom: 0,
          content: "''",
          left: 0,
          right: 0,
          position: "absolute",
        },
      ],

      "&.active": [bgWhite, fontWeightBold],

      ":hover, :active, :focus": [colorOrange, bgWhite, fontWeightBold],
    },
  ];
};

const itemSubmenuStyle = (submenuHeight: number): CSSObject => ({
  margin: 0,
  overflow: "auto",
  transition: "height 250ms ease-out, padding 250ms ease-out",
  "&.open": { height: submenuHeight },
  "&.close": { height: 0, padding: 0 },
  "& a": paddingLeftXl,
});

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary.main};
`;
const submenuIconStyle: CSSObject = rotateAnimation;
//#endregion MenuItems Styles

//#region Menu Styles

const containerCSS = (navBarHeight: string): CSSObject => ({
  overflow: "auto",
  pointerEvents: "none",
  position: "fixed",
  marginTop: navBarHeight,
  right: 0,
  top: 0,
  transition: "height 500ms ease-out, padding 500ms ease-out",
  width: "100%",
  zIndex: zIndexes.dropdown,
  ...paddingXLg,
  [breakpointUp["md"]]: {
    right: "2.5rem",
    width: "25rem",
  },
  "&.open": { height: `calc(100vh - ${navBarHeight})` },
  "&.close": { height: 0, ...paddingXLg },
});

const menuCSS: CSSObject[] = [
  bgWhite,
  elevation3,
  marginXZero,
  marginYZero,
  marginNegTopMd,
  paddingTopZero,
  paddingYMd,
  {
    pointerEvents: "all",
  },
];

const headerStyle: CSSObject[] = [
  marginBottomZero,
  paddingLg,
  bodyLgStyle,
  displayFlex,
  {
    justifyContent: "space-between",
  },
];

const extraContentItemCSS: CSSObject[] = [
  marginTopMd,
  marginBottomZero,
  marginXMd,
  {
    listStyle: "none",
  },
];
//#endregion Menu Styles

export {
  containerCSS,
  extraContentItemCSS,
  headerStyle,
  itemCSS,
  itemLinkCSS,
  itemSubmenuStyle,
  menuCSS,
  StyledLink,
  submenuIconStyle,
};
