import { PostsService } from "@Services/posts.service";
import { type UseBaseQueryResult, useQuery } from "@tanstack/react-query";
import type { Initiative, Signable } from "@Types/services/post";
import type { SignableParams } from "@Types/services/typeServiceParams";
import { Tone } from "@Variables/themes";
import type { SignableType } from "../types";
import useBaseQuery from "./useBaseQuery";
import usePagiBaseQuery from "./usePagiBaseQuery";

type UseSignables = {
  params: SignableParams;
  variant: SignableType;
  tone: Tone;
  enabled?: boolean;
};

const useSignables = ({
  params,
  variant,
  tone,
  enabled = true,
}: UseSignables) => {
  const service = PostsService.getInstance();

  return useBaseQuery<Signable[], "signables">({
    queryKey: [variant, tone, params],
    dataName: "signables",
    service: async () => service.getSignables<Signable[]>(params),
    onError: (error) => {
      console.error("An error occurred:", error);
    },
    enabled,
  });
};

const usePagiSignables = ({
  params,
  variant,
  tone,
  enabled = true,
}: UseSignables) => {
  const service = PostsService.getInstance();

  return usePagiBaseQuery<Signable[], "signables">({
    queryKey: [variant, tone, JSON.stringify(params), "v2"],
    dataName: "signables",
    defaultValue: [],

    service: async () => {
      const response = await service.getPagiSignables<Signable[]>(params);

      return response;
    },

    onError: (error) => {
      console.error("An error occurred:", error);
    },
    enabled,
    _optimisticResults: "isRestoring",
    staleTime: 1000 * 60 * 5,
    _defaulted: true,
  });
};

const useSignable = (
  id: "mostSigned",
): UseBaseQueryResult<Initiative, "signable"> => {
  const service = PostsService.getInstance();

  return useQuery({
    queryKey: ["signable", id],

    queryFn: async () => service.getSignable<Initiative>(id as string),
  });
};

export { usePagiSignables, useSignable, useSignables };
