import React, { useEffect, useState } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import {
  faArrowDownToBracket,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Visible from "@Atoms/Visible";
import { PSFSharedStepsProps, PSF_STEP } from "@Types/components/psf";

import {
  Button,
  Column,
  Container,
  Heading,
  IconBox,
  Row,
  UserForm,
  UserFormFields,
} from "@Components";
import { bgGradient } from "@Styles/backgrounds";
import { elevation3 } from "@Styles/elevations";
import {
  marginBottomLg,
  marginBottomMdZero,
  marginBottomXl,
  marginTopZero,
  paddingLg,
  paddingYXl,
} from "@Styles/spacers";
import api from "@Utils/api";

const headerStyle: CSSObject[] = [
  marginBottomXl,
  {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
];

const iconBoxContainerStyle: CSSObject[] = [marginBottomLg, marginBottomMdZero];

const DocumentRequestPage = ({
  loaded,
  signable,
  signer,
}: PSFSharedStepsProps) => {
  const [displayUserForm, setDisplayUserForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tone } = useTheme();
  const { trackEvent } = useMatomo();
  const { _id } = useParams();

  const [recipient, setRecipient] = useState<UserFormFields>({
    ...signer,
    address: "",
  } as UserFormFields);

  useEffect(() => {
    if (!signer.email && !location.state?.signed._id) {
      navigate(`/${_id}`, { replace: true });
    }
  }, [location, navigate, signer, _id]);

  const handleFormSubmit = (
    exportType: string,
    event?: React.FormEvent<Element>,
  ) => {
    event?.preventDefault();
    setLoading(true);

    if ("initiative" != signable.type) {
      const promise = Promise.resolve();
      return promise.then(() => {
        setLoading(false);
        return {
          valid: false,
          message: t("components.userForm.validation.submit.error"),
        };
      });
    }

    return api
      .createDocument({
        initiativeId: signable._id,
        initiativeStage: signable.stage,
        firstName: signer.firstName,
        lastName: signer.lastName,
        country: signer.country,
        location: signer.location,
        postalCode: signer.postalCode,
        email: signer.email,
        subscribe: signer.subscribe,
        recipient,
        exportType,
      })
      .then((response) => {
        trackEvent({
          category: "signable",
          action: "createDocument",
          customDimensions: [
            {
              id: 1, // Signable ID
              value: signable._id,
            },
            {
              id: 2, // Signable model
              value: signable.type,
            },
            {
              id: 3, // Newsletter opt-in
              value: response.data.subscribe.toString(),
            },
          ],
        });

        navigate(`/${signable.name}/${PSF_STEP.Donation}`, {
          replace: true,
          state: { ...location.state, signable, signed: response.data },
        });
        return { valid: true, message: "" };
      })
      .catch(() => {
        return {
          valid: false,
          message: t("components.userForm.validation.submit.error"),
        };
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Visible when={loaded}>
        <Container
          css={headerStyle}
          size="lg"
        >
          <Heading
            scale={2}
            aria-level={1}
            variant="display"
            color="primary"
          >
            {t("pages.psf.documentRequest.header.title")}
          </Heading>

          <Button
            icon={faArrowDownToBracket}
            loading={loading}
            label={t("pages.psf.documentRequest.header.buttonLabel")}
            onClick={(event) => handleFormSubmit("download", event)}
            variant="secondary"
          />
        </Container>

        <div css={[bgGradient(tone, "light"), paddingYXl]}>
          <Container
            css={{ textAlign: "center" }}
            size="lg"
          >
            <Heading
              scale={3}
              aria-level={2}
              color="primary"
              css={[marginTopZero]}
            >
              {t("pages.psf.documentRequest.steps.title")}
            </Heading>

            <Row>
              <Column
                css={iconBoxContainerStyle}
                span={{ default: 12, md: 3 }}
              >
                <IconBox
                  icon="download"
                  title={"1. " + t("pages.psf.documentRequest.steps.1.title")}
                  text={t("pages.psf.documentRequest.steps.1.text")}
                />
              </Column>

              <Column
                css={iconBoxContainerStyle}
                span={{ default: 12, md: 3 }}
              >
                <IconBox
                  icon="signature"
                  title={"2. " + t("pages.psf.documentRequest.steps.2.title")}
                  text={t("pages.psf.documentRequest.steps.2.text")}
                />
              </Column>

              <Column
                css={iconBoxContainerStyle}
                span={{ default: 12, md: 3 }}
              >
                <IconBox
                  icon="post"
                  title={"3. " + t("pages.psf.documentRequest.steps.3.title")}
                  text={t("pages.psf.documentRequest.steps.3.text")}
                />
              </Column>

              <Column
                css={iconBoxContainerStyle}
                span={{ default: 12, md: 3 }}
              >
                <IconBox
                  icon="activism"
                  title={"4. " + t("pages.psf.documentRequest.steps.4.title")}
                  text={t("pages.psf.documentRequest.steps.4.text")}
                />
              </Column>
            </Row>
          </Container>
        </div>

        <Container
          css={headerStyle}
          size="lg"
        >
          <Heading
            scale={2}
            aria-level={1}
            color="primary"
          >
            {t("pages.psf.documentRequest.post.title")}
          </Heading>

          <p>{t("pages.psf.documentRequest.post.text")}</p>

          <Button
            icon={displayUserForm ? faChevronDown : faChevronUp}
            label={t("pages.psf.documentRequest.post.buttonLabel")}
            onClick={() => setDisplayUserForm((prev) => !prev)}
            tone={tone}
            variant="outline"
          />
        </Container>

        <Visible when={displayUserForm}>
          <Container size="lg">
            <Row>
              <Column
                span={{ default: 12, md: 4 }}
                offset={{ default: 0, md: 4 }}
              >
                <div css={[elevation3, paddingLg]}>
                  <UserForm
                    autoFocus="address"
                    optionalFields={["address"]}
                    hideFields={["email", "subscribe"]}
                    user={recipient}
                    setUser={setRecipient}
                    subscribe={false}
                    submitButtonLabel={
                      t(
                        "pages.psf.documentRequest.post.submitButtonLabel",
                      ) as string
                    }
                    onSubmit={(event) => handleFormSubmit("post", event)}
                  />
                </div>
              </Column>
            </Row>
          </Container>
        </Visible>
      </Visible>
    </>
  );
};

export default DocumentRequestPage;
