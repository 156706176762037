import { CSSObject } from "@emotion/react";
import {
  faBullseyeArrow,
  faMapMarkerAlt,
  faTags,
  faUserGroup,
} from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import { Signable } from "../../types/services/post";
import { fetchActors } from "../helpers/fetchActors";
import { displayFlex } from "../styles/displays";
import { fontSizeMd } from "../styles/fontSizes";
import { fontWeightBold, fontWeightRegular } from "../styles/fontWeights";
import { lineHeightLg } from "../styles/lineHeights";
import {
  marginBottomXs,
  marginXZero,
  marginZero,
  paddingZero,
} from "../styles/spacers";
import Row from "./Row";
import Sticker from "./Sticker";
import { BodySm, Label } from "./typography";

const defaultContentStyle: CSSObject[] = [fontWeightRegular, displayFlex];

const labelStyle: CSSObject[] = [
  fontWeightBold,
  {
    whiteSpace: "nowrap",
  },
];

type IconLabelProps = {
  /** Label type */
  type: "initiators" | "location" | "tag" | "targets";
  /** If a label before names should be displayed. */
  prefix?: boolean;
  /** Object to fetch the label. */
  signable: Signable;
};

const IconLabel = ({ signable, type, prefix = false }: IconLabelProps) => {
  const { t } = useTranslation();
  let icon;
  let content = <></>;
  const contentStyle: CSSObject[] = [...defaultContentStyle];

  if (type === "tag") {
    if (signable.tag && signable.tag.title) {
      icon = faTags;
      content = (
        <Sticker
          label={signable.tag.title}
          style="outline"
          variant="secondary"
        />
      );
      contentStyle.push({ alignItems: "center" });
    }
  } else if (type === "location") {
    if (
      signable.type === "initiative" &&
      signable.location &&
      signable.location.name
    ) {
      icon = faMapMarkerAlt;
      content = (
        <Sticker
          label={signable.location.name}
          style="outline"
          variant="secondary"
        />
      );
    }
    contentStyle.push({ alignItems: "center" });
  } else {
    icon = "initiators" == type ? faUserGroup : faBullseyeArrow;

    const prefixLabel =
      "initiators" == type
        ? t("components.iconLabel.prefix.initiators")
        : t("components.iconLabel.prefix.targets");

    return icon && content ? (
      <Row css={[marginXZero, { width: "100%", alignItems: "center" }]}>
        <div css={[paddingZero, marginBottomXs]}>
          <Label
            css={[contentStyle, marginZero, { alignItems: "center" }]}
            icon={icon}
            variant="secondary"
          >
            {prefix && (
              <BodySm css={[labelStyle, marginZero, fontSizeMd]}>
                {prefixLabel}
              </BodySm>
            )}

            <BodySm
              css={[
                marginZero,
                lineHeightLg,
                fontSizeMd,
                { textAlign: "left" },
              ]}
            >
              {fetchActors(
                type === "initiators"
                  ? signable.initiators
                  : signable.type === "petition"
                    ? signable.targets
                    : [],
              )}
            </BodySm>
          </Label>
        </div>
      </Row>
    ) : null;
  }

  return icon && content ? (
    <Label
      css={contentStyle}
      icon={icon}
      variant="secondary"
    >
      {content}
    </Label>
  ) : null;
};

export default IconLabel;
