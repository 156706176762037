const hexToRgba = (hex: string, opacity: number): string => {
  // Remove the leading # if present
  hex = hex.replace(/^#/, "");

  // If shorthand (3 digits), expand to 6 digits (e.g., #fff -> #ffffff)
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Parse r, g, b values from hex
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return the rgba string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export default hexToRgba;
