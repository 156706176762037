import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { displayFlex, gapLg } from "@Styles";
import { motion } from "framer-motion";
import { useEffect } from "react";

// Styled container for the loading dots
const LoadingDots = styled(motion.div)([
  gapLg,
  displayFlex,
  {
    justifyContent: "spaceBetween",
  },
]);

// Styled individual loading dot
const LoadingDot = styled(motion.div)(({ color }) => ({
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  margin: "0 5px",
  background: color,
}));

// Animation variants for the container
const containerVariants = {
  animate: {
    transition: {
      staggerChildren: 0.25, // Delay for each dot's animation
    },
  },
};

// Animation variants for the dots
const dotVariants = {
  animate: {
    y: [-20, 0, -20], // Bouncing effect vertically
    transition: {
      duration: 1.5, // Animation duration
      repeat: Infinity, // Loop the animation
      ease: "easeInOut",
    },
  },
};

// Dots component
const Dots = () => {
  const { colors } = useTheme();
  useEffect(() => {
    // Disable scrolling while loading
    document.body.style.overflow = "hidden";

    // Cleanup function to re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const primaryColor = colors.primary.main;
  const secondaryColor = colors.secondary.main;

  return (
    <LoadingDots
      variants={containerVariants}
      animate="animate"
    >
      <LoadingDot
        variants={dotVariants}
        color={primaryColor}
      />
      {/* Blue */}
      <LoadingDot
        variants={dotVariants}
        color={`linear-gradient(45deg,${primaryColor},${secondaryColor} )`}
      />
      {/* Gradient */}
      <LoadingDot
        variants={dotVariants}
        color={secondaryColor}
      />
      {/* Red */}
    </LoadingDots>
  );
};

export default Dots;
