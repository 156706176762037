import { faBoxHeart } from "@fortawesome/pro-solid-svg-icons";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import { Placeholder } from "@Components";
import { ContainerPageProps } from "@Types/components/pageProps";
import type { Fragment } from "@Types/services/post";
import DonorBannerIllustration from "@Components/DonorBannerIllustration";

const DonorBanner = ({ loaded, page, ...props }: ContainerPageProps) => {
  const { t } = useTranslation();

  const fragments =
    loaded &&
    page?.fragments &&
    page?.fragments?.filter(
      (fragment: Fragment) => fragment?.section == "donor-banner-section",
    );

  const title = fragments ? t(fragments[0]?.title) : <Placeholder />;
  const content = fragments ? parse(t(fragments[0]?.content)) : <Placeholder />;

  return (
    <section>
      <DonorBannerIllustration
        illustration="donation"
        buttonLabel={t("pages.aboutUs.donorBanner.buttonLabel")}
        buttonLink="#"
        content={content as string}
        title={title as string}
        icon={faBoxHeart}
        {...props}
      />
    </section>
  );
};

export default DonorBanner;
