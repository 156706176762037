import {
  DefaultError,
  UseQueryOptions,
  UseQueryResult,
  keepPreviousData,
  useQuery,
} from "@tanstack/react-query";
import { MetaData, ResponsePayload } from "@Types/services/typeServiceParams";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

type MetaLoad = { meta: MetaData; loading: boolean };

interface UsePagiBaseQueryOptions<T, K extends string = "data">
  extends Omit<
    UseQueryOptions<ResponsePayload<T>, any, T, any>,
    "queryFn" | "initialData"
  > {
  service: (params: any) => Promise<AxiosResponse<ResponsePayload<T>>>;
  dataName: K;
  defaultValue?: T;
  onError?: (error: DefaultError) => void;
  onSuccess?: (data: ResponsePayload<T>) => void;
}

type CombinedPagiQueryOptions<T, K extends string> = UsePagiBaseQueryOptions<
  T,
  K
> &
  Omit<
    UseQueryOptions<ResponsePayload<T>, any, ResponsePayload<T>, any>,
    "queryFn" | "initialData"
  >;

export type UsePagiBaseQueryResult<T, K extends string> = Omit<
  UseQueryResult<ResponsePayload<T>, any>,
  "data" | "isLoading"
> & {
  [key in K]: T;
} & MetaLoad;

const usePagiBaseQuery = <T, K extends string = "data">({
  service,
  dataName = "data" as K,
  queryKey,
  defaultValue,
  ...queryOptions
}: CombinedPagiQueryOptions<T, K>): UsePagiBaseQueryResult<T, K> => {
  const [customLoading, setCustomLoading] = useState(true);

  const query = useQuery({
    queryKey,
    queryFn: async (params): Promise<ResponsePayload<T>> => {
      setCustomLoading(true);
      const response: AxiosResponse<ResponsePayload<T>> = await service(params);

      prepareMetaData<T>(response);

      return response.data;
    },
    placeholderData: keepPreviousData,

    ...queryOptions,
  });

  const { isLoading, data, ...restQuery } = query;

  const loading = isLoading || query.isPlaceholderData || query.isFetching;

  useEffect(() => {
    if (!loading) {
      setCustomLoading(false);
    }
  }, [loading]);

  return {
    ...restQuery,
    [dataName]: data?.data ?? defaultValue,
    loading: customLoading,
    meta: data?.meta,
  } as UsePagiBaseQueryResult<T, K>;
};

export default usePagiBaseQuery;

const prepareMetaData = <T>(
  response: AxiosResponse<ResponsePayload<T>, any>,
) => {
  const paramsData = response.config.params;

  const data = response.data;
  const totalCount = data?.meta?.totalCount ?? 0;
  const limit = paramsData?.limit ?? 1;
  const offset = paramsData?.offset ?? 0;
  const currentPage = Math.ceil((offset + 1) / limit);

  data.meta = {
    ...data.meta,
    limit,
    totalCount,
    currentPage,
  };

  if (totalCount > limit) {
    data.meta.totalPages = Math.ceil(totalCount / limit);
  } else {
    data.meta.totalPages = 1;
  }
};
