import { ReactNode } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

import {
  marginBottomLg,
  marginBottomMdLg,
  marginBottomMdZero,
  marginBottomXsXs,
  marginBottomZero,
  paddingRightSmMd,
  paddingRightXsZero,
  paddingXXsZero,
} from "@Styles/spacers";
import { ToneColors } from "@Types/configs";
import { TextAlign, TypographyBody } from "@Types/typesTypography";
import { breakpointUp } from "@Variables/breakpoints";
import { Heading, HeadingProps, HeadingScale } from "..";
import Visible from "../atoms/Visible";
import { Body, BodyLg } from "../typography";

type Direction = "column" | "row";
interface TitleProps
  extends Omit<HeadingProps, "children" | "title" | "scale"> {
  title: ReactNode;
  direction?: Direction;
  scale?: HeadingScale;
}

interface IconProps extends Omit<FontAwesomeIconProps, "color"> {
  color?: ToneColors;
}

interface TextProps extends TypographyBody {
  title: ReactNode;
}

export type HeadlineProps = {
  /** Headline title size. */
  size?: "md" | "lg";
  /** Headline subtitle. */
  subtitle?: string;
  /** Title. */
  title: TitleProps;
  text?: TextProps;
  icon?: Partial<IconProps>;
  align?: TextAlign;
};

const iconWidth = "2.5rem";

const iconStyle = (iconTone?: string): CSSObject[] => [
  paddingRightXsZero,
  paddingRightSmMd,
  marginBottomXsXs,
  marginBottomMdZero,
  {
    color: iconTone,
    width: iconWidth,
  },
];

const subtitleStyle: CSSObject = {
  ...marginBottomLg,
  fontWeight: 700,
};

const titleStyle = (
  titleDirection: Direction,
  text?: ReactNode,
  titleTone?: string,
): CSSObject[] => {
  let titleMargin = [marginBottomMdZero];
  if (text) {
    titleMargin = [marginBottomLg, marginBottomMdLg];
  }

  return [
    ...titleMargin,
    paddingXXsZero,
    {
      color: titleTone,
      display: "flex",
    },
    {
      alignItems: "flex-start",
      flexDirection: titleDirection,
    },
    {
      [breakpointUp["md"]]: {
        alignItems: "center",
        flexDirection: "row",
      },
    },
  ];
};

/**
 * Displays a title with icon, subtitle and text, to be used as headline and modal text.
 */
const Headline = ({
  subtitle,
  title,
  text: textProps,
  icon: iconProps,
  align,
}: HeadlineProps) => {
  const { colors: swatchColors } = useTheme();
  const titleTone = title.color ? swatchColors[title.color].main : "inherit";

  const { icon, color, ...iconRest } = iconProps ?? {};

  let iconTone = color ? swatchColors[color].main : "inherit";

  if (icon && color) {
    iconTone = swatchColors[color].main;
  } else {
    iconTone = titleTone;
  }

  const {
    direction = "column",
    lineClamp = { default: 4, md: 4, lockHeight: false },
    title: children,
    scale = 5,
    ...rest
  } = title;

  const titlePropsDefault: HeadingProps = {
    scale,
    lineClamp,
    children,
    ...rest,
  };

  const { title: text = "", ...textRest } = textProps ?? {};
  return (
    <div>
      <Visible when={!!subtitle}>
        <Body css={subtitleStyle}>{subtitle}</Body>
      </Visible>

      <div css={titleStyle(direction, text, titleTone)}>
        {icon && (
          <FontAwesomeIcon
            aria-hidden={true}
            css={iconStyle(iconTone)}
            fixedWidth
            icon={icon}
            size="lg"
            {...iconRest}
          />
        )}
        <Heading
          {...titlePropsDefault}
          align={align}
        />
      </div>

      {text && (
        <BodyLg
          css={marginBottomZero}
          {...textRest}
        >
          {text}
        </BodyLg>
      )}
    </div>
  );
};

export default Headline;
