import React, { useEffect, useRef, useState } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useParams } from "react-router-dom";

import { BodyLg, Heading, SocialMediaShare, Tab, Tabs } from "@Components";
import routes from "@Configs/routes";
import { marginBottomMd, marginYXl } from "@Styles/spacers";
import { Signable } from "@Types/services/post";
import ContactTab from "./ContactTab";
import InfoTab from "./InfoTab";
import SingleUpdateTab from "./SingleUpdateTab";
import UpdatesTab from "./UpdatesTab";

type SignablePageTabsProps = {
  signable: Signable;
};

export type SignablePageElementProps = {
  signable: Signable;
};

const SignablePageTabs = ({ signable }: SignablePageTabsProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const tabsRef = useRef<HTMLDivElement>(null);
  const { tone } = useTheme();
  const [updateId, setUpdateId] = useState(useParams().updateId);

  const shareContainerStyle: CSSObject[] = [];

  const shareTitleStyle: CSSObject[] = [marginBottomMd];

  useEffect(() => {
    const match = matchPath(routes.updatesId, location.pathname);

    if (match && match.params.updateId) {
      setUpdateId(match.params.updateId);
    } else {
      setUpdateId("");
    }
  }, [location]);

  const tabs = [
    <Tab
      active={!updateId}
      id="info"
      key="info-tab"
      label={t(`pages.signable.${tone}.tabs.info`)}
    >
      <InfoTab signable={signable} />
    </Tab>,

    updateId ? (
      <Tab
        active={true}
        id="updates"
        key="updates-tab"
        label={t(`pages.signable.${tone}.tabs.updates`)}
        onTabChange={() => setUpdateId("")}
        path={`/${signable.name}/updates`}
      >
        <SingleUpdateTab
          signable={signable}
          tabsRef={tabsRef}
          updateId={updateId}
        />
      </Tab>
    ) : (
      <Tab
        id="updates"
        key="updates-tab"
        label={t(`pages.signable.${tone}.tabs.updates`)}
        path={`/${signable.name}/updates`}
      >
        <UpdatesTab signable={signable} />
      </Tab>
    ),
  ];

  if (signable.type === "initiative") {
    tabs.push(
      <Tab
        id="contact"
        key="contact-tab"
        label={t(`pages.signable.${tone}.tabs.contact.title`)}
      >
        <ContactTab signable={signable} />
      </Tab>,
    );
  }

  tabs.push(
    <Tab
      id="share"
      key="share-tab"
      label={t(`pages.signable.${tone}.tabs.share`)}
      path={`/${signable.name}/share`}
    >
      {signable && (
        <div css={shareContainerStyle}>
          <Heading
            scale={2}
            variant="display"
            color="primary"
            css={shareTitleStyle}
          >
            {t("pages.signable.share.title")}
          </Heading>

          <BodyLg css={marginBottomMd}>
            {t("pages.signable.share.text.line1")}
            <br />
            {t("pages.signable.share.text.line2")}
          </BodyLg>

          <div css={marginYXl}>
            <SocialMediaShare
              context="signableTab"
              size="md"
              signableTitle={signable.title}
              signableType={signable.type}
              url={window.location.href}
              transparent
              includeLabel
            />
          </div>
        </div>
      )}
    </Tab>,
  );

  <Tab
    id="share"
    label={t(`pages.signable.${tone}.tabs.share`)}
    path={`/${signable.name}/share`}
  >
    {signable && (
      <div css={shareContainerStyle}>
        <Heading
          scale={2}
          variant="display"
          color="primary"
          css={shareTitleStyle}
        >
          {t("pages.signable.share.title")}
        </Heading>

        <BodyLg css={marginBottomMd}>
          {t("pages.signable.share.text.line1")}
          <br />
          {t("pages.signable.share.text.line2")}
        </BodyLg>

        <SocialMediaShare
          context="signableTab"
          size="md"
          signableTitle={signable.title}
          signableType={signable.type}
          url={window.location.href}
          variant="primary"
          background="dark"
          includeLabel={false}
        />
      </div>
    )}
  </Tab>;

  if (signable.type !== "initiative") {
    tabs.push(<React.Fragment key="empty-tab"></React.Fragment>);
  }

  return (
    <div ref={tabsRef}>
      <Tabs>{tabs}</Tabs>
    </div>
  );
};

export default SignablePageTabs;
