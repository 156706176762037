import React, { createContext, useContext, useState } from "react";

type SignatureProviderProps = {
  children: React.ReactNode;
};

type EmailContextValues = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

export const EmailContext = createContext<EmailContextValues>({
  email: "",
  setEmail: () => undefined,
});

export const useEmailContext = (): EmailContextValues => {
  const context = useContext(EmailContext);

  if (!context) {
    throw new Error("useEmailContext must be used within Theme Provider");
  }

  return context;
};

export const EmailProvider = ({ children }: SignatureProviderProps) => {
  const [email, setEmail] = useState<string>("");

  return (
    <EmailContext.Provider value={{ email, setEmail }}>
      {children}
    </EmailContext.Provider>
  );
};
