import { CSSObject } from "@emotion/react";
import styled from "@emotion/styled";
import {
  bgGray3,
  bgPrimary,
  displayFlex,
  marginBottom3xl,
  marginBottomXl,
} from "@Styles";
import { Tone } from "@Variables/themes";
import { motion } from "framer-motion";

// Styled component for the slide pagination wrapper
const SlidePaginationWrapper = styled(motion.div)({
  display: "flex",
  alignItems: "flex-start", // Ensures Y=0
  transition: "transform 0.5s ease-in-out",
  position: "relative",
  width: "90%",
});

// Styled component for the dots container
const SlidePaginationDotsContainer = styled("div")([
  displayFlex,
  marginBottomXl,
  {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
]);

const SlidePaginationDot = styled("div")<{ active: boolean; tone: Tone }>(
  ({ active, tone }) => [
    active ? bgPrimary(tone) : bgGray3,
    {
      width: "1rem",
      height: "1rem",
      margin: "0 5px",
      borderRadius: "50%",
      cursor: "pointer",
      transition: "background-color 0.1s ease",
    },
  ],
);

// Styled component for the pagination container
const SlidePaginationContainer = styled("div")({
  ...marginBottom3xl,
  width: "100%",
  paddingBlock: "0.5rem",
  overflowX: "hidden",
  boxSizing: "border-box",
  position: "relative",
});

// Styles for the pagination container
const paginationContainerStyles: CSSObject = {
  width: "100vw",
  marginLeft: "-1rem",
};

// Animation styles for the slide pagination wrapper
const slidPaginationWrapperStyles = (currentIndex: number) => ({
  animate: { x: `calc(-${currentIndex * 100}%)` },
  transition: { type: "tween", ease: "easeInOut", duration: 0.1 },
});

// Styles for signable cards
const signableCardStyles: CSSObject[] = [
  {
    flex: "0 0 calc(100% - 1rem)",
    marginLeft: "1rem",
    boxSizing: "content-box",
    textAlign: "center",
    borderRadius: "10px",
  },
];

export {
  paginationContainerStyles,
  signableCardStyles,
  SlidePaginationContainer,
  SlidePaginationDot,
  SlidePaginationDotsContainer,
  SlidePaginationWrapper,
  slidPaginationWrapperStyles,
};
