import React, { useEffect, useRef, useState } from "react";
import { CSSObject, useTheme } from "@emotion/react";
import {
  faBoxHeart,
  faBullseyeArrow,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Heading } from "./Heading";
import { BodySm } from "./typography";
import { colorBlack } from "@Styles";

type ProgressProps = {
  label?: string | React.ReactNode;
  max: number;
  value: number;
};

const ProgressBar = ({ label, max, value }: ProgressProps) => {
  const { colors } = useTheme();
  const { i18n } = useTranslation();
  const [progress, setProgress] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);

  // Adjust max if it's less than value
  const effectiveMax = Math.max(max, value);

  // Container styles for progress bar and text
  const progressBarContainer: CSSObject = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.5rem",
    borderRadius: "0.5rem",
  };

  const iconStyle: CSSObject = {
    color: colors.secondary.main,
    fontSize: "1.5rem",
  };

  const progressTextStyle: CSSObject = {
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: colors.secondary.main,
  };

  const goalTextStyle: CSSObject = {
    fontSize: "1.25rem",
    color: "#f97316",
    marginBottom: 0,
  };

  const progressStyle: CSSObject = {
    width: "100%",
    height: ".75rem",
    borderRadius: "0.25rem",
    backgroundColor: colors.label[15],
    overflow: "hidden",
    marginTop: "0.5rem",
  };

  const foregroundStyle: CSSObject = {
    width: `${(progress / effectiveMax) * 100}%`,
    height: "100%",
    background: colors.secondary.main,
    transition: "width 200ms ease-out",
  };

  useEffect(() => {
    if (value > 0) {
      let valueCount = 0;
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry.isIntersecting) {
            return;
          }

          const valueIncrement = value / 25;
          const updateLevel = () => {
            valueCount += valueIncrement;
            if (valueCount <= value) {
              setProgress(valueCount);
              setTimeout(updateLevel, 100);
            }
          };
          updateLevel();
          observer.disconnect();
        },
        { root: null, threshold: 0.1 },
      );

      if (ref.current) observer.observe(ref.current);
    }
  }, [value]);

  return (
    <div>
      <div css={progressBarContainer}>
        <div css={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <FontAwesomeIcon
            icon={faBoxHeart}
            css={iconStyle}
          />
          <Heading
            scale={5}
            css={progressTextStyle}
          >
            {new Intl.NumberFormat(i18n.language).format(value)}€
          </Heading>
          {label && <BodySm css={{ color: "#333" }}>{label}</BodySm>}
        </div>
        <BodySm
          as="div"
          css={goalTextStyle}
        >
          <span css={[colorBlack]}>Spendenziel:</span>{" "}
          <FontAwesomeIcon
            icon={faBullseyeArrow}
            css={[
              iconStyle,
              {
                color: colors.primary.main,
                margin: "0 .1rem 0 .1rem",
              },
            ]}
          />{" "}
          {new Intl.NumberFormat(i18n.language).format(effectiveMax)}€
        </BodySm>
      </div>
      <div
        css={progressStyle}
        ref={ref}
      >
        <div css={foregroundStyle}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
