// export default Loading;
import styled, { CSSObject } from "@emotion/styled";
import {
  colorBlack,
  displayFlex,
  fontSizeLg,
  marginBottom2xl,
  marginTop4xl,
  marginTopXl,
} from "@Styles";
import { color } from "@Utils";
import colors from "@Variables/colors";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import Dots from "./LoadingDots";
import LoadingText from "./LoadingText";

// Styled overlay that covers the entire screen
const LoadingOverlay = styled.div<{ fixed: boolean }>(({ fixed }) => {
  let conditionalStyles: CSSObject[] = [];
  if (fixed) {
    conditionalStyles = [
      marginTop4xl,
      marginBottom2xl,

      {
        position: "fixed",
        height: "100vh",
        top: 0,
        left: 0,
      },
    ];
  } else {
    conditionalStyles = [
      {
        position: "absolute",
        height: "100%",
      },
    ];
  }

  return [
    fontSizeLg,
    displayFlex,
    colorBlack,
    {
      top: 0,
      left: 0,
      flexDirection: "column",
      width: "100%",
      backgroundColor: color.hexToRgba(colors.gray1, 0.9),
      alignItems: "center",
      justifyContent: "start",
      zIndex: 9999,
      color: "black",
    },
    ...conditionalStyles,
  ];
});

/**
 * A loading screen that covers the entire screen and prevents user interaction.
 */
type LoadingProps = {
  fixed?: boolean;
};

const Loading = ({ fixed = false }: LoadingProps) => {
  const [loaded, setLoaded] = useState(false);

  const fixedStyles: CSSObject[] = fixed
    ? [marginTop4xl, marginBottom2xl]
    : [
        {
          position: "absolute",
          top: "50%", // Center vertically
          left: "50%", // Center horizontally
          transform: "translate(-50%, -50%)", // Adjust position to center
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
      ];

  useEffect(() => {
    // Disable scrolling
    if (fixed) {
      document.body.style.overflow = "hidden";
    } else if (document.body.style.overflow === "hidden") {
      document.body.style.overflow = "";
    }

    const wait = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const loadTimeout = async () => {
      await wait(3);
      setLoaded(true);
    };

    loadTimeout();

    // Cleanup function to re-enable scrolling and clear the timer when the component unmounts
    return () => {
      loadTimeout();
      document.body.style.overflow = "";
    };
  }, [fixed]);

  return (
    <AnimatePresence>
      <LoadingOverlay fixed={fixed}>
        <motion.div
          css={fixedStyles}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: loaded ? 1 : 0, scale: loaded ? 1 : 0.8 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          <Dots />

          <div css={marginTopXl} />

          <LoadingText />
        </motion.div>
      </LoadingOverlay>
    </AnimatePresence>
  );
};

export default Loading;
