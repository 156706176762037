import { useContext, useEffect, useState } from "react";

import {
  IconDefinition,
  faCircleCheck,
  faEnvelopeCircleCheck,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Visible from "@Atoms/Visible";
import { Alert } from "@Components";
import { UserContext } from "@Contexts/UserContext";
import { Signed } from "@Types/services/documents";
import api from "@Utils/api";
import { useTheme } from "@emotion/react";

const PSFAlert = () => {
  const { isAuth } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [signed, setSigned] = useState<Signed>({} as Signed);
  const { t } = useTranslation();
  const { tone } = useTheme();
  const { trackEvent } = useMatomo();

  useEffect(() => {
    const signed = location.state?.signed as Signed;

    if (signed && signed._id) {
      setSigned(signed);
    }
  }, [location.state]);

  useEffect(() => {
    if (
      "verified" in signed &&
      signed.verified &&
      signed.exportType === "download"
    ) {
      setLoading(true);
      api
        .downloadDocument(signed._id)
        .then((response) => {
          trackEvent({
            category: "signable",
            action: "downloadDocument",
            customDimensions: [
              {
                id: 1, // Signable ID
                value: signed.initiativeId,
              },
              {
                id: 2, // Signable model
                value: "initiative",
              },
              {
                id: 3, // Newsletter opt-in
                value:
                  signed.subscribe && signed.verifySubscription
                    ? "true"
                    : "false",
              },
              {
                id: 4, // Logged in
                value: isAuth ? "true" : "false",
              },
            ],
          });
          fileDownload(response.data, "innn_it.pdf");
        })
        .catch(null)
        .finally(() => setLoading(false));
    }
  }, [isAuth, signed, trackEvent]);

  const CONTENT: {
    [key in Signed["status"]]?: {
      icon: IconDefinition;
    };
  } = {
    created: {
      icon: faEnvelopeCircleCheck,
    },
    repeated: {
      icon: faCircleExclamation,
    },
    verified: {
      icon: faCircleCheck,
    },
  };

  const getString = (type: "title" | "text") => {
    if (!signed || Object.keys(signed).length === 0) {
      return "";
    }

    let string = `pages.psf.alerts.${tone}`;

    if (signed && "exportType" in signed) {
      string += "download" == signed?.exportType ? ".download" : ".post";
    }

    string += `.${signed.status}`;
    string += `.${type}`;

    return t(string);
  };

  return (
    <section>
      <Visible when={signed && CONTENT[signed.status]}>
        <Alert
          icon={CONTENT[signed.status]?.icon}
          title={getString("title")}
          text={loading ? (t("common.loading") as string) : getString("text")}
          closeButton={true}
        />
      </Visible>
    </section>
  );
};

export default PSFAlert;
