import { useEffect, useState } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Button, Heading, UpdateCard } from "@Components";
import {
  colorSecondary,
  marginBottomLg,
  marginBottomXl,
  marginTopLg,
} from "@Styles";
import { Update } from "@Types/services/post";
import api from "@Utils/api";
import { SignablePageElementProps } from ".";

const InfoTab = ({ signable }: SignablePageElementProps) => {
  const { tone } = useTheme();

  const [lastUpdate, setLastUpdate] = useState<Update>({} as Update);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const lastUpdateStyle: CSSObject[] = [marginBottomXl, marginTopLg];
  const lastUpdateTitleStyle: CSSObject[] = [
    colorSecondary(tone),
    marginBottomLg,
  ];

  useEffect(() => {
    if (!signable._id) return;

    api
      .getUpdates(signable._id, { limit: 1 })
      .then((response) => {
        const updates = response.data;
        if (updates.length > 0) {
          setLastUpdate(updates[0]);
        }
      })
      .catch(() => null);
  }, [signable]);
  return (
    <>
      <section css={marginBottomLg}>{parse(signable.content)}</section>

      {lastUpdate._id && (
        <>
          <hr />
          <section css={lastUpdateStyle}>
            <Heading
              scale={4}
              aria-label="2"
              css={lastUpdateTitleStyle}
            >
              {t("pages.signable.updates.lastUpdate.title")}
            </Heading>

            <UpdateCard
              signableTitle={signable.title}
              update={lastUpdate}
            />
            <Button
              as={Link}
              fullWidth={{ default: true }}
              label={t("pages.signable.updates.lastUpdate.more")}
              onClick={() => navigate(`/${signable.name}/updates`)}
              to={`/${signable.name}/updates`}
              variant="outline"
            />
          </section>
        </>
      )}
    </>
  );
};

export default InfoTab;
