import React, { useEffect, useRef, useState } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { bgGradient } from "../styles/backgrounds";
import { fontSizeMd, fontSizeXl } from "../styles/fontSizes";
import { fontWeightBold, fontWeightMedium } from "../styles/fontWeights";
import { marginZero } from "../styles/spacers";
import { colorPrimary, colorSecondary } from "../styles/textColors";
import colors from "../variables/colors";
import { Container } from "./Container";
import { Body } from "./typography";

interface Props {
  /** Displays the animated arc. */
  displayArc?: boolean;
  /** Label behind the number. Can be simple text or HTML. */
  label?: string | React.ReactNode;
  /** Arc size. */
  max: number;
  /** Value to be filled in the arc. */
  value: number;
}

const gaugeBorderRadius = "50%/100% 100% 0 0";
const gaugeWidth = "100%";
const gaugeArcWidth = "12.5%";

/**
 * Animated gauge for values with a goal.
 */
const Gauge = ({ displayArc = true, label, max = 0, value = 0 }: Props) => {
  const { tone } = useTheme();

  const { i18n } = useTranslation();

  const ref = useRef<HTMLDivElement | null>(null);

  const [count, setCount] = useState(0);

  const [levelRotate, setLevelRotate] = useState("-0.5turn");

  if (max < value) max = value;

  const increment = value / (5000 / 100);

  const gaugeStyle: CSSObject = {
    position: "relative",
    textAlign: "center",
    zIndex: "1",
  };

  const arcBackgroundStyle: CSSObject = {
    aspectRatio: "2",
    backgroundColor: colors.gray3,
    borderRadius: gaugeBorderRadius,
    margin: "auto",
    maxWidth: "19.5rem",
    overflow: "hidden",
    position: "relative",
    width: gaugeWidth,
    zIndex: "-1",

    "&:after": {
      backgroundColor: colors.white,
      borderRadius: gaugeBorderRadius,
      bottom: "0",
      content: "''",
      display: "block",
      position: "absolute",
      left: gaugeArcWidth,
      right: gaugeArcWidth,
      top: `calc(${gaugeArcWidth} * 2)`,
    },
  };

  const arcForegroundStyle: CSSObject = {
    ...bgGradient(tone),
    bottom: "0",
    left: "0",
    position: "absolute",
    right: "0",
    top: "0",
    transform: `rotate(${levelRotate})`,
    transformOrigin: "center bottom",
    transition: "transform 5s ease-in-out",
  };

  const valueStyle: CSSObject = {
    ...colorSecondary(tone),
    ...marginZero,
    ...fontSizeXl,
    ...fontWeightMedium,
  };

  useEffect(() => {
    if (!value) {
      return;
    }

    let levelCount = 0;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          return;
        }

        const levelPercentage = (value / max) * 100;
        const levelRotate = -(0.5 - levelPercentage / 200) + "turn";
        setLevelRotate(levelRotate);

        const updateCount = () => {
          levelCount += increment;
          setCount(Math.floor(levelCount));

          if (levelCount <= value) {
            setTimeout(updateCount, 99);
          } else {
            setCount(value);
          }
        };

        updateCount();
        observer.disconnect();
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [value, max, increment]);

  return (
    <div
      css={gaugeStyle}
      ref={ref}
    >
      {displayArc && (
        <div css={arcBackgroundStyle}>
          <div css={arcForegroundStyle} />
        </div>
      )}

      <Container
        css={{ margin: "auto", marginTop: displayArc ? "-5.5rem" : "0" }}
      >
        <Body css={valueStyle}>
          {new Intl.NumberFormat(i18n.language).format(count)}
        </Body>

        <Body css={[fontWeightBold, fontSizeMd, marginZero]}>{label}</Body>

        <Body
          css={[fontWeightMedium, fontSizeXl, marginZero, colorPrimary(tone)]}
        >
          innn.it
        </Body>
      </Container>
    </div>
  );
};

export default Gauge;
