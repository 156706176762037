import { type CSSObject, useTheme } from "@emotion/react";
import { gapLg } from "@Styles";
import type { RadioControlGroupProps } from "@Types";
import { colors } from "@Variables";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import InputRadio from "../inputs/Input.Radio";
import FormController from "./Form.Control";

const RadioControlGroup: FC<RadioControlGroupProps> = ({
  name,
  options,
  required = false,
  showErrors = true,
  containerStyles,
}) => {
  const { control } = useFormContext();
  const { colors: swatchColors } = useTheme();

  const primaryColor = swatchColors.primary.main;

  const radioContainerStyle: CSSObject = {
    display: "inline-flex",
    width: "100%",
    alignItems: "center",
    justifyItems: "center",
    flexWrap: "wrap",
    alignContent: "center",
    flexDirection: "row",
    ...gapLg,
    ...containerStyles,
  };

  const inputRadioStyle = (hasError: boolean): CSSObject => ({
    borderColor: hasError ? colors.danger : primaryColor,
    color: hasError ? colors.danger : colors.black,
    marginRight: "0.5rem",
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { onChange, value = "" }, fieldState: { error } }) => {
        return (
          <FormController
            error={error}
            showErrors={showErrors}
          >
            <div css={radioContainerStyle}>
              {options.map((option, index) => (
                <InputRadio
                  key={`${option?.value}-${index}`}
                  label={option.label}
                  value={option.value}
                  name={name}
                  checked={value === option.value}
                  onChange={onChange}
                  css={inputRadioStyle(!!error?.message)}
                />
              ))}
            </div>
          </FormController>
        );
      }}
    />
  );
};

export default RadioControlGroup;
