import type {
  UserCreateParams,
  UserTokenParams,
} from "@Types/services/typeServiceParams";
import type { User } from "@Types/services/user";
import BaseService from "./base.service";
import apiRoutes from "./serviceRoutes";
import { AxiosResponse } from "axios";

export class UsersService extends BaseService {
  private static instance: UsersService;
  private routes = apiRoutes.users;

  // Singleton instance getter
  public static getInstance(): UsersService {
    if (!UsersService.instance) {
      UsersService.instance = new UsersService();
    }

    return UsersService.instance;
  }

  /**
   * Change the user's password.
   * @param id User ID
   * @param params Object containing newPassword and current password
   */
  public async changePassword(
    id: string,
    params: { newPassword: string; password: string },
  ) {
    return this.patch(this.routes.changePassword(id), params);
  }

  /**
   * Create a new user.
   * @param params Object containing user details
   */
  public async createUser(params: UserCreateParams = {}) {
    return this.post<UserCreateParams, User>(this.routes.createUser, params);
  }

  /**
   * Generate a token for user actions.
   * @param params Object containing email, type (optional), and URL (optional)
   */
  public async generateToken(params: UserTokenParams) {
    return this.post(this.routes.generateToken, params);
  }

  /**
   * Get user details by ID.
   * @param id User ID
   */
  public async getUser(id: string) {
    return this.get(this.routes.getUser(id));
  }

  /**
   * Delete a user by ID.
   * @param id User ID
   */
  public async deleteUser(id: string) {
    return this.delete(this.routes.deleteUser(id));
  }

  /**
   * Login a user.
   * @param params Object containing email, password (optional), and token (optional)
   */
  public async login(params: {
    email: string;
    password?: string;
    token?: string;
  }): Promise<AxiosResponse<any, any>> {
    return this.post(this.routes.login, params);
  }

  /**
   * Logout the current user.
   */
  public async logout(): Promise<AxiosResponse<any, any>> {
    return this.post(this.routes.logout, null);
  }

  /**
   * Get details of the current logged-in user.
   */
  public async me(): Promise<AxiosResponse<any, any>> {
    return this.get(this.routes.me);
  }

  /**
   * Set a new password using a token.
   * @param id User ID
   * @param params Object containing password and token
   */
  public async setPassword(
    id: string,
    params: { password: string; token: string },
  ) {
    return this.post(this.routes.setPassword(id), params);
  }
}
