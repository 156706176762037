import { type MouseEvent, useState } from "react";

import { CSSObject } from "@emotion/serialize";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { faLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import { useTheme } from "@emotion/react";
import { gapMd, gapSm, marginBottomZero } from "@Styles";
import { breakpointUp, colors } from "../variables";
import Visible from "./atoms/Visible";
import { ConditionalWrapper } from "./ConditionalWrapper";
import { Label, Link } from "./typography";

interface ContentProps {
  initiative: string;
  petition: string;
}

interface SocialMediaShareContent {
  icon: IconProp;
  label: string;
  name: string;
  title: string;
  url: string;

  display: boolean;
}

export interface SocialMediaShareBasicProps {
  /** Background color. */
  background?: "dark" | "light";
  /** Context. Used on tracking. */
  context?: string;
  /** Size of the component. */
  size?: "md" | "sm";
  /** URL to be shared. */
  url: string;
  /** Component style variant. */
  variant?: "primary" | "secondary";
  /** Border color. */
  transparent?: boolean;
  /** border transparency */
  hasBorder?: boolean;
}

export interface SocialMediaShareProps extends SocialMediaShareBasicProps {
  /** Signable title, to be used on the share text. */
  signableTitle: string;
  /** Signable title, that will define the text to be shared. */
  signableType: "petition" | "initiative";
  /** Include label on the share buttons. */
  includeLabel?: boolean;

  socialMediaList?: string[];

  injectSocialMediaUrl?: boolean;
}

export interface SocialMediaShareItemProps extends SocialMediaShareBasicProps {
  content: SocialMediaShareContent;
  includeLabel?: boolean;
}

const iconSizeStyles: {
  [key in SocialMediaShareBasicProps["size"] as string]: CSSObject;
} = {
  md: {
    fontSize: "clamp(24px, 24px + 1.39vw, 38px)",
    height: "clamp(56px, 56px + 1.58vw, 72px)",
    width: "clamp(56px, 56px + 1.58vw, 72px)",
  },
  sm: {
    fontSize: "clamp(16px, 16px + 1.39vw, 24px)",
    height: "clamp(40px, 40px + 1.58vw, 48px)",
    width: "clamp(40px, 40px + 1.58vw, 48px)",
  },
};

const socialShareContainer: CSSObject = {
  display: "flex",
  flexWrap: "wrap",
};

const containerStyle: CSSObject = {
  ...gapSm,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  flexDirection: "column",
  textDecorationLine: "none",
};

const socialMediaWrapper: CSSObject[] = [
  socialShareContainer,
  gapMd,
  {
    alignItems: "center",
    justifyContent: "center",
  },
  {
    [breakpointUp["md"]]: {
      justifyContent: "initial",
    },
  },
];

/**
 * Widget to share a signable on different media.
 */
export const SocialMediaShare = ({
  context = "",
  variant = "primary",
  signableTitle,
  signableType,
  size = "md",
  url,
  includeLabel = true,
  transparent = false,
  socialMediaList,
  injectSocialMediaUrl,
}: SocialMediaShareProps) => {
  const { t } = useTranslation();

  const CONTENT: ContentProps = {
    initiative: t("components.socialMediaShare.shareText.initiative", {
      signableTitle,
      url,
    }),
    petition: t("components.socialMediaShare.shareText.petition", {
      signableTitle,
      url,
    }),
  };

  const shareText = CONTENT?.[signableType] || "";

  const socialMediaShareContents: SocialMediaShareContent[] = [
    {
      icon: faInstagram,
      label: t("components.socialMediaShare.media.instagram.label"),
      name: "instagram",
      title: t("components.socialMediaShare.media.instagram.title"),
      url: injectSocialMediaUrl
        ? "https://www.instagram.com/innn.it/"
        : "https://www.instagram.com/innn.it/",
      display: false,
    },
    {
      icon: faFacebook,
      label: t("components.socialMediaShare.media.facebook.label"),
      name: "facebook",
      title: t("components.socialMediaShare.media.facebook.title"),
      url: injectSocialMediaUrl
        ? "https://www.facebook.com/HierKannstDuMitbestimmen"
        : `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      display: true,
    },
    {
      icon: faXTwitter,
      label: t("components.socialMediaShare.media.twitter.label"),
      name: "twitter",
      title: t("components.socialMediaShare.media.twitter.title"),
      url: injectSocialMediaUrl
        ? "https://x.com/innn_it"
        : `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            shareText.replace("{{site_url}}", url),
          )}`,
      display: true,
    },
    {
      icon: faWhatsapp,
      label: t("components.socialMediaShare.media.whatsapp.label"),
      name: "whatsapp",
      title: t("components.socialMediaShare.media.whatsapp.title"),
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        shareText.replace("{{site_url}}", url),
      )}`,
      display: true,
    },
    {
      icon: faEnvelope,
      label: t("components.socialMediaShare.media.email.label"),
      name: "email",
      title: t("components.socialMediaShare.media.email.title"),
      url: `mailto:?subject=innn.it&body=${encodeURIComponent(
        shareText.replace("{{site_url}}", url),
      )}`,
      display: true,
    },
    {
      icon: faLink,
      label: t("components.socialMediaShare.media.link.label"),
      name: "link",
      title: t("components.socialMediaShare.media.link.title"),
      url: "",
      display: true,
    },
    {
      name: "tiktok",
      label: t("footer.socialMedia.tiktok"),
      icon: faTiktok,
      title: t("components.socialMediaShare.media.tiktok.title"),
      url: injectSocialMediaUrl
        ? "https://www.tiktok.com/@innn.it"
        : "https://tiktok.com/@innn.it",
      display: false,
    },
  ];

  const socialMediaShareContentsFiltered: SocialMediaShareContent[] =
    socialMediaList?.length
      ? socialMediaShareContents.filter((item) => {
          item.display = true;
          return socialMediaList?.includes(item.name);
        })
      : [];

  const list =
    socialMediaList?.length && socialMediaList?.length > 0
      ? socialMediaShareContentsFiltered
      : socialMediaShareContents.filter((item) => item.display === true);

  return (
    <div css={socialMediaWrapper}>
      {list.map((content: SocialMediaShareContent) => {
        return (
          <SocialMediaShareItem
            key={content.name}
            content={content}
            context={context}
            size={size}
            url={url}
            variant={variant}
            includeLabel={includeLabel}
            transparent={transparent}
          />
        );
      })}
    </div>
  );
};

const SocialMediaShareItem = ({
  content,
  context = "",
  size = "md",
  url,
  variant = "primary",
  includeLabel = true,
  transparent = false,
}: SocialMediaShareItemProps) => {
  const { colors: swatchColors } = useTheme();

  const [linkText, setLinkText] = useState({ value: url, copied: false });
  const iconColor = swatchColors[variant].main;

  const { trackEvent } = useMatomo();

  const onCopy = () => {
    setLinkText({ value: linkText.value, copied: true });
  };

  const labelColor = transparent ? iconColor : colors.white;
  const backgroundColor = transparent ? "transparent" : colors.white;
  const borderWidth = transparent ? "1px" : "0px";

  const iconContainer: CSSObject = {
    alignItems: "center",
    backgroundColor,
    borderRadius: "100%",
    color: iconColor,
    display: "flex",
    justifyContent: "center",
    margin: 0,
    borderColor: iconColor,
    borderWidth,
    borderStyle: "solid",
  };

  const labelStyle: CSSObject = {
    color: labelColor,
    cursor: "pointer",
    ...marginBottomZero,
  };

  const handleLinkClick = (
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
  ): void => {
    trackEvent({
      category: context,
      action: "share",
      name: content.name,
    });

    if (content.name === "link") {
      event.preventDefault();
    }
  };

  return (
    <ConditionalWrapper
      condition={content.name === "link"}
      wrapper={(children: JSX.Element): JSX.Element => (
        <CopyToClipboard
          text={linkText.value || ""}
          onCopy={() => onCopy?.()}
        >
          {children}
        </CopyToClipboard>
      )}
    >
      <div css={[containerStyle]}>
        <Link
          css={containerStyle}
          href={content.url}
          onClick={handleLinkClick}
          target="_blank"
        >
          <div css={[iconSizeStyles[size], iconContainer]}>
            <FontAwesomeIcon
              aria-label={content.label}
              fixedWidth
              icon={content.icon}
            />
          </div>

          <Visible when={includeLabel}>
            <Label css={labelStyle}>{content.title}</Label>
          </Visible>
        </Link>
      </div>
    </ConditionalWrapper>
  );
};
