import { ReactNode } from "react";

import { useTheme } from "@emotion/react";

import { tabButtonStyle, tabStyle } from "@Appearances/components/styleTab";
import { DivisionComponentProps } from "@Types/components/pageProps";

type OmitDivProps = Omit<DivisionComponentProps, "children" | "content" | "id">;

export interface TabProps<T = string> extends OmitDivProps {
  /** Tab to be open when the whole component is loaded. */
  active?: boolean;
  children?: ReactNode;
  /** ID of the tab. */
  id: T;
  /** Label to be displayed in the tab list. */
  label: string;
  /** Content to be displayed instead of the label. */
  content?: ReactNode | ReactNode[];
  /** Action to be performed on tab button click. */
  onTabChange?: (id: T) => void;
  /** Path to be used to activate tab. */
  path?: string;
  /** Function to display the tab, defined by parent. */
  switchToTab?: (id: T) => void;
}

/** Content block for content navigator with tabs. */
const Tab = <T extends string>({
  id,
  active,
  label,
  content,
  onTabChange: onClick,
  switchToTab,
  ...props
}: TabProps<T>) => {
  const { colors, tone } = useTheme();

  const handleClick = () => {
    onClick?.(id);
    switchToTab?.(id);
  };

  return (
    <div
      key={label}
      css={tabStyle}
      {...props}
    >
      <button
        aria-controls={id}
        aria-selected="true"
        id={`${id}-tab`}
        role="tab"
        onClick={handleClick}
        css={tabButtonStyle({ content, active, colors, tone })}
      >
        {content ? content : <span>{label}</span>}
      </button>
    </div>
  );
};

export default Tab;
