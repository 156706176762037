import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useTheme } from "@emotion/react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

import {
  StyledLink,
  containerCSS,
  extraContentItemCSS,
  headerStyle,
  itemCSS,
  itemLinkCSS,
  itemSubmenuStyle,
  menuCSS,
  submenuIconStyle,
} from "@Appearances/components/stylesMenu";
import { Visible } from "@Atoms";
import type {
  MenuItemContentProps,
  MenuItemProps,
  MenuProps,
} from "@Types/components/typeMenu";
import { navBarHeight } from "@Variables";
import { bgGray1, fontWeightBold, marginBottomZero, paddingXMd } from "@Styles";
import { useModalContext } from "@Contexts/ModalsContext";
import { useUserContext } from "@Contexts/UserContext";

const MenuItem = ({
  blank,
  label,
  url,
  items,
  tone,
  setMenuOpen,
  skipTone,
  loginModal,
}: MenuItemProps) => {
  const { pathname, hash } = useLocation();
  const { colors } = useTheme();
  const submenuRef = useRef<HTMLUListElement>(null);

  const [submenuHeight, setSubmenuHeight] = useState(0);
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const { isAuth } = useUserContext();
  const { toggleModal } = useModalContext();

  const itemUrl = !url || items?.length ? "" : url;

  useEffect(() => {
    if (submenuRef.current) {
      setSubmenuHeight(submenuRef.current.scrollHeight);
    }
  }, [submenuRef, setSubmenuHeight]);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): void => {
    if (loginModal && !isAuth) {
      event.preventDefault();
      toggleModal("getUser");
    } else if (!itemUrl) {
      event.preventDefault();
      setSubmenuOpen((prev) => !prev);
    } else {
      setMenuOpen(false);
    }
  };

  const isLinkActive = useMemo(
    () => (url === pathname + hash ? "active" : ""),
    [pathname, hash],
  );

  return (
    <li
      css={itemCSS}
      key={label}
    >
      <StyledLink
        css={itemLinkCSS({ tone, skipTone, colors })}
        className={isLinkActive}
        onClick={handleMenuClick}
        rel={blank ? "noopener noreferrer" : ""}
        target={blank ? "_blank" : ""}
        to={itemUrl}
        color="primary"
      >
        {label}

        {items?.length && (
          <FontAwesomeIcon
            className={submenuOpen ? "rotate" : ""}
            css={submenuIconStyle}
            fixedWidth
            icon={faChevronDown}
            size="xl"
          />
        )}
      </StyledLink>

      {items?.length && (
        <ul
          css={itemSubmenuStyle(submenuHeight)}
          className={submenuOpen ? "open" : "close"}
          ref={submenuRef}
        >
          {items?.map((item: MenuItemContentProps) => (
            <MenuItem
              blank={item.blank}
              key={item.label}
              label={item.label}
              url={item.url}
              tone={item.tone}
              items={item.items}
              setMenuOpen={setMenuOpen}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

/**
 * Dropdown menu for navigation bar.
 */

export const Menu = ({
  content,
  menuOpen,
  setMenuOpen,
  preContent,
  postContent,
  header,
  showHeaderBackground,
  menuTogglerRef,
}: MenuProps) => {
  const { colors } = useTheme();
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node;
      const clickedInsideMenu = menuRef.current?.contains(target);
      const clickedInsideToggler = menuTogglerRef?.current?.contains(target);

      // Close the menu only if clicked outside both Menu and MenuToggler
      if (!clickedInsideMenu && !clickedInsideToggler) {
        setMenuOpen(false);
      }
    },
    [setMenuOpen, menuTogglerRef],
  );

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuOpen, handleClickOutside]);

  return (
    <div
      css={containerCSS(navBarHeight)}
      className={menuOpen ? "open" : "close"}
      ref={menuContainerRef}
    >
      <ul
        css={menuCSS}
        ref={menuRef}
      >
        {header && (
          <li
            css={[
              ...headerStyle,
              showHeaderBackground
                ? bgGray1
                : [
                    { borderBottom: `1px solid ${colors.label[50]}` },
                    fontWeightBold,
                    marginBottomZero,
                    paddingXMd,
                  ],
            ]}
          >
            <span>{header}</span>

            <a
              css={{ color: colors.primary.main }}
              href="#"
              onClick={(event) => {
                event.preventDefault();
                setMenuOpen(false);
              }}
            >
              <FontAwesomeIcon
                icon={faChevronUp}
                fixedWidth
              />
            </a>
          </li>
        )}

        <Visible when={preContent?.length}>
          {React.Children.map(preContent, (child) => {
            if (React.isValidElement(child)) {
              return (
                <li css={[extraContentItemCSS, child.props.css]}>
                  {React.cloneElement(child)}
                </li>
              );
            }
          })}
        </Visible>

        {content.map((item) => (
          <MenuItem
            {...item}
            key={item.label}
            setMenuOpen={setMenuOpen}
          />
        ))}

        <Visible when={postContent?.length}>
          {React.Children.map(postContent, (child) => {
            if (React.isValidElement(child)) {
              return (
                <li css={[extraContentItemCSS, child.props.css]}>
                  {React.cloneElement(child)}
                </li>
              );
            }
          })}
        </Visible>
      </ul>
    </div>
  );
};
