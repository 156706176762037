import { faBoxHeart } from "@fortawesome/pro-regular-svg-icons";
import getDonationUrl from "@Stylize/helpers/getDonationUrl";
import { useTranslation } from "react-i18next";

import {
  BodyLg,
  Button,
  Column,
  Container,
  Heading,
  Illustration,
  RadioControlGroup,
  Row,
  TextControl,
} from "@Components";

import {
  bodyStyles,
  formContainerStyle,
  formInputStyle,
  headingStyle,
  illustrationStyles,
  rowStyle,
  textContainer,
} from "@Appearances/pages/styleHomeDonation";
import { zodResolver } from "@hookform/resolvers/zod";
import { paddingRightLg2xl, paddingRightXlZero } from "@Styles";
import {
  Amount,
  type DonationFormValues,
  type RadioControlOption,
  type SharedHomeProps,
} from "@Types";
import schema from "@Validations/home.donation.schema";
import { FormProvider, useForm } from "react-hook-form";

const HomeSignableDonation = (props: SharedHomeProps) => {
  const methods = useForm<DonationFormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: Amount.FIFTY,
      customValue: "",
    },
  });

  const { handleSubmit, setValue } = methods;
  const { t } = useTranslation();

  //#region Helpers
  const amounts: RadioControlOption[] = [
    {
      label: `${Amount.FIVE}€`,
      value: Amount.FIVE,
    },
    {
      label: `${Amount.TEN}€`,
      value: Amount.TEN,
    },
    {
      label: `${Amount.TWENTY_FIVE}€`,
      value: Amount.TWENTY_FIVE,
    },
    {
      label: `${Amount.FIFTY}€`,
      value: Amount.FIFTY,
    },
    {
      label: (
        <TextControl
          type="number"
          name={"customValue"}
          placeholder={`${t("common.donation.otherAmount")}`}
          required
          onFocus={() => setValue("amount", Amount.OTHER)} // Add onFocus event here
        />
      ),
      value: Amount.OTHER,
    },
  ];
  //#endregion

  const onSubmit = (data: DonationFormValues) => {
    const amount =
      data.amount === Amount.OTHER ? data.customValue : data.amount;

    const url = getDonationUrl({
      context: "general",
      source: "homeBanner",
      amount: Number(amount),
    });

    window.location.href = url;
  };

  return (
    <section {...props}>
      <Container size="lg">
        <Row css={rowStyle}>
          <Column
            span={{ default: 8, md: 6 }}
            order={{ md: 1, lg: 2 }}
          >
            <Illustration
              name="donation"
              size="lg"
              css={illustrationStyles}
            />
          </Column>

          <Column span={{ default: 12, md: 6 }}>
            <div css={textContainer}>
              <div css={[paddingRightLg2xl, paddingRightXlZero]}>
                <Heading
                  css={headingStyle}
                  scale={1}
                  variant="display"
                  color="primary"
                >
                  {t("pages.home.donation.title")}
                </Heading>

                <BodyLg css={bodyStyles}>
                  {t("pages.home.donation.text")}
                </BodyLg>
              </div>
              <FormProvider {...methods}>
                <form
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div css={formContainerStyle}>
                    <div css={formInputStyle}>
                      <RadioControlGroup
                        name="amount"
                        options={amounts}
                        required
                      />
                    </div>
                  </div>

                  <Button
                    type="submit"
                    fullWidth={{ default: true }}
                    label={t("pages.home.donation.buttonLabel")}
                    icon={faBoxHeart}
                    variant="primary"
                  />
                </form>
              </FormProvider>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  );
};

export default HomeSignableDonation;
