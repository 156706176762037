import { useTheme } from "@emotion/react";
import { Tone } from "../variables";
import themes from "@Variables/themes";

type LogoSVGProps = {
  tone?: Tone;
};

const LogoSVG = ({ tone }: LogoSVGProps) => {
  const { tone: swatchTone } = useTheme();

  const theTone = tone || swatchTone;

  const primaryColor = themes[theTone].colors.primary;

  const secondaryColor = themes[theTone].colors.secondary;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="color"
      x={0}
      y={0}
      viewBox="100 0 1200 580"
    >
      <title>innn.it</title>
      <path
        d="M834.9 256.6c0-28.1-22.9-53-50.9-53-28.1 0-50.9 24.9-50.9 53v60c0 40.3-32.8 73.1-73.1 73.1s-73.1-32.8-73.1-73.1v-29.2c0-28.1-22.9-50.9-50.9-50.9-28.1 0-50.9 22.9-50.9 50.9v60c0 40.3-32.8 73.1-73.1 73.1s-73.1-32.8-73.1-73.1v-29.1c0-28.1-22.9-50.9-50.9-50.9-28.1 0-50.9 22.9-50.9 50.9v102.2h-46.3V318.4c0-53.6 43.6-97.2 97.2-97.2s97.2 43.6 97.2 97.2v29.1c0 14.8 12 26.9 26.9 26.9 14.8 0 26.9-12 26.9-26.9v-60c0-53.6 43.6-97.2 97.2-97.2s97.2 43.6 97.2 97.2v29.2c0 14.8 12 26.9 26.9 26.9s26.9-12 26.9-26.9v-60c0-53.6 43.6-97.2 97.2-97.2s97.2 43.6 97.2 97.2v96.1h-46.3v-96.2z"
        fill={primaryColor}
      />
      <path
        fill={primaryColor}
        d="M921.5 227.3h46.1v193.3h-46.1zM104 227.3h46.1v193.3H104zM127.3 153.1c-15.9 0-27.3 11.9-27.3 27.3 0 14.9 10.9 26.8 26.3 26.8 17.4 0 28.3-11.9 27.8-26.8-.1-15.4-10.5-27.3-26.8-27.3zM944.8 153.1c-15.9 0-27.3 11.9-27.3 27.3 0 14.9 10.9 26.8 26.3 26.8 17.4 0 28.3-11.9 27.8-26.8-.1-15.4-10.5-27.3-26.8-27.3z"
      />
      <path
        fill={secondaryColor}
        d="M859 372.8c-17.4 0-28.3 11.9-27.8 26.8 0 15.4 10.4 27.3 26.8 27.3 15.9 0 27.3-11.9 27.3-27.3 0-14.9-10.9-26.8-26.3-26.8z"
      />
      <path
        fill={primaryColor}
        d="M1100 273.4v-46.1h-46.1v-67.9h-46.1v194c0 40.3 32.8 73.1 73.1 73.1h19v-46.3h-19c-14.8 0-27.1-12-27.1-26.9v-80h46.2z"
      />
      <path
        fill="none"
        d="M0 0h1200v580H0z"
      />
    </svg>
  );
};

export default LogoSVG;
