import { BannerIllustration } from "@Components";
import routes from "@Configs/routes";
import { marginTop2xl, marginTopLg } from "@Styles";
import type {
  InitialPetitions,
  StartPetitionBannerProps,
} from "@Types/pages/typeUserProfile";
import { useTranslation } from "react-i18next";
import MyPetitions from "./MyPetitions";

const MyPetitionsPage = ({ initialData }: InitialPetitions) => {
  const { t } = useTranslation();

  return (
    <>
      {initialData?.length ? (
        <section css={marginTopLg}>
          <MyPetitions initialData={initialData} />
        </section>
      ) : (
        <section css={marginTop2xl}>
          <StartPetitionBanner
            buttonLabel={t(
              "pages.userAccount.petition.startPetition.banner.buttonLabel",
            )}
            title={t("pages.userAccount.petition.startPetition.banner.title")}
            content={t(
              "pages.userAccount.petition.startPetition.banner.content",
            )}
          />
        </section>
      )}
    </>
  );
};

const StartPetitionBanner = (props: StartPetitionBannerProps) => {
  return (
    <BannerIllustration
      buttonLink={routes.petition.start}
      illustration="start"
      {...props}
    />
  );
};

export default MyPetitionsPage;
