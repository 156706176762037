import { useContext, useEffect, useState } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { ModalsContext } from "@Contexts/ModalsContext";
import {
  bgGradient,
  marginBottomLg,
  marginBottomMd,
  marginBottomZero,
  marginRightLg,
  paddingXLg,
  paddingXMd,
  paddingYLg,
} from "@Styles";
import { breakpointUp, navBarHeight } from "@Variables";
import { ValidationMessage } from "../helpers/validation";
import { Heading } from "./Heading";
import Icon from "./Icon";
import Modal from "./Modal";
import { BodyLg } from "./typography";
import UserForm, { UserFormFields } from "./UserForm";
import { useEmailContext } from "@Contexts/EmailContext";
import { EmailService } from "@Services/email.service";
/**
 * Creates a Newsletter modal that can be triggered open from an outside element or when the URL contains #newsletter.
 */
const NewsletterModal = () => {
  const { tone } = useTheme();

  const { hash } = useLocation();

  const navigate = useNavigate();

  const { openModal, toggleModal } = useContext(ModalsContext);

  const { email } = useEmailContext();

  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  const [user, setUser] = useState<UserFormFields>({
    firstName: "",
    lastName: "",
    email: email || "",
    country: "DE",
    postalCode: "",
    postalCodeDisplay: "",
    location: "",
    locationDisplay: "",
  });

  useEffect(() => {
    if (hash && "#newsletter" == hash) {
      if ("newsletter" != openModal) {
        toggleModal("newsletter");
      } else {
        navigate(window.location.pathname);
      }
    }
  }, [hash, navigate, openModal, toggleModal]);

  useEffect(() => {
    if (email && email !== user.email) {
      setUser((prevUser) => ({
        ...prevUser,
        email,
      }));
    }
  }, [email, user.email]);

  const headerStyle: CSSObject[] = [marginBottomLg, { display: "flex" }];
  const iconContainerStyle: CSSObject[] = [
    marginBottomMd,
    marginRightLg,
    paddingYLg,
    {
      display: `${success ? "flex" : "none"}`,
      flexGrow: 0,

      [breakpointUp["md"]]: [
        marginBottomZero,
        {
          display: "block",
        },
      ],
    },
  ];

  const modalStyle: CSSObject[] = [
    bgGradient(tone, "light"),
    {
      top: navBarHeight,
      transform: "translate(-50%, 0%)",

      [breakpointUp["md"]]: {
        maxHeight: `calc(100vh - ${navBarHeight})`,
        width: "41.875rem",
      },
    },
  ];

  const modalContentStyle: CSSObject[] = [
    paddingXMd,
    {
      [breakpointUp["md"]]: [paddingXLg],
    },
  ];

  const titleStyle: CSSObject = {
    ...marginBottomMd,
  };

  const emailService = EmailService.getInstance();

  const handleFormSubmit = async (): Promise<ValidationMessage> => {
    try {
      await emailService.subscribeNewsletter({
        ...user,
        source: "newsletterBanner",
      });
      setSuccess(true);
      return { valid: true, message: "" };
    } catch {
      return {
        valid: false,
        message: t("components.userForm.validation.submit.error"),
      };
    }
  };

  return (
    <Modal
      id="newsletter"
      modalCss={modalStyle}
    >
      <div css={modalContentStyle}>
        <div css={headerStyle}>
          <div css={iconContainerStyle}>
            <Icon name="email" />
          </div>

          <div>
            <Heading
              scale={3}
              variant="display"
              css={titleStyle}
              color="primary"
            >
              {success
                ? t("components.newsletterModal.success.title")
                : t("components.newsletterModal.title")}
            </Heading>

            <BodyLg css={marginBottomZero}>
              {success
                ? t("components.newsletterModal.success.message")
                : t("components.newsletterModal.message")}
            </BodyLg>
          </div>
        </div>

        {!success && (
          <UserForm
            user={user}
            setUser={setUser}
            subscribe={true}
            onSubmit={handleFormSubmit}
          />
        )}
      </div>
    </Modal>
  );
};

export default NewsletterModal;
