import { Visible } from "@Atoms";
import { Button, ButtonContainer } from "@Components";
import { ButtonProps } from "@Components/Button";
import { ButtonContainerProps } from "@Components/ButtonContainer";
import styled from "@emotion/styled";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import {
  colorGray3,
  fontSizeLg,
  fontSizeSm,
  gapLg,
  gapZero,
  marginNegMd,
  paddingSm,
} from "@Styles";
import { SectionComponentProps } from "@Types/components/pageProps";
import { MetaData } from "@Types/services/typeServiceParams";

//#region PageList
type PageListProps = SectionComponentProps & {
  totalPages: number;
  currentPage: number;
  currentLoadingPage?: number;
  onPageChange: (page: number) => void;
};

const PageList = ({
  totalPages = 0,
  currentPage,
  currentLoadingPage,
  onPageChange,
}: PageListProps) => {
  // Calculate middle page range
  const getMiddlePages = () => {
    if (totalPages <= 3 && totalPages > 0) {
      // Return all pages if totalPages is 3 or less
      return Array.from({ length: totalPages - 1 }, (_, index) => index + 2);
    } else if (currentPage <= 3) {
      return [2, 3, 4]; // Early in pagination
    } else if (currentPage >= totalPages - 2) {
      return [totalPages - 3, totalPages - 2, totalPages - 1]; // Near the end
    } else {
      return [currentPage - 1, currentPage, currentPage + 1]; // Middle range
    }
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages && page !== currentPage) {
      onPageChange(page);
    }
  };

  return (
    <>
      <Button
        onClick={() => handlePageChange(1)}
        variant={"transcent"}
        active={currentPage === 1}
        size="sm"
        label={"1"}
        loading={currentLoadingPage === 1}
        rounded
      >
        <div css={fontSizeSm}>1</div>
      </Button>

      {currentPage > 3 && <Dots>...</Dots>}

      {getMiddlePages().map((page, index) => {
        return (
          <Button
            key={`page-${index}`}
            onClick={() => onPageChange(Number(page))}
            variant={"transcent"}
            active={page === currentPage}
            size="sm"
            label={String(page)}
            loading={currentLoadingPage === page}
            rounded
          >
            <div css={fontSizeSm}>{page}</div>
          </Button>
        );
      })}

      {currentPage < totalPages - 2 && <Dots>...</Dots>}

      <Button
        key={`page-${totalPages}`}
        onClick={() => onPageChange(totalPages)}
        variant={"transcent"}
        disabled={currentPage === totalPages}
        size="sm"
        label={String(totalPages)}
        loading={currentLoadingPage === totalPages}
        rounded
      >
        <div css={fontSizeSm}>{totalPages}</div>
      </Button>
    </>
  );
};
//#endregion PageList

//#region Pagination
export type PaginationProps = SectionComponentProps & {
  meta?: MetaData;
  loading: boolean;
  current: number;
  onPageChange: (page: number) => void;
};

const buttonContainerProps: ButtonContainerProps = {
  align: {
    default: "center",
  },
  direction: {
    default: "horizontal",
  },
  css: [gapLg],
};

const sharedButtonProps: ButtonProps = {
  iconOnly: true,
  label: "",
  loading: false,
  variant: "transparentSecondary",
  size: "sm",
};

const Pagination = ({
  meta,
  loading,
  current = -1,
  onPageChange,
  ...props
}: PaginationProps) => {
  const { currentPage = 1, totalPages = 0, totalCount = 0 } = meta || {};

  const showButtons =
    (totalPages > 1 || loading) && totalCount > (meta?.limit || 0);

  const loadingFirstPage = current === 1 && loading;
  const loadingLastPage = current === totalPages && loading;
  const currentLoadingPage = loading ? current : -1;

  return (
    <section {...props}>
      <Visible when={showButtons}>
        <ButtonContainer {...buttonContainerProps}>
          <Button
            disabled={currentPage === 1}
            icon={faChevronLeft}
            onClick={() => onPageChange(currentPage - 1)}
            css={[marginNegMd, gapZero]}
            loading={loadingFirstPage}
            {...sharedButtonProps}
          />

          <PageList
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={onPageChange}
            currentLoadingPage={currentLoadingPage}
          />

          <Button
            disabled={currentPage === totalPages}
            icon={faChevronRight}
            onClick={() => onPageChange(currentPage + 1)}
            css={[marginNegMd, gapZero]}
            loading={loadingLastPage}
            {...sharedButtonProps}
          />
        </ButtonContainer>
      </Visible>
    </section>
  );
};
//#endregion Pagination

export default Pagination;

const Dots = styled.span([colorGray3, paddingSm, fontSizeLg]);
