import { Column, Container, Row } from "@Components";
import type { CSSObject } from "@emotion/react";
import { marginBottom3xl } from "@Styles";
import { UpdatePassword } from "./components/update-password";
import { UpdateProfile } from "./components/update-profile";

const containerStyle: CSSObject = {
  ...marginBottom3xl,
};

const MyProfilePage = () => {
  return (
    <section css={containerStyle}>
      <Container size="lg">
        <Row>
          <Column span={{ lg: 6, md: 7, default: 12 }}>
            <UpdateProfile />

            {/* <UpdateEmail /> */}

            <UpdatePassword />
          </Column>
        </Row>
      </Container>
    </section>
  );
};

export default MyProfilePage;
