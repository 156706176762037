import { useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { faPenToSquare, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { AttachmentImage, Column, FormControl, Row } from "@Components";
import { faArrowUpFromBracket } from "@fortawesome/pro-regular-svg-icons";
import { displayFlex } from "@Styles/displays";
import { fontWeightBold } from "@Styles/fontWeights";
import { marginBottomMd, paddingXZero, paddingZero } from "@Styles/spacers";
import { breakpointValues } from "@Variables/breakpoints";
import FieldHeading from "../components/FieldHeading";
import { PetitionStepProps } from "../utils/types";

const StepFive = ({
  formErrors,
  handleImageUpload,
  initiative,
  isSaving,
  setFieldHelp,
}: PetitionStepProps) => {
  const [validation, setValidation] = useState<{ featuredImage: boolean }>({
    featuredImage: false,
  });

  useEffect(() => {
    // if we have any new field in this step later, we add it to the array.
    (["featuredImage"] as "featuredImage"[]).forEach((fieldName) => {
      if (formErrors[fieldName] && !validation[fieldName]) {
        setValidation({ ...validation, [fieldName]: true });
      }
    });
  }, [formErrors.featuredImage, validation]);

  useEffect(() => {
    if (formErrors.featuredImage) {
      // the form is submitted, all fields should have validations.
      setValidation({ featuredImage: true });
    }
  }, [formErrors]);

  const inputRef = useRef(null);

  const { t } = useTranslation();

  const CONTENT = {
    featuredImage: {
      placeholder: t(
        "components.startPetitionForm.fields.featuredImage.placeholder",
      ),
      heading: t("components.startPetitionForm.fields.featuredImage.heading"),
      description: t(
        "components.startPetitionForm.fields.featuredImage.description",
      ),
      formatDescription: t(
        "components.startPetitionForm.fields.featuredImage.formatDescription",
      ),
      caption: t("components.startPetitionForm.fields.featuredImage.caption"),
      emptyError: t(
        "components.startPetitionForm.fields.featuredImage.validationErrors.empty",
      ),
      uploadLabel: t(
        "components.startPetitionForm.fields.featuredImage.buttonLabel.upload",
      ),
      reUploadLabel: t(
        "components.startPetitionForm.fields.featuredImage.buttonLabel.reUpload",
      ),
    },
  };

  const FileName = styled.p({
    [`@media (max-width: ${breakpointValues.sm}px)`]: {
      display: "none",
    },
    ...fontWeightBold,
    ...marginBottomMd,
  });

  return (
    <>
      <div>
        <FieldHeading
          text={CONTENT.featuredImage.heading}
          tipAction={() => {
            setFieldHelp("featuredImage");
          }}
        />

        <p>{CONTENT.featuredImage.description}</p>

        <p>{CONTENT.featuredImage.formatDescription}</p>

        {!initiative.featuredImage && (
          <FormControl
            id="step5-featuredImage"
            placeholder={CONTENT.featuredImage.placeholder}
            name="featuredImage"
            tone="petition"
            type="file"
            captionAlign="right"
            label={CONTENT.featuredImage.uploadLabel}
            displayLabel={false}
            componentRef={inputRef}
            acceptFormat="image/jpg, image/jpeg, image/png"
            handleUpload={handleImageUpload}
            disabled={isSaving}
            validation={
              (validation.featuredImage || undefined) && {
                valid: !formErrors.featuredImage,
                message: CONTENT.featuredImage.emptyError,
              }
            }
            leftIcon={isSaving ? faSpinner : faArrowUpFromBracket}
          />
        )}

        {initiative?.featuredImage &&
          "object" === typeof initiative?.featuredImage && (
            <Row
              css={{
                margin: "0",
                width: "100%",
                flexDirection: "row",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "flex-start",
                alignContent: "flex-start",
                ...paddingZero,
              }}
            >
              <Column
                span={{ default: 12, sm: 6 }}
                css={[
                  marginBottomMd,
                  paddingXZero,
                  displayFlex,
                  { justifyContent: "center", alignItems: "center" },
                ]}
              >
                <AttachmentImage
                  alt={initiative.title}
                  attachment={initiative.featuredImage}
                  size="md"
                />
              </Column>
              <Column
                span={{ default: 12, sm: 5 }}
                offset={{ default: 0, sm: 1 }}
                css={{
                  margin: "auto",
                  ...paddingZero,
                }}
              >
                <FileName>{initiative.featuredImage.fileName}</FileName>

                <FormControl
                  id="step5-featuredImage-edit"
                  placeholder={CONTENT.featuredImage.placeholder}
                  name="featuredImage"
                  tone="petition"
                  type="file"
                  captionAlign="right"
                  label={CONTENT.featuredImage.reUploadLabel}
                  displayLabel={false}
                  componentRef={inputRef}
                  acceptFormat="image/jpg,image/jpeg,image/png,.jpg,.jpeg,.png"
                  handleUpload={handleImageUpload}
                  disabled={isSaving}
                  validation={
                    (validation.featuredImage || undefined) && {
                      valid: !formErrors.featuredImage,
                      message: CONTENT.featuredImage.emptyError,
                    }
                  }
                  leftIcon={isSaving ? faSpinner : faPenToSquare}
                />
              </Column>
            </Row>
          )}
      </div>
    </>
  );
};

StepFive.propTypes = {
  formErrors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  initiative: PropTypes.object,
};

export default StepFive;
