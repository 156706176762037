import { HeroVariant } from "@Components/Hero";
import { type CSSObject } from "@emotion/react";

import { marginBottomLg, paddingY2xl } from "@Styles/spacers";
import { ColorPalette } from "@Types/configs";
import { breakpointDown } from "@Variables/breakpoints";

const heroStyles = (
  variant: HeroVariant,
  colors: ColorPalette,
  bgCustomStyles?: CSSObject,
) => {
  const primarySwatch = variant === "default" ? "primary" : "secondary";
  const primaryColor = colors[primarySwatch].main;

  const secondarySwatch = primarySwatch === "primary" ? "secondary" : "primary";
  const secondaryColor = colors[secondarySwatch].main;

  const heroStyle: CSSObject[] = [
    paddingY2xl,
    marginBottomLg,
    {
      backgroundColor: secondaryColor,
      height: "100%",
      overflow: "hidden",
      position: "relative",
    },
  ];

  const bgStyle: CSSObject = {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    zIndex: 0,
    height: "100%",
    svg: {
      position: "absolute",
      bottom: 0,
      height: "100%",
      [breakpointDown["sm"]]: {
        height: "75%",
      },
      ...bgCustomStyles,
    },
  };

  return { heroStyle, bgStyle, primaryColor, secondaryColor };
};

export default heroStyles;
