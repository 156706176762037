import { z } from "zod";

const schema = z.object({
  email: z
    .string({ required_error: "emailRequired" })
    .email({ message: "invalidEmail" })
    .regex(/^[^äÄöÖüÜ]+$/),
});

export default schema;
