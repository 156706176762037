import { zodResolver } from "@hookform/resolvers/zod";
import { Amount, DonationFormValues } from "@Types/pages";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import schema from "@Validations/home.donation.schema";
import { RadioControlOption } from "@Types/components";
import {
  Body,
  Button,
  Column,
  Container,
  Heading,
  RadioControlGroup,
  Row,
  TextControl,
} from "@Components";
import getDonationUrl from "@Stylize/helpers/getDonationUrl";
import {
  formContainerStyle,
  formInputStyle,
} from "@Appearances/pages/styleHomeDonation";
import { faSparkles, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { faBoxHeart } from "@fortawesome/pro-regular-svg-icons";

import ProgressBar from "@Components/ProgressBar";
import {
  borderOrange,
  elevation2,
  fontWeightMedium,
  gapMd,
  marginBottomLg,
  marginTopLg,
  paddingLeftLg,
  paddingMd,
  paddingXLg,
} from "@Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { CSSObject, useTheme } from "@emotion/react";
import { breakpointDown, breakpointUp } from "@Variables/breakpoints";
import { useCookieOptions } from "@Utils/useCookies";
import { PostsService } from "@Services/posts.service";
import { Banner } from "@Types/services/post";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
const firstRowStyle: CSSObject[] = [
  {
    [breakpointUp["md"]]: {
      ...paddingLeftLg,
    },
  },
];

const containerStyle: CSSObject[] = [
  paddingMd,
  {
    [breakpointDown["md"]]: {
      ...paddingMd,
    },
  },
  { ...borderOrange, borderWidth: ".5rem" },
];

type VariationType = "Democracy" | "Original";

const EYOBanner = ({ signableId }: { signableId: string }) => {
  const { pushInstruction, trackEvent } = useMatomo();

  const { t } = useTranslation();

  const variations = {
    Original: {
      title: t("common.banners.EOY.original.title"),
      content: t("common.banners.EOY.original.content"),
    },
    Democracy: {
      title: t("common.banners.EOY.democracy.title"),
      content: t("common.banners.EOY.democracy.content"),
    },
  };

  const postsService = PostsService.getInstance();

  const [post, setPost] = useState<Banner | null>(null);

  const { colors } = useTheme();

  const { bannerClosed, setBannerConsent } = useCookieOptions();

  const [isVisible, setIsVisible] = useState(!bannerClosed);

  const [variation, setVariation] = useState<VariationType>("Original"); // Default variation is democracy

  const methods = useForm<DonationFormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: Amount.FIFTY,
      customValue: "",
    },
  });

  const loadBanner = async () => {
    try {
      const { data: post } = await postsService.getBanner("EOY");
      if (post) {
        setPost(post);
      }
    } catch (error) {
      setIsVisible(false);
      console.error(error);
    }
  };

  const { handleSubmit, setValue } = methods;

  //#region Helpers
  const amounts: RadioControlOption[] = [
    {
      label: `${Amount.TWENTY_FIVE}€`,
      value: Amount.TWENTY_FIVE,
    },
    {
      label: `${Amount.FIFTY}€`,
      value: Amount.FIFTY,
    },
    {
      label: `${Amount.ONE_HUNDRED}€`,
      value: Amount.ONE_HUNDRED,
    },
    {
      label: (
        <TextControl
          type="number"
          name={"customValue"}
          placeholder={`${t("common.donation.otherAmount")}`}
          required
          onFocus={() => setValue("amount", Amount.OTHER)} // Add onFocus event here
        />
      ),
      value: Amount.OTHER,
    },
  ];
  //#endregion

  const onSubmit = (data: DonationFormValues) => {
    // Trigger donationStarted event in Matomo
    trackEvent({
      category: "Banner A/B Test",
      action: "donationStarted",
      name: `${variation}`,
    });

    const amount =
      data.amount === Amount.OTHER ? data.customValue : data.amount;

    const url = getDonationUrl({
      context: "EOY",
      source: "BannerEOY2024",
      amount: Number(amount),
      signableId: signableId,
    });

    window.location.href = url;
  };

  const handleBannerClose = () => {
    // Set banner consent to hide it for the user and track bannerClosed event
    setBannerConsent();
    setIsVisible(false);

    trackEvent({
      category: "Banner A/B Test",
      action: "bannerClosed",
      name: `${variation}`,
    });
  };

  useEffect(() => {
    // If the banner is closed, set it to not visible
    if (bannerClosed) {
      setIsVisible(false);
    } else {
      // Otherwise, load the banner data
      loadBanner();
    }
  }, [bannerClosed]);

  useEffect(() => {
    pushInstruction("AbTesting::create", {
      name: "2024EOY", // you can also use "2" (ID of the experiment) to hide the name
      percentage: 100,
      includedTargets: [
        { attribute: "url", inverted: "0", type: "any", value: "" },
      ],
      excludedTargets: [],
      startDateTime: "2024/11/14 16:07:00 UTC",
      variations: [
        {
          name: "Original",
          activate: function (event: unknown) {
            // usually nothing needs to be done here
            console.info({ event });
          },
        },
        {
          name: "Democracy", // you can also use "2" (ID of the variation) to hide the name
          activate: function (event: unknown) {
            console.info({ event });
            setVariation("Democracy");
          },
        },
      ],
    });
  }, []);

  // Return null if the banner is not visible
  if (!isVisible) return null;

  return (
    <div>
      <Container
        size="lg"
        css={containerStyle}
      >
        {/* header breadcrumb and close button */}
        <Row css={firstRowStyle}>
          <Column span={{ default: 9, sm: 7, md: 5, lg: 4 }}>
            <Button
              type="submit"
              fullWidth={{ default: true }}
              label={"Unterstütze innn.it zum Jahresende"}
              icon={faSparkles}
              variant="primary"
              css={[
                {
                  justifyContent: "left",
                  "&:hover": {
                    opacity: 1,
                    cursor: "default",
                    background: colors.primary.main,
                    ...fontWeightMedium,
                  },
                },
              ]}
              size="sm"
              tone="petition"
            />
          </Column>
          <Column css={{ textAlign: "right" }}>
            <FontAwesomeIcon
              icon={faXmark}
              fontSize={"2rem"}
              color={colors.label[75]}
              css={{ cursor: "pointer" }}
              onClick={handleBannerClose}
            />
          </Column>
        </Row>
        {/* Main content */}
        <Row
          css={[
            marginTopLg,
            {
              [breakpointUp["md"]]: {
                ...paddingXLg,
              },
            },
          ]}
        >
          <Column span={{ default: 12, lg: 6 }}>
            <Heading
              scale={3}
              variant="display"
              color="label"
              css={[marginBottomLg]}
            >
              {variations[variation].title}
            </Heading>
            <Body>{variations[variation].content}</Body>
          </Column>
          <Column
            offset={{ default: 0, md: 0, lg: 1 }}
            span={{ default: 12, md: 12, lg: 5 }}
          >
            <div
              css={{
                border: `1px solid ${colors.secondary.main}`,
                borderRadius: "1rem",
                ...paddingMd,
                ...elevation2,
              }}
            >
              <div>
                <ProgressBar
                  max={(post && post.goalValue) || 0}
                  value={(post && post.currentValue) || 0}
                />
              </div>
              <FormProvider {...methods}>
                <form
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div
                    css={[
                      formContainerStyle,
                      {
                        [breakpointDown["lg"]]: {
                          display: "none",
                        },
                      },
                    ]}
                  >
                    <div css={formInputStyle}>
                      <RadioControlGroup
                        containerStyles={{
                          [breakpointUp["lg"]]: {
                            ...gapMd,
                            flexWrap: "nowrap",
                          },
                        }}
                        name="amount"
                        options={amounts}
                        required
                      />
                    </div>
                  </div>

                  <Button
                    type="submit"
                    fullWidth={{ default: true }}
                    label={t("pages.home.donation.buttonLabel")}
                    icon={faBoxHeart}
                    variant="secondary"
                    css={{
                      [breakpointDown["lg"]]: {
                        ...marginTopLg,
                      },
                    }}
                  />
                </form>
              </FormProvider>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
};

export default EYOBanner;
