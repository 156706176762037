import { CSSObject, keyframes } from "@emotion/react";

import { SpanComponentProps } from "@Types/components/pageProps";
import colors from "../variables/colors";

type PlaceholderProps = SpanComponentProps & {
  /** Number of lines to be displayed. */
  lines?: number;
};

/**
 * An element to be displayed inside other block elements while the content is being loaded.
 */
const Placeholder = ({ lines = 1, ...props }: PlaceholderProps) => {
  if (lines < 1) lines = 1;

  const animationFrames = keyframes`
    0% {
      background-color: ${colors.gray3};
    }
    100% {
      background-color: ${colors.gray1};
    }
  `;

  const lastLineStyle: CSSObject = {
    width: "65%",
  };

  const lineStyle: CSSObject = {
    animationName: `${animationFrames}`,
    animationDuration: "1s",
    animationTimingFunction: "ease-out",
    animationDelay: "0",
    animationDirection: "alternate",
    animationIterationCount: "infinite",
    animationFillMode: "none",
    animationPlayState: "running",
    display: "inline-block",
    height: "100%",
    minHeight: "1em",
    width: "100%",
  };

  return (
    <>
      {[...Array(lines)].map((_, index) => {
        const currentLine = index + 1;

        return (
          <span
            css={[
              lineStyle,
              currentLine > 2 && currentLine == lines && lastLineStyle,
            ]}
            {...props}
            key={currentLine}
          />
        );
      })}
    </>
  );
};

export default Placeholder;
