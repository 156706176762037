import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faBoxHeart,
  faEnvelopeDot,
  faGlobe,
} from "@fortawesome/pro-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import {
  colophonCSS,
  columnCSS,
  footerCSS,
  footerLowerCSS,
  infoCSS,
  linkCSS,
  linkListCSS,
  linkListItemLinkCSS,
  logoContainerCSS,
  sharedColumnCSS,
  socialMediaListCSS,
} from "@Appearances/components/stylesFooter";
import { routes } from "@Configs";
import {
  colorOrange,
  marginBottomLg,
  marginBottomMd,
  marginBottomZero,
  marginTopMd,
  paddingYLg,
} from "@Styles";
import getDonationUrl from "@Stylize/helpers/getDonationUrl";
import schema from "@Validations/newsletterSubscriptionSchema";
import { breakpointDown, colors } from "@Variables";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import LogoSVG from "../images/LogoSVG";
import Button from "./Button";
import Column from "./Column";
import { Container } from "./Container";
import { Heading } from "./Heading";
import Row from "./Row";
import { TextControl } from "./controllers";
import { BodySm, IconLi, Link } from "./typography";
import { useModalContext } from "@Contexts/ModalsContext";
import { useEmailContext } from "@Contexts/EmailContext";

const Footer = () => {
  return (
    <div css={footerCSS}>
      <Container size="lg">
        <Row css={paddingYLg}>
          <Column span={{ default: 12, sm: 12, md: 12, lg: 4 }}>
            <Features />
          </Column>

          <Column
            span={{ default: 12, sm: 12, md: 6, lg: 2 }}
            offset={{ lg: 1 }}
            css={sharedColumnCSS}
          >
            <AboutUs />
          </Column>

          <Column
            span={{ default: 12, sm: 12, md: 6, lg: 4 }}
            offset={{ lg: 1 }}
            css={[
              ...sharedColumnCSS,
              {
                [breakpointDown["md"]]: {
                  ...marginBottomLg,
                },
              },
            ]}
          >
            <NewsletterWidget />
          </Column>
        </Row>

        <div css={footerLowerCSS}>
          <Row css={{ alignItems: "center", justifyContent: "center" }}>
            <Column
              span={{ default: 12, md: 4, lg: 4 }}
              order={{ md: 2, lg: 3 }}
              css={[
                {
                  [breakpointDown["md"]]: {
                    ...marginBottomLg,
                  },
                },
              ]}
            >
              <SocialMediaIcons />
            </Column>

            <Column
              span={{ default: 12, md: 8, lg: 4 }}
              order={{ md: 1, lg: 2 }}
              css={[
                {
                  [breakpointDown["md"]]: {
                    ...marginBottomLg,
                  },
                },
              ]}
            >
              <Info />
            </Column>

            <Column
              span={{ default: 12, md: 12, lg: 4 }}
              order={{ md: 2, lg: 1 }}
            >
              <Colophon />
            </Column>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Footer;

//#region Features(First Column)
const Features = () => {
  const { t } = useTranslation();

  const iconItems = [
    { label: t("footer.features.description"), icon: faBoxHeart },
    { label: t("footer.features.server"), icon: faGlobe },
  ];

  return (
    <div css={columnCSS}>
      <div css={logoContainerCSS}>
        <LogoSVG tone="petition" />
      </div>

      {iconItems.map((item) => (
        <IconLi
          key={item.label}
          icon={item.icon}
          labelColor={colors.orange}
        >
          <span css={{ color: colors.orange }}>{item.label}</span>
        </IconLi>
      ))}
    </div>
  );
};
//#endregion Features(First Column)

const AboutUs = () => {
  const { t } = useTranslation();
  const donateUrl = getDonationUrl({ context: "general", source: "footer" });

  const aboutUsItems = [
    { label: t("footer.column.aboutUs"), href: routes.about },
    { label: t("footer.column.faq"), href: routes.faq },
    {
      label: t("footer.column.press"),
      href: `${routes.press}/#pressekontakt`,
      blank: true,
    },
    {
      label: t("footer.column.organization"),
      href: routes.organization,
      blank: true,
    },
    {
      label: t("footer.column.donate"),
      href: donateUrl,
      blank: true,
    },
  ];

  return (
    <div css={columnCSS}>
      <ul css={linkListCSS}>
        {aboutUsItems.map((item) => {
          return (
            <li key={item.label}>
              <Link
                css={linkListItemLinkCSS}
                rel={item.blank ? "noopener noreferrer" : ""}
                target={item.blank ? "_blank" : ""}
                href={item.href}
              >
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
//#endregion AboutUs(Second Column)

//#region Newsletter Form(third column)
type NewsletterSubscriptionForm = {
  email: string;
};

const NewsletterWidget = () => {
  const { toggleModal } = useModalContext();
  const { setEmail } = useEmailContext();

  const methods = useForm<NewsletterSubscriptionForm>({
    resolver: zodResolver(schema),
  });

  const { handleSubmit } = methods;

  const { t } = useTranslation();

  const onSubmit = (data: NewsletterSubscriptionForm) => {
    setEmail(data.email);
    toggleModal("newsletter");
  };

  return (
    <div css={columnCSS}>
      <Heading
        variant="display"
        css={[marginBottomMd, colorOrange]}
        scale={4}
      >
        {t("footer.newsletter.title")}
      </Heading>

      <FormProvider {...methods}>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextControl
            label={t("components.userForm.fields.email")}
            name="email"
            type="email"
            placeholder="name@email.com"
          />

          <Button
            type="submit"
            fullWidth={{ default: true }}
            label={t("common.newsletter.subscribe")}
            icon={faEnvelopeDot}
            variant="primary"
            css={[marginTopMd, { width: "60%" }]}
            size="sm"
            tone="petition"
          />
        </form>
      </FormProvider>
    </div>
  );
};
//#endregion Newsletter Form  (third column)

//#region Below hr(footerLower)
//#region Social Media Icons (third column)

const SocialMediaIcons = () => {
  const { t } = useTranslation();

  const SocialMediaItems = [
    {
      name: "instagram",
      label: t("footer.socialMedia.instagram"),
      icon: faInstagram,
      href: "https://instagram.com/innn.it",
    },
    {
      name: "facebook",
      label: t("footer.socialMedia.facebook"),
      icon: faFacebook,
      href: "https://facebook.com/HierKannstDuMitbestimmen",
    },
    {
      name: "x",
      label: t("footer.socialMedia.x"),
      icon: faXTwitter,
      href: "https://x.com/innn_it",
    },
    {
      name: "tiktok",
      label: t("footer.socialMedia.tiktok"),
      icon: faTiktok,
      href: "https://tiktok.com/@innn.it",
    },
  ];

  return (
    <ul css={socialMediaListCSS}>
      {SocialMediaItems.map((item) => (
        <li key={`socialMedia-${item.name}`}>
          <Link
            css={linkCSS}
            href={item.href}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              aria-label={item.label}
              fixedWidth
              icon={item.icon as IconProp}
              size="xl"
            />
          </Link>
        </li>
      ))}
    </ul>
  );
};
//#endregion SocialMedia Icons

//#region Info Links (second column)

const Info = () => {
  const { t } = useTranslation();

  const infoItems = [
    {
      label: t("footer.column.dataProtection"),
      href: routes.info.dataProtection,
    },
    {
      label: t("footer.column.imprint"),
      href: routes.info.imprint,
      blank: true,
    },
    {
      label: t("footer.column.termsOfUse"),
      href: routes.info.termsOfUse,
      blank: true,
    },
  ];

  return (
    <ul css={infoCSS}>
      {infoItems.map((item) => {
        return (
          <li key={item.label}>
            <Link
              href={item.href}
              css={linkCSS}
            >
              {item.label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
//#endregion Info Links

//#region Copyright

const Colophon = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <div css={colophonCSS}>
      <BodySm css={[marginBottomZero]}>{t("footer.colophon", { year })}</BodySm>
    </div>
  );
};
//#endregion Copyright
//#endregion Below hr(footerLower)
