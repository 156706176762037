import React, { useEffect, useId, useRef, useState } from "react";

import { CSSObject } from "@emotion/react";
import {
  IconDefinition,
  faPenToSquare,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import isISO31661Alpha2 from "validator/lib/isISO31661Alpha2";

import {
  borderDanger,
  colorDanger,
  displayFlex,
  gapSm,
  marginBottomLg,
  marginBottomSm,
  marginRightSm,
  marginTopMd,
  marginTopXs,
  marginZero,
  paddingLeftSm,
  paddingSm,
  paddingZero,
} from "@Styles";
import getCountries from "../helpers/getCountries";
import { useLocation } from "../helpers/useLocation";
import {
  ValidationMessage,
  ValidationMessages,
  ValidationRules,
  isValid,
  validate,
} from "../helpers/validation";
import Button from "./Button";
import Column from "./Column";
import FormControl from "./FormControl";
import PrivacyText from "./PrivacyText";
import Row from "./Row";
import { BodySm } from "./typography";

type OptionalField = "address" | "password";
type OptionalFields = OptionalField[];

export type UserFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  address?: string;
  country: string;
  location: string;
  locationDisplay?: string;
  password?: string;
  postalCode: string;
  postalCodeDisplay?: string;
  subscribe?: string;
};

type UserFormProps = {
  autoFocus?: keyof UserFormFields;
  hideFields?: (keyof UserFormFields)[];
  isLoading?: boolean;
  loggedIn?: boolean;
  onSubmit?: (event?: React.FormEvent) => Promise<ValidationMessage>;
  optionalFields?: OptionalFields;
  submitButtonIcon?: IconDefinition;
  submitButtonLabel?: string;
  subscribe?: boolean;
  subscriptionText?: { true?: string; false?: string };
  user: UserFormFields;
  setUser: React.Dispatch<React.SetStateAction<UserFormFields>>;
  showSignatureWarning?: boolean;
};

const errorMessageStyle: CSSObject[] = [marginTopXs, colorDanger];

const UserForm = ({
  autoFocus,
  hideFields = [],
  isLoading = false,
  loggedIn = false,
  onSubmit,
  optionalFields = [],
  submitButtonIcon,
  submitButtonLabel = "",
  subscribe = false,
  user,
  setUser,
  showSignatureWarning = false,
}: UserFormProps) => {
  const formId = useId();

  const { i18n, t } = useTranslation();

  const locationFieldRef = useRef<HTMLInputElement>();

  const [isFormLoading, setIsFormLoading] = useState(isLoading);

  const [mode, setMode] = useState<"loggedIn" | "edit">("edit");

  const defaultSubmitError = {
    valid: true,
    message: "",
  };

  const [submitError, setSubmitError] =
    useState<ValidationMessage>(defaultSubmitError);

  const [validation, setValidation] = useState<ValidationMessages>({});

  const {
    locationProperties,
    locations,
    postalCodeError,
    setPostalCode,
    validateLocation,
  } = useLocation({
    country: user?.country,
    postalCode: user?.postalCode,
    location: user?.location,
  });

  const validationRules: ValidationRules = {
    firstName: [
      {
        message: t("components.userForm.validation.firstName.notEmpty"),
        valid: !isEmpty(user.firstName),
      },
    ],
    lastName: [
      {
        message: t("components.userForm.validation.lastName.notEmpty"),
        valid: !isEmpty(user.lastName),
      },
    ],
    email: [
      {
        message: t("components.userForm.validation.email.notEmpty"),
        valid: !isEmpty(user.email),
      },
      {
        message: t("components.userForm.validation.email.notValid"),
        valid: isEmail(user.email, { blacklisted_chars: "äÄöÖüÜ" }),
      },
    ],
    address: [
      {
        message: t("components.userForm.validation.address.notEmpty"),
        valid:
          !optionalFields.includes("address") ||
          (!!user.address && !isEmpty(user.address)),
      },
    ],
    password: [
      {
        message: t("components.userForm.validation.password.notEmpty"),
        valid:
          !optionalFields.includes("password") ||
          (!!user.password && !isEmpty(user.password)),
      },
    ],
    country: [
      {
        message: t("components.userForm.validation.country.notEmpty"),
        valid: !isEmpty(user.country),
      },
      {
        message: t("components.userForm.validation.country.notValid"),
        valid: isISO31661Alpha2(user.country),
      },
    ],
  };

  const countries = getCountries(i18n.language);

  const validateForm = () => {
    const validatedUser: UserFormFields = {
      ...user,
      firstName: user.firstName.trim(),
      lastName: user.lastName.trim(),
      email: user.email.trim(),
      postalCodeDisplay: user.postalCodeDisplay
        ? user.postalCodeDisplay.trim()
        : "",
      locationDisplay: user.locationDisplay ? user.locationDisplay.trim() : "",
    };

    if (validatedUser.country !== "DE") {
      validatedUser.postalCode = "";
      validatedUser.postalCodeDisplay = "";
      validatedUser.location = "";
      validatedUser.locationDisplay = "";
    }

    setUser(validatedUser);

    const validation = validate(validationRules);
    const locationValidation = validateLocation(locationProperties);

    let formValid = true;

    if (!locationValidation[Object.keys(locationValidation)[0]].valid) {
      formValid = false;
    } else {
      formValid = isValid(validation);
    }

    setValidation({ ...validation, ...locationValidation });
    return formValid;
  };

  useEffect(() => {
    if (subscribe && !user.subscribe) {
      setUser((user) => ({
        ...user,
        subscribe: "true",
      }));
    }
  }, [user, setUser, subscribe]);

  useEffect(() => {
    if (
      !(
        loggedIn &&
        user.firstName &&
        user.lastName &&
        user.email &&
        user.country &&
        user.postalCode &&
        user.location
      )
    ) {
      setMode("edit");
    } else {
      setMode("loggedIn");
    }
  }, [loggedIn, user]);

  useEffect(() => {
    setUser((prev) => ({
      ...prev,
      country: locationProperties?.country || prev?.country || "DE",
      location: locationProperties?.location?._id || "",
      locationDisplay:
        locationProperties?.location?.name || prev?.locationDisplay || "",
      postalCode: locationProperties?.postalCode?._id || "",
      postalCodeDisplay:
        locationProperties?.postalCode?.postalCode ||
        prev?.postalCodeDisplay ||
        "",
    }));
  }, [locationProperties, setUser]);

  useEffect(() => {
    if (locations.length == 0) return;
    if (!location && locations.length > 1 && locationFieldRef.current) {
      locationFieldRef.current.focus();

      setValidation((prev) => ({
        ...prev,
        postalCode: { valid: true, message: "" },
      }));
    } else {
      setValidation((prev) => ({
        ...prev,
        location: { valid: true, message: "" },
        postalCode: { valid: true, message: "" },
      }));
    }
  }, [locations]);

  useEffect(() => {
    setPostalCode(user.postalCodeDisplay ? user.postalCodeDisplay : "");
  }, [setPostalCode, user]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setSubmitError(defaultSubmitError);
    setIsFormLoading(true);
    event.preventDefault();

    const valid = validateForm();
    if (valid && onSubmit) {
      const response = await onSubmit();
      setSubmitError(response);
    }

    setIsFormLoading(false);
  };

  const parseError = (
    hookError: ValidationMessage,
    formError: ValidationMessage,
  ) => {
    if (hookError && !hookError.valid) {
      return hookError;
    } else {
      return formError;
    }
  };

  const loggedInUserDataStyle: CSSObject = {
    display: "flex",
  };

  const loggedInUserDataTextStyle: CSSObject = {
    flexGrow: 1,
    fontWeight: "bold",
  };

  const CONTENT = {
    warning: {
      normal: t("components.userForm.warning.normal"),
      bold: t("components.userForm.warning.bold"),
    },
  };

  const inputFields: (keyof UserFormFields)[] = [
    "firstName",
    "lastName",
    "email",
    "country",
    "country",
    "address",
    "postalCode",
    "location",
  ];
  const hiddenDictionary: Record<keyof UserFormFields, boolean> =
    inputFields.reduce(
      (acc, current) => {
        return { ...acc, [current]: hideFields.includes(current) };
      },
      {} as Record<keyof UserFormFields, boolean>,
    );

  return (
    <form
      id={formId}
      onSubmit={handleSubmit}
      noValidate
    >
      <fieldset disabled={isLoading || isFormLoading}>
        {mode === "edit" && (
          <>
            {!hiddenDictionary["firstName"] && hiddenDictionary["lastName"] && (
              <FormControl
                autoFocus={"firstName" == autoFocus}
                id={`${formId}-firstName`}
                label={t("components.userForm.fields.firstName") as string}
                name="firstName"
                setValue={setUser}
                type="text"
                validation={validation.firstName}
                value={user.firstName}
              />
            )}
            {!hiddenDictionary["lastName"] && hiddenDictionary["firstName"] && (
              <FormControl
                autoFocus={autoFocus === "lastName"}
                id={`${formId}-lastName`}
                label={t("components.userForm.fields.lastName") as string}
                name="lastName"
                setValue={setUser}
                type="text"
                validation={validation.lastName}
                value={user.lastName}
              />
            )}{" "}
            {!hiddenDictionary["firstName"] &&
              !hiddenDictionary["lastName"] && (
                <Row css={[marginZero, gapSm]}>
                  <Column css={[paddingZero]}>
                    <FormControl
                      autoFocus={autoFocus === "firstName"}
                      id={`${formId}-firstName`}
                      label={
                        t("components.userForm.fields.firstName") as string
                      }
                      name="firstName"
                      setValue={setUser}
                      type="text"
                      validation={validation.firstName}
                      value={user.firstName}
                    />
                  </Column>

                  <Column css={paddingZero}>
                    <FormControl
                      autoFocus={autoFocus === "lastName"}
                      id={`${formId}-lastName`}
                      label={t("components.userForm.fields.lastName") as string}
                      name="lastName"
                      setValue={setUser}
                      type="text"
                      validation={validation.lastName}
                      value={user.lastName}
                    />
                  </Column>
                </Row>
              )}
            {!hiddenDictionary["email"] && (
              <FormControl
                autoFocus={autoFocus === "email"}
                id={`${formId}-email`}
                label={t("components.userForm.fields.email") as string}
                name="email"
                setValue={setUser}
                type="email"
                validation={validation.email}
                value={user.email}
              />
            )}
            {!hiddenDictionary["country"] && (
              <FormControl
                autoFocus={autoFocus === "country"}
                id={`${formId}-country`}
                label={t("components.userForm.fields.country") as string}
                name="country"
                setValue={setUser}
                options={countries}
                type="select"
                validation={validation.country}
                value={user.country}
              />
            )}
            {optionalFields.includes("address") && (
              <FormControl
                autoFocus={autoFocus === "address"}
                id={`${formId}-address`}
                label={t("components.userForm.fields.address") as string}
                name="address"
                setValue={setUser}
                type="address"
                validation={validation.address}
                value={user.address}
              />
            )}
            {user.country === "DE" && (
              <fieldset>
                {!hiddenDictionary["postalCode"] &&
                  hiddenDictionary["location"] && (
                    <FormControl
                      autoFocus={autoFocus === "postalCode"}
                      id={`${formId}-postalCode`}
                      label={
                        t("components.userForm.fields.postalCode") as string
                      }
                      name="postalCode"
                      setValue={setUser}
                      type="text"
                      validation={parseError(
                        postalCodeError,
                        validation.postalCode,
                      )}
                      value={user.postalCodeDisplay}
                      hasHiddenValue
                      hiddenValue={user.postalCode}
                    />
                  )}

                {!hiddenDictionary["location"] &&
                  hiddenDictionary["postalCode"] && (
                    <FormControl
                      autoFocus={autoFocus === "location"}
                      id={`${formId}-location`}
                      disabled={locations.length === 1 ? true : false}
                      label={t("components.userForm.fields.location") as string}
                      name="location"
                      componentRef={locationFieldRef}
                      options={locations}
                      setValue={setUser}
                      type="select"
                      validation={validation.location}
                      value={user.location}
                    />
                  )}

                {!hiddenDictionary["location"] &&
                  !hiddenDictionary["postalCode"] && (
                    <Row
                      css={[marginZero, { justifyContent: "space-between" }]}
                    >
                      <Column
                        css={[paddingZero]}
                        span={{ default: 4 }}
                      >
                        <FormControl
                          autoFocus={autoFocus === "postalCode"}
                          id={`${formId}-postalCode`}
                          label={
                            t("components.userForm.fields.postalCode") as string
                          }
                          name="postalCode"
                          setValue={setUser}
                          type="text"
                          validation={parseError(
                            postalCodeError,
                            validation.postalCode,
                          )}
                          value={user.postalCodeDisplay}
                          hasHiddenValue
                          hiddenValue={user.postalCode}
                        />
                      </Column>

                      <Column
                        css={[paddingZero, paddingLeftSm]}
                        span={{ default: 8 }}
                      >
                        <FormControl
                          autoFocus={autoFocus === "location"}
                          id={`${formId}-location`}
                          disabled={locations.length <= 1 ? true : false}
                          label={
                            t("components.userForm.fields.location") as string
                          }
                          name="location"
                          componentRef={locationFieldRef}
                          options={locations}
                          setValue={setUser}
                          type="select"
                          validation={validation.location}
                          value={user.location}
                        />
                      </Column>
                    </Row>
                  )}
              </fieldset>
            )}
          </>
        )}

        {optionalFields.includes("password") && (
          <FormControl
            autoFocus={autoFocus === "password"}
            id={`${formId}-password`}
            label={t("components.userForm.fields.password") as string}
            name="password"
            setValue={setUser}
            type="password"
            validation={validation.password}
            value={user.password}
          />
        )}

        {loggedIn && "loggedIn" == mode && (
          <>
            <div css={loggedInUserDataStyle}>
              <p css={loggedInUserDataTextStyle}>
                {`${user.email}`}

                <br />

                {`${user.firstName} ${user.lastName}`}

                <br />

                {`${locationProperties.postalCode?.postalCode} ${locationProperties.location?.name}`}

                <br />

                {
                  countries.find(
                    (country) => country.value == locationProperties.country,
                  )?.label
                }
              </p>

              <div>
                <Button
                  icon={faPenToSquare}
                  iconOnly
                  label={t("common.edit")}
                  onClick={() => setMode("edit")}
                  variant="transparent"
                />
              </div>
            </div>
          </>
        )}

        {!subscribe &&
          !loggedIn &&
          !hideFields.includes("subscribe") &&
          showSignatureWarning && (
            <div
              css={[
                { borderRadius: "4px" },
                borderDanger,
                paddingSm,
                marginTopMd,
                marginBottomSm,
              ]}
            >
              <div css={[displayFlex]}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faTriangleExclamation}
                  color="#FC1515"
                  aria-hidden={true}
                  css={marginRightSm}
                />

                <BodySm css={[colorDanger, marginZero]}>
                  {CONTENT.warning.normal}{" "}
                  <strong>{CONTENT.warning.bold}</strong>
                </BodySm>
              </div>
            </div>
          )}
      </fieldset>

      <div css={[marginTopMd, marginBottomLg]}>
        <Button
          icon={submitButtonIcon}
          label={submitButtonLabel || t("common.navigation.next")}
          loading={isLoading || isFormLoading}
          type="submit"
          variant="primary"
          fullWidth={{ default: true }}
        />
      </div>

      {!submitError.valid && (
        <BodySm css={errorMessageStyle}>{submitError.message}</BodySm>
      )}
      <BodySm>
        <PrivacyText />
      </BodySm>
    </form>
  );
};

export default UserForm;
