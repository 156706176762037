import { PAGINATION_LIMIT } from "@Configs/constants";
import type {
  Attachment,
  Banner,
  Initiative,
  Page,
  Post,
  Testimonial,
  Update,
  WriteInitiativeResponse,
} from "@Types/services/post";
import type {
  CreateInitiativeParams,
  PagiAxiosResponse,
  ResponsePayload,
  SignableParams,
} from "@Types/services/typeServiceParams";
import type { AxiosResponse } from "axios";
import BaseService from "./base.service";
import apiRoutes from "./serviceRoutes";
import { ValidTarget } from "~/pages/StartPetition/utils/types";

const signableParams: SignableParams = {
  limit: PAGINATION_LIMIT,
  type: "petition",
};

export class PostsService extends BaseService {
  private static instance: PostsService;
  private routes = apiRoutes.posts;

  // Singleton instance getter
  public static getInstance(): PostsService {
    if (!PostsService.instance) {
      PostsService.instance = new PostsService();
    }

    return PostsService.instance;
  }

  public async getSignable<T>(id: string): Promise<AxiosResponse<T>> {
    return this.get<T>(this.routes.getSignable(id));
  }

  public async getPagiSignables<T>(
    params: SignableParams = signableParams,
  ): Promise<PagiAxiosResponse<T>> {
    return this.get<ResponsePayload<T>>(this.routes.getPagiSignables, {
      params,
    });
  }

  public async getSignables<T>(
    params: SignableParams = signableParams,
  ): Promise<AxiosResponse<T>> {
    return this.get<T>(this.routes.getSignables, {
      params,
    });
  }

  public async getTags<T>(params = {}): Promise<AxiosResponse<T>> {
    return this.get<T>(this.routes.getTags, { params });
  }

  public async getTestimonials(
    params = {},
  ): Promise<AxiosResponse<Testimonial[]>> {
    return this.get<Testimonial[]>(this.routes.getTestimonials, {
      params,
    });
  }

  public async getUpdate(
    signableId: string,
    _id: string,
  ): Promise<AxiosResponse<Update>> {
    return this.get<Update>(this.routes.getUpdate(signableId, _id));
  }

  public async getUpdates(
    _id: string,
    params: SignableParams = signableParams,
  ): Promise<AxiosResponse<Update[]>> {
    return this.get<Update[]>(this.routes.getUpdates(_id), {
      params,
    });
  }

  public async getInitiatives(): Promise<AxiosResponse<Initiative[]>> {
    return this.get<Initiative[]>(this.routes.getInitiatives);
  }

  public async getInitiative(id: string): Promise<AxiosResponse<Initiative>> {
    return this.get<Initiative>(this.routes.getInitiative(id));
  }

  public async createInitiative(
    params: CreateInitiativeParams = {},
  ): Promise<AxiosResponse<WriteInitiativeResponse>> {
    return this.post(this.routes.createInitiative, params);
  }

  public async updateInitiative(
    id: string,
    params: CreateInitiativeParams = {},
  ): Promise<AxiosResponse<WriteInitiativeResponse>> {
    return this.patch(this.routes.updateInitiative(id), params);
  }

  public async upsertInitiative(
    id: string | null,
    params: CreateInitiativeParams = {},
  ): Promise<AxiosResponse<WriteInitiativeResponse>> {
    if (id) {
      return this.updateInitiative(id, params);
    } else {
      return this.createInitiative(params);
    }
  }

  public async getPage(id: string): Promise<AxiosResponse<Page>> {
    return this.get<Page>(this.routes.getPage(id));
  }

  async createActor(body: {
    name: string;
  }): Promise<AxiosResponse<any, ValidTarget[]>> {
    return this.post(this.routes.createActor, body);
  }

  async createAttachment(
    formData: FormData,
  ): Promise<AxiosResponse<any, Attachment>> {
    return this.post(this.routes.createAttachments, formData);
  }

  async getPost(id: string): Promise<AxiosResponse<Post>> {
    return this.get<Post>(this.routes.getPost(id));
  }

  async getBanner(id: string): Promise<AxiosResponse<Banner>> {
    return this.get<Banner>(this.routes.getBanner(id));
  }
}
