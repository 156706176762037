import React, { useEffect, useRef, useState } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { bgGradient } from "../styles/backgrounds";
import { displayFlex } from "../styles/displays";
import { gapSm, marginBottomZero, marginZero } from "../styles/spacers";
import { colorPrimary, colorSecondary } from "../styles/textColors";
import colors from "../variables/colors";
import { Heading } from "./Heading";
import { BodySm } from "./typography";

type ProgressProps = {
  /** Label after the number. Can be simple text or HTML. */
  label?: string | React.ReactNode;
  /** Bar size. */
  max: number;
  /** Value to be filled in the bar. */
  value: number;
};

/**
 * Progress bar with counter.
 */
const Progress = ({ label, max, value }: ProgressProps) => {
  const { tone } = useTheme();

  const { i18n } = useTranslation();

  const [progress, setProgress] = useState(0);

  const ref = useRef<HTMLProgressElement | null>(null);

  if (max < value) max = value;

  const baseProgressStyle: CSSObject = {
    background: colors.gray3,
    borderRadius: "0.25rem",
  };

  const progressStyle: CSSObject = {
    ...baseProgressStyle,
    appearance: "none",
    height: "0.5rem",
    width: "100%",
  };

  const backgroundStyle = {
    ...baseProgressStyle,
  };

  const foregroundStyle: CSSObject = {
    ...baseProgressStyle,
    ...bgGradient(tone),
    transition: "width 200ms ease-out",
  };

  progressStyle["&::-webkit-progress-bar"] = backgroundStyle;
  progressStyle["&::-webkit-progress-value"] = foregroundStyle;
  progressStyle["&::-moz-progress-bar"] = foregroundStyle;

  const iconStyle: CSSObject = colorSecondary(tone);

  const textContainerStyle: CSSObject = {
    ...displayFlex,
    ...gapSm,
    alignItems: "center",
  };

  const numberStyle: CSSObject = {
    ...marginZero,
    ...colorPrimary(tone),
    width: "auto",
  };

  const labelStyle: CSSObject = {
    ...marginBottomZero,
    flexGrow: 1,
    textAlign: "left",
  };

  useEffect(() => {
    if (!value) {
      return;
    }

    let valueCount = 0;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          return;
        }

        const valueIncrement = value / 25;

        const updateLevel = () => {
          valueCount += valueIncrement;

          if (valueCount <= value) {
            setProgress(valueCount);
            setTimeout(updateLevel, 100);
          }
        };

        updateLevel();
        observer.disconnect();
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [value, max]);

  return (
    <div>
      <div css={textContainerStyle}>
        <div css={iconStyle}>
          <FontAwesomeIcon
            css={{ maxWidth: "20px" }}
            fixedWidth
            icon={faPen}
            size="xl"
          />
        </div>

        <Heading
          scale={5}
          css={numberStyle}
        >
          {new Intl.NumberFormat(i18n.language).format(value)}
        </Heading>
        {label && (
          <BodySm
            as="div"
            css={labelStyle}
          >
            {label}
          </BodySm>
        )}
      </div>
      <progress
        css={progressStyle}
        max={max}
        ref={ref}
        value={progress}
      />
    </div>
  );
};

export default Progress;
