import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useCookies } from "react-cookie";

export const useCookieOptions = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent", "bannerClosed"]);
  const { pushInstruction } = useMatomo();

  const cookieCategories = [
    "essential",
    "statistics",
    "banner",
    // "socialMedia",
    // "thirdParty",
  ];

  const setConsents = (consents: string[]) => {
    setCookie("cookieConsent", consents, {
      path: "/",
      secure: true,
    });

    if (consents.includes("statistics")) {
      pushInstruction("setConsentGiven");
      pushInstruction("setCookieConsentGiven");
    } else {
      pushInstruction("forgetConsentGiven");
      pushInstruction("forgetCookieConsentGiven");
    }
  };

  const setAllConsents = () => {
    setConsents(cookieCategories);
  };

  const setEssentialConsent = () => {
    setConsents(["essential"]);
  };

  const setBannerConsent = () => {
    // Set banner cookie with 12-hour expiration
    setCookie("bannerClosed", "true", {
      path: "/",
      secure: true,
      expires: new Date(Date.now() + 12 * 60 * 60 * 1000),
    });
  };

  const resetCookies = () => {
    // Remove cookie consent by setting an expired cookie
    setCookie("cookieConsent", "", { path: "/", expires: new Date(0) });
    setCookie("bannerClosed", "", { path: "/", expires: new Date(0) }); // Clear banner cookie

    // Forget Matomo consent
    pushInstruction("forgetConsentGiven");
    pushInstruction("forgetCookieConsentGiven");
  };

  return {
    cookieCategories,
    cookieConsent: cookies.cookieConsent,
    bannerClosed: cookies.bannerClosed,
    setAllConsents,
    setConsents,
    setEssentialConsent,
    setBannerConsent,
    resetCookies,
  };
};
