import {
  bannerContainerStyle,
  buttonContainerCSS,
  orderColumn,
  titleStyles,
} from "@Appearances/components/stylesBannerText";
import type { BannerTextProps } from "@Types/components/typeBannerText";
import AttachmentImage from "./AttachmentImage";
import Button from "./Button";
import Column from "./Column";
import { Container } from "./Container";
import { Heading } from "./Heading";
import Row from "./Row";
import { Body } from "./typography";
import { paddingXXl } from "@Styles";
import routes from "@Configs/routes";
import { useUserContext } from "@Contexts/UserContext";
import { useModalContext } from "@Contexts/ModalsContext";
import { useNavigate } from "react-router-dom";

/**
 * Display a text banner with call-to-action and an image.
 */

const BannerText = ({
  alt,
  attachment,
  buttonLabel,
  buttonLink,
  content,
  imagePosition = "right",
  title,
  columns,
  ...props
}: BannerTextProps) => {
  const { isAuth } = useUserContext();

  const { toggleModal } = useModalContext();

  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (buttonLink === routes.petition.start && !isAuth) {
      toggleModal("getUser");
    } else if (buttonLink.startsWith("https")) {
      window.location.href = buttonLink;
    } else {
      navigate(buttonLink);
    }
  };

  return (
    <Container
      {...props}
      size="lg"
      css={[bannerContainerStyle]}
    >
      <Row>
        <Column
          span={{ default: 12, sm: 12, md: 6, lg: 6 }}
          order={orderColumn(1, imagePosition)}
          // offset={{
          //   default: 0,
          //   md: imagePosition === "left" ? 1 : 0,
          //   lg: imagePosition === "left" ? 1 : 0,
          // }}
          {...columns?.content}
          css={paddingXXl}
        >
          <Heading
            scale={4}
            color="primary"
            css={titleStyles}
          >
            {title}
          </Heading>

          <Body>{content}</Body>

          {buttonLabel && (
            <div css={buttonContainerCSS}>
              <Button
                fullWidth={{ default: true, md: false }}
                label={buttonLabel}
                variant="primary"
                size="md"
                onClick={handleButtonClick}
              />
            </div>
          )}
        </Column>

        <Column
          span={{ default: 12, md: 6, lg: 6 }}
          order={orderColumn(2, imagePosition)}
          css={[{ alignContent: "center", ...paddingXXl }]}
          {...columns?.image}
        >
          <AttachmentImage
            attachment={attachment}
            alt={alt}
            size="lg"
          />
        </Column>
      </Row>
    </Container>
  );
};

export default BannerText;
