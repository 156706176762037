import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import apiRoutes from "./serviceRoutes";

class BaseService {
  private api: AxiosInstance;
  private showInfo = false;

  constructor() {
    this.api = axios.create({
      baseURL: apiRoutes.baseURL.root,
      headers: {},
    });

    this.api.interceptors.response.use(this.handleSuccess, this.handleError);

    this.api.interceptors.request.use((config) => {
      if (config.params) {
        config.params = {
          ...config.params,
          lng: "de-DE",
        };
      } else {
        config.params = {
          lng: "de-DE",
        };
      }
      return config;
    });
  }

  // Success handler
  private handleSuccess(response: AxiosResponse): AxiosResponse {
    return response;
  }

  // Error handler
  private handleError = (error: AxiosError): Promise<never> => {
    // Optionally, handle specific error cases here
    console.error("API request error:", error);
    return Promise.reject(error);
  };

  private logRequest(
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    url: string,
  ) {
    if (this.showInfo) {
      console.info(`${method} request to ${url}`);
    }
  }

  // Generic GET request
  public async get<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    console.info("GET request to", url);

    return this.api.get<T>(url, config);
  }

  // Generic POST request
  public async post<T, R>(
    url: string,
    data: T,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<R>> {
    this.logRequest("POST", url);

    return this.api.post<R>(url, data, config);
  }

  // Generic PUT request
  public async put<T, R>(
    url: string,
    data: T,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<R>> {
    this.logRequest("PUT", url);

    return this.api.put<R>(url, data, config);
  }

  // Generic PUT request
  public async patch<T, R>(
    url: string,
    data: T,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<R>> {
    this.logRequest("PATCH", url);

    return this.api.patch<R>(url, data, config);
  }

  // Generic DELETE request
  public async delete<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    this.logRequest("DELETE", url);

    return this.api.delete<T>(url, config);
  }
}

export default BaseService;
