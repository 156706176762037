//UI
import { faCircleUser as faCircleUserSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import LogoSVG from "../../../images/LogoSVG";
import Button from "../../Button";
import { Container } from "../../Container";
import { Menu } from "./Menu";
import { MenuToggler } from "./MenuToggler";

//Hooks
import { routes } from "@Configs";
import { useModalContext } from "@Contexts/ModalsContext";
import {
  logout,
  useUserContext,
  useUserDispatchContext,
} from "@Contexts/UserContext";
import { useRef, useState } from "react";

//CSS
import { CSSObject, useTheme } from "@emotion/react";
import {
  faBoxHeart,
  faCircleUser,
  faEnvelopeDot,
} from "@fortawesome/pro-regular-svg-icons";
import {
  bgTransparent,
  bgWhite,
  colorGray4,
  colorPrimary,
  colorSecondary,
  displayMdInlineBlock,
  displayNone,
  elevation2,
  gapMd,
} from "@Styles";

//Utils
import getDonationUrl from "@Stylize/helpers/getDonationUrl";

//Types
import type { MenuContent } from "@Types/components/typeMenu";
import { type Tone, navBarHeight, zIndexes } from "@Variables";
import { type TFunction } from "i18next";

//Services
import { UsersService } from "@Services/users.service";

//#region Styles
export type NavBarProps = {
  /** Color theme. */
  theme: Tone;
};

const navBarCSS: CSSObject[] = [
  bgWhite,
  elevation2,
  {
    borderRadius: 0,
    display: "flex",
    height: navBarHeight,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: zIndexes.fixed,
  },
];

const navBarContainerStyle: CSSObject = {
  display: "flex",
  alignItems: "center",
  height: "100%",
  justifyContent: "space-between",
  margin: "auto",
};

const navBarBrandStyle: CSSObject = {
  height: "100%",
  "& svg": {
    height: "100%",
  },
  a: {
    backgroundColor: "transparent",
  },
};

const navBarItemsCSS: CSSObject[] = [
  gapMd,
  { alignItems: "center", display: "flex", justifyContent: "end" },
];

const navBarButtonContainerCSS: CSSObject[] = [
  displayNone,
  displayMdInlineBlock,
];

const userMenuButtonStyle: CSSObject = { "&:focus": bgTransparent };
//#endregion
/**
 *  Site navigation bar. The theme changes according to the context.
 */
//#region Navbar Helper
const menuContent = (t: TFunction): MenuContent => [
  { label: t("common.pages.homepage"), tone: "petition", url: routes.root },
  {
    label: t("common.petition.search"),
    tone: "petition",
    url: routes.search,
  },
  {
    label: t("common.petition.start"),
    tone: "petition",
    url: routes.petition.start,
    loginModal: true,
  },
  { label: "Über uns", tone: "petition", url: routes.about },
  {
    label: t("common.pages.press"),
    tone: "petition",
    url: routes.press,
    blank: true,
  },
  { label: t("common.pages.faq"), tone: "petition", url: routes.faq },
  {
    label: t("common.initiative.title"),
    items: [
      {
        label: t("common.initiative.search"),
        tone: "initiative",
        url: routes.initiative.search,
      },
      {
        label: t("common.initiative.start"),
        tone: "initiative",
        url: routes.initiative.start,
      },
      {
        label: t("common.pages.faq"),
        tone: "initiative",
        url: routes.initiative.faq,
      },
    ],
    tone: "initiative",
  },
];

const userMenuContent = (t: TFunction): MenuContent => [
  {
    label: t("pages.userAccount.petition.title"),
    tone: "petition",
    url: routes.petition.user.petitions,
    skipTone: true,
  },
  {
    label: t("pages.userAccount.signatures.title"),
    tone: "petition",
    url: routes.petition.user.signatures,
    skipTone: true,
  },
  {
    label: t("pages.userAccount.account.title"),
    tone: "petition",
    url: routes.petition.user.account,
    skipTone: true,
  },
];

//#endregion Navbar Helper
const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const { toggleModal } = useModalContext();
  const { t } = useTranslation();
  const { isAuth, user } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useUserDispatchContext();
  const { tone } = useTheme();
  const navigate = useNavigate();
  const menuTogglerRef = useRef<HTMLDivElement>(null);
  const usersService = UsersService.getInstance();
  const userMenuIconStyle: CSSObject = {
    "&:hover": colorSecondary(tone),
  };

  type ButtonProps = {
    fullWidth?: boolean;
    size?: "sm" | "md";
  };
  const NewsletterButton = ({
    fullWidth = false,
    size = "sm",
  }: ButtonProps) => (
    <Button
      fullWidth={{
        default: fullWidth,
      }}
      icon={faEnvelopeDot}
      label={t("common.newsletter.subscribe")}
      onClick={() => toggleModal("newsletter")}
      size={size}
      variant="outline"
    />
  );

  const DonationButton = ({ fullWidth = false, size = "sm" }: ButtonProps) => (
    <Button
      as="a"
      fullWidth={{
        default: fullWidth,
      }}
      href={getDonationUrl({ context: "general", source: "header" })}
      icon={faBoxHeart}
      label={t("common.donation.donate")}
      size={size}
      variant="primary"
    />
  );

  const userMenuExtraContent = [
    // User doesn't have a password
    isAuth && user && !user?.hasPassword && (
      <p key="setPasswordRequestContent">
        {t("pages.userAccount.modals.setPasswordRequest.content")}
      </p>
    ),

    isAuth && user && user.status == "verified" && !user?.hasPassword && (
      <Button
        fullWidth={{ default: true }}
        key="setPasswordRequestButton"
        label={t("pages.userAccount.modals.setPasswordRequest.title")}
        loading={isLoading}
        onClick={() => {
          setIsLoading(true);
          usersService
            .generateToken({
              email: user.email,
              type: "password",
              url: window.location.pathname,
            })
            .then(() => toggleModal("setPasswordRequestMessage"))
            .catch(() => null)
            .finally(() => setIsLoading(false));
        }}
        size="md"
        variant="primary"
      />
    ),
    // User have a password, but is not verified
    isAuth && user && user.status !== "verified" && user.hasPassword && (
      <p key="emailConfirmationContent">
        {t("pages.userAccount.modals.emailConfirmationRequest.content")}
      </p>
    ),

    isAuth && user && user.status !== "verified" && user.hasPassword && (
      <Button
        fullWidth={{ default: true }}
        key="emailConfirmationButton"
        label={t("pages.userAccount.modals.emailConfirmationRequest.title")}
        loading={isLoading}
        onClick={() => {
          setIsLoading(true);
          usersService
            .generateToken({
              email: user.email,
              type: "verification",
              url: window.location.pathname,
            })
            .then(() => toggleModal("registrationMessage"))
            .catch(() => null)
            .finally(() => setIsLoading(false));
        }}
        size="md"
        variant="primary"
      />
    ),

    <Button
      fullWidth={{ default: true }}
      key="logoutButton"
      label={t("pages.userAccount.logout")}
      onClick={() => {
        logout(dispatch);
        navigate(routes.root);
      }}
      size="md"
      variant="outline"
    />,
  ];

  return (
    <header>
      <nav css={navBarCSS}>
        <Container size="lg">
          <div css={navBarContainerStyle}>
            <div css={navBarBrandStyle}>
              <Link to={routes.root}>
                <LogoSVG css={{ height: "100%" }} />
              </Link>
            </div>

            <nav css={navBarItemsCSS}>
              <div css={navBarButtonContainerCSS}>
                <NewsletterButton />
              </div>

              <div css={navBarButtonContainerCSS}>
                <DonationButton />
              </div>

              <a
                css={userMenuButtonStyle}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (isAuth) {
                    setUserMenuOpen((prev) => !prev);
                  } else {
                    toggleModal("getUser");
                  }
                }}
              >
                {isAuth ? (
                  <FontAwesomeIcon
                    aria-hidden={false}
                    css={[userMenuIconStyle, colorPrimary(tone)]}
                    fixedWidth
                    icon={faCircleUserSolid}
                    size="2x"
                  />
                ) : (
                  <FontAwesomeIcon
                    aria-hidden={false}
                    css={[userMenuIconStyle, colorGray4]}
                    fixedWidth
                    icon={faCircleUser}
                    size="2x"
                  />
                )}
              </a>

              <MenuToggler
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                ref={menuTogglerRef}
              />
            </nav>
          </div>
        </Container>
      </nav>

      <Menu
        content={menuContent(t)}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        showHeaderBackground={true}
        menuTogglerRef={menuTogglerRef}
      />

      {isAuth && user && (
        <Menu
          content={
            user?.hasPassword && "verified" == user.status
              ? userMenuContent(t)
              : []
          }
          header={
            user.firstName ? (
              <Trans
                i18nKey="pages.userAccount.greeting"
                values={{ firstName: user.firstName }}
              >
                {""}
                <strong></strong>!
              </Trans>
            ) : (
              <strong>{user.email}</strong>
            )
          }
          showHeaderBackground={false}
          menuOpen={userMenuOpen}
          setMenuOpen={setUserMenuOpen}
          postContent={userMenuExtraContent}
        />
      )}
    </header>
  );
};

export default NavBar;
